import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-kys-corporate-docs',
  templateUrl: './kys-corporate-docs.component.html',
  styleUrls: ['./kys-corporate-docs.component.scss']
})
export class KysCorporateDocs implements OnChanges {
  @Input() corporateDocs: any;
  @Input() accountData: any;
  docList: any[] = [];
  coDigitalDone: any;
  constructor(
    private _app: AppService,
    private _data: DataService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.corporateDocs && changes.corporateDocs.currentValue) {
      this.corporateDocs = changes.corporateDocs.currentValue
    }
    if (changes.accountData && changes.accountData.currentValue) {
      this.accountData = changes.accountData.currentValue
    }
    if (this.corporateDocs.length) {
      this.dataManipulation();
    }
    this._data.corporateDigiUpload.subscribe(res=>{
      this.coDigitalDone=res;
    });
  }

  saveData(){
    if(this.docList.some((f) => f.status != 'CREATED')){
      if(this.coDigitalDone){
        this.coDigitalDone = { ...this.coDigitalDone, kysTpeCheckStatus: 'COMPLETED' }
      }
      let status = this.coDigitalDone
      this._app.sendStatus(status).subscribe(() => {
        this._app.getStatus().subscribe(res => {
          this._data.checksStatus(res['data'])
        })
      })
      this._data.toastrShow('Your details have been saved', 'info');
    }
    else{
      this._data.toastrShow('Minimum one document is required to upload', 'warning');
    }
  }

  dataManipulation(){
    this.docList = [];
    for (let item of this.corporateDocs) {
      if (item.CORPORATE) {
        for (let docs of item.CORPORATE) {
          if(docs.status != 'CREATED'){
          }
          this.docList.push(docs);
        }
      }
      if (item.FINANCE) {
        for (let docs of item.FINANCE) {
          if(docs.status != 'CREATED'){
          }
          this.docList.push(docs);
        }
      }
      if (item.LEGAL) {
        for (let docs of item.LEGAL) {
          if(docs.status != 'CREATED'){
          }
          this.docList.push(docs);
        }
      }
    }
  }

  onUpload(event, item) {
    item.array = new Array()
    item.size = 0
  }

  getFileDetails(e, item) {
    for (var i = 0; i < e.target.files.length; i++) {
      item.array.push(e.target.files[i]);
    }
    item.size = 1
  }

  uploadFiles(item){
    const formData = new FormData();
    formData.append('frontImage', item.array[0]);
    formData.append('accountId', this.accountData.accountId);
    formData.append('checkId', item.checkId);
    formData.append('docName', item.docName);
    this._data.changeLoaderVisibility(true);
    this._app.getIdDocUploadforCorp(formData).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
      this._data.toastrShow('Documents successfully uploaded', `success`);
      item.upload = true
      item.array = new Array();
      this.getDocList();
    });
  }

  getDocList(){
    this.docList = [];
    this._app.getVideoChecks().subscribe(result => {
      this.corporateDocs = result.docChecks || [];
      this.dataManipulation();
    });
  }


}