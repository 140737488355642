import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';
import { NewKycModalComponent } from 'src/app/shared/new-kyc-modal/new-kyc-modal.component';

@Component({
  selector: 'app-recursive-officer-list',
  templateUrl: './recursive-officer-list.component.html',
  styleUrls: ['./recursive-officer-list.component.scss']
})
export class RecursiveOfficerListComponent implements OnChanges {
  @Input() inputData;
  @Input() countries;
  @Output() outputData = new EventEmitter<any>();
  public bsModalRef: BsModalRef;
  officerTypeArray = [
    { heading: 'INDIVIDUAL', slug: 'INDIVIDUAL' },
    { heading: 'CORPORATE', slug: 'INDIVIDUAL CORPORATE' },
  ];
  emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  uboDone: any;
  openedModal: any;

  constructor(
    private _app: AppService,
    private _data: DataService,
    private modalService: BsModalService,
    private toastrService: ToastrService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.countries && changes.countries.currentValue){
      this.countries = changes.countries.currentValue;
    }
    if(changes.inputData && changes.inputData.currentValue){
      this.inputData = changes.inputData.currentValue;
      if(this.inputData && this.inputData.companyName != ''){
        this.inputData['controlOfficerEntityType'] = 'INDIVIDUAL CORPORATE';
        this.inputData['entityTypeDisabled'] = true;
      }
    }
    this._data.corporateUboInfo.subscribe(res => {
      this.uboDone = res;
    })
  } 
  
  addOfficer(item){
    const obj = { firstName: null, email: null, sharePercentage: null, controlOfficerEntityType: 'INDIVIDUAL', citizenshipCountryCode: null };
    item.officerList.push(obj);
  }
  
  getOfficerEntityType(inputData, item){
    if(item.controlOfficerEntityType == 'INDIVIDUAL CORPORATE'){
      const pushObj = { companyName: '', companyStack: null, parentNodeCompanyName: inputData.companyName, controlOfficerEntityType: 'INDIVIDUAL CORPORATE', entityTypeDisabled: false, officerList: [{firstName: null, email: null, sharePercentage: null, controlOfficerEntityType: 'INDIVIDUAL', officerEntityType: true, citizenshipCountryCode: null}] };
      inputData.childCompanyOfficerList.push(pushObj);
      if(!item.createdDate){
        const index = inputData.officerList.findIndex((f) => f.firstName == null);
        inputData.officerList.splice(index, 1);
      }
    }
    else{
      delete inputData.companyName;
    }
  }

  getOfficerEntityTypeOfCorporate(inputData){
    this.outputData.emit(inputData.controlOfficerEntityType);
  }

  getOutputData(event){
    if(event == 'INDIVIDUAL'){
      const index = this.inputData.childCompanyOfficerList.findIndex((f) => f.companyName == '');
      this.inputData.childCompanyOfficerList.splice(index, 1);
      const obj = {firstName: null, email: null, sharePercentage: null, controlOfficerEntityType: 'INDIVIDUAL', officerEntityType: false, citizenshipCountryCode: null};
      this.inputData.officerList.push(obj);
    }
    else{
      this.outputData.emit(event);
    }
  }

  addOfficerButtonDisable(inputData){
    if(inputData.officerList.every((f) => f.createdDate)){
      return false;
    }
    else{
      return true;
    }
  }

  editStakesOfCompany(inputData,invalid){
    if(!invalid){
      const payload = {
        officers: inputData.officerList.map((e) => ({ officerId: e.id, companySharePercentage: Number(inputData.companyStack) }))
      }
      this._data.changeLoaderVisibility(true);
      this._app.editStakes(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
        this._data.toastrShow("Details have been updated", `success`);
        inputData.editStakes = null;
        this.outputData.emit('loadAPI');
      });
    }
  }

  addDetails(item, inputData, isRemoteLink, uboDetails?){
    const parentNodeCompanyName = inputData.officerList.length > 1 ? inputData.companyName : inputData.parentNodeCompanyName;
    let companyName = null;
    let companySharePercentage = null;
    if(inputData.officerList.length == 1){
      companyName = inputData.companyName;
      companySharePercentage = Number(inputData.companyStack);
    }
    const payload = {
      firstName: item.firstName,
      controlOfficerType: 'UBO',
      email: item.email,
      controlOfficerEntityType: item.controlOfficerEntityType,
      sharePercentage: Number(item.sharePercentage),
      companyName: companyName,
      companySharePercentage: companySharePercentage,
      parentNodeCompanyName: parentNodeCompanyName,
      citizenshipCountryCode: item.citizenshipCountryCode
    }
    this._data.changeLoaderVisibility(true);
    this._app.getDetailsOfficers(payload).pipe((finalize(() => this._data.changeLoaderVisibility(false)))).subscribe((data) => {
      this.toastrService.success('UBO added');
      if(data.data){
        if(isRemoteLink){
          this.sendRemoteLink(data.data);
          this.outputData.emit('loadAPI');
        }
        else if(uboDetails){
          this.outputData.emit(['uboAddDetails', data.data]);
        }
        else{
          this.outputData.emit('loadAPI');
        }
      }
      const payload = {
        "message": item.firstName + ' has been added as UBO',
        "page": "Control Officer Add",
        "geoLocation": null
      }
      if(this.uboDone){
        this.uboDone = { ...this.uboDone, kysTpeCheckStatus: 'COMPLETED' }
      }
      let status = this.uboDone
      this._app.sendStatus(status).subscribe(() => {
        this._app.getStatus().subscribe(res => {
          this._data.checksStatus(res['data'])
        })
      })
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Control Officer Add",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    );
  }

  viewDetails(item){
    const initialState = {
      type: "officer-view-edit-page",
      officerItem: item,
      viewType: 'view'
    }
    this.bsModalRef = this.modalService.show(NewKycModalComponent, { initialState, class: 'modal-lg new-modal-as-dialogue' });
    this.bsModalRef.content.onClose.subscribe((result) => {
      this.outputData.emit('loadAPI');
    })
  }

  checkLinkForInitateButton(item) {
    if (item.controlOfficerEntityType == 'CORPORATE') {
      return (item.citizenshipCountry && item.jurisdictionCode) ? false : true;
    }
    else {
      if(item.firstName && this.emailPattern.test(item.email) && item.sharePercentage && item.citizenshipCountryCode && item.controlOfficerEntityType){
        return false;
      }
      else{
        return true;
      }
    }
  }

  checkLinkButton(item) {
    if (item.controlOfficerEntityType == 'CORPORATE') {
      return (item.citizenshipCountry && item.jurisdictionCode) ? false : true;
    }
    else {
      return item.citizenshipCountry ? false : true;
    }
  }

  // Send Remote Link
  sendRemoteLink(item) {
    this._data.changeLoaderVisibility(true);
    this._app.startKYC(item.id).subscribe((res) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow(`Remote link has been sent`, 'success');
      const payload = {
        "message": 'Remote Link has been sent to the ' + res['data'].tpe.kysNo,
        "page": "Control Officer Remote Link",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Control Officer Remote Link",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      });
  }

  deleteData(item){
    this.openedModal = {
      modals: 'delete-data',
      data: item
    }
  }
  
  callDeleteDataAPI(){
    this.openedModal.modals = null;
    this._data.changeLoaderVisibility(true);
    this._app.deleteControlOfficers(this.openedModal.data.id).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
      this.outputData.emit('loadAPI');
      const msg = `${this.openedModal.data.firstName} has been deleted`;
      this._data.toastrShow(msg, 'info');
      const payload = {
        "message": msg,
        "page": "Control Officer Delete",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      this.openedModal.data = null;
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Control Officer Delete",
          "geoLocation": null
        }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    })
  }

}
