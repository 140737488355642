import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { AppService } from '../../services/app.service';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse, } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { NewKycModalComponent } from 'src/app/shared/new-kyc-modal/new-kyc-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-kys-corporate-feas',
  templateUrl: './kys-corporate-feas.component.html',
  styleUrls: ['./kys-corporate-feas.component.scss']
})
export class KysCorporateFeasComponent implements OnInit {
  public bsModalRef: BsModalRef;
  basicDone: boolean = false;
  additionalDone:boolean = false;
  digitalDone: boolean = false;
  questionnaireDone: boolean = false;
  uboDone: boolean = false;
  directorsDone: boolean = false;
  tradeDone: boolean = false;
  showQuestionr: boolean = false;
  finish: boolean = false;
  terms: boolean = true;
  checkbox = false;
  checks: any = [];
  name: any = '';
  isScrollLeft: boolean = false;
  isScrollRight: boolean = true;
  dashbordRedirectionURL = environment.URL.redirection_Url
  isUboDataChange: boolean = false;
  openedModal: any = null;
  officersList: any[] = [];
  checksCompleteDate: any;
  statusText: any;
  checksArray: any[] = [];
  checksCount: { UNCHECK: 0; COMPLETED: 0; PASSED: 0; FAILED: 0; };
  activeTabs: string = 'basicDone';
  tabsArray = ['basicDone', 'additionalDone', 'digitalDone', 'questionnaireDone', 'tradeDone', 'uboDone']
  onboardingStatus: any;
  isBankCheck: boolean = false;
  docList: any;
  questionaryData: any;
  answeredQuestions: number;
  uboList: any;
  directorData: any;
  docRejected: number;
  selectedCountry: any;
  docSubmitted: number;
  openModalPopup:any=null;
  digiUploadStatus: string;

  constructor(
    private _app: AppService,
    private _data: DataService,
    private dataService: DataService,
    private modalService: BsModalService,
    public translate: TranslateService,
    private route: ActivatedRoute
  ) { 
    this.route.queryParams.subscribe(params => {
      const lang = params['language'];
      if(lang){
        translate.use(lang);
      }
      else{
        translate.use('en');
      }
    });
  }

  ngOnInit() {
    this.dataService.finishValue.subscribe(res => {
      this.finish = res
    })
    this.dataService.isBankCheck.subscribe(res => {
      this.isBankCheck = res
    })
    this.dataService.digiData.subscribe(res => {
      this.docList = res
      if(res){
        this.docRejected = 0;
        this.docSubmitted = 0;
        this.calcVerificationStatus(this.docList, 'digiUploadStatus');
        for(let i = 0; i < this.docList.length; i++){
          let ehQ = this.docList[i]
          if(ehQ.status === 'REJECTED'){
            this.docRejected ++
          }
          if(ehQ.status !== 'CREATED'){
            this.docSubmitted ++
          }
        }
      }
    })
    this.dataService.questionaryData.subscribe(res => {
      this.questionaryData = res;
      if(res){
        this.answeredQuestions = 0
        for(let i = 0; i < this.questionaryData.length; i++){
          let ehQ = this.questionaryData[i]
          if(ehQ.inputData){
            this.answeredQuestions ++
          }
        }
      }
    })
    this.dataService.uboData.subscribe(res => {
      this.uboList = res
    })
    this.dataService.directorData.subscribe(res => {
      this.directorData = res
    })
    this.dataService.isCountryUploaded.subscribe(res => {
      this.selectedCountry = res
    })
    this.dataService.corporateBasicInfo.subscribe(res => {
      this.basicDone = res;
      this.getStatusWithText();
    })
    this.dataService.corporateAccountInfo.subscribe(res => {
      this.additionalDone = res;
      this.getStatusWithText();
    })
    this.dataService.corporateDigiUpload.subscribe(res => {
      this.digitalDone = res;
      this.getStatusWithText();
    })
    this.dataService.corporateQuestionnaireInfo.subscribe(res => {
      this.questionnaireDone = res;
      this.getStatusWithText();
    })
    this.dataService.corporateTradeInfo.subscribe(res => {
      this.tradeDone = res;
      this.getStatusWithText();
    })
    this.dataService.corporateUboInfo.subscribe(res => {
      this.uboDone = res;
      this.getStatusWithText();
    })
    this.dataService.corporateDirectorInfo.subscribe(res => {
      this.directorsDone = res;
      this.getStatusWithText();
    })
    this._app.getStatus().subscribe((res: any) => {
      if(res.data.length){
        this.dataService.checksStatus(res.data);
        if(res.data.some((f) => f.kysTpeCheckStatus != 'UNCHECK')){
          this.terms = false
        }
        this.getStatusWithText();
      }
    });
    this._app.getAccountInfoforCorp().subscribe((data) => {
      if(data['data']){
        const createdDate = data['data'].createdDate;
        this.onboardingStatus = data['data'].status;
        if(createdDate){
          const dateObject = new Date(createdDate);
          dateObject.setDate(dateObject.getDate() + 1);
          const formattedDate = new DatePipe('en-US').transform(dateObject, 'dd MMM, yyyy hh:mm a');
          this.checksCompleteDate = formattedDate;
        }
        let copmanyAccountInfoData = data["data"]
        let accountID = copmanyAccountInfoData.accountId;
        this.checksArray.push('basicDone');
        this._app.getAllCorpChecks(accountID).subscribe(res => {
          this.checks = res["data"];
          if (this.checks) {
            if(this.checks.accountOpeningFormCheck){
              this.checksArray.push('additionalDone');
            }
            else{
              this.tabsArray = this.tabsArray.filter((f) => f != 'additionalDone');
            }
            if(this.checks.documentCheck){
              this.checksArray.push('digitalDone');
            }
            else{
              this.tabsArray = this.tabsArray.filter((f) => f != 'digitalDone');
            }
            if(this.checks.tradeCheck){
              this.checksArray.push('tradeDone');
            }
            else{
              this.tabsArray = this.tabsArray.filter((f) => f != 'tradeDone');
            }
            if(this.checks.uboCheck){
              this.checksArray.push('uboDone');
            }
            else{
              this.tabsArray = this.tabsArray.filter((f) => f != 'uboDone');
            }
            this.getStatusWithText();
            this._data.kysNoDetails(this.checks.kysTpe.kysNo);
          }
        })
      }
    });
    this._app.getAllQuestionnaireCheck().subscribe((res) => {
      if (res && res.length) {
        this.showQuestionr = true;
        this.checksArray.push('questionnaireDone');
        this.getStatusWithText();
      }
      else{
        this.tabsArray = this.tabsArray.filter((f) => f != 'questionnaireDone');
      }
    });
    this.dataService.nameValue.subscribe(res => {
      this.name = res
    });
  }

  calcVerificationStatus(list, statusName){
    if(list.every(eh => eh.status === 'VERIFIED')){
      this[statusName] = 'Approved';
    }
    else if(list.every(eh => eh.status === 'REJECTED')){
      this[statusName] = 'Rejected';
    }else{
      this[statusName] = 'Failed';
    }
  }

  selectedActiveTabs(slug){
    this.activeTabs = slug;
  }

  getOutputData(event){
    const index = this.tabsArray.findIndex((f) => f == event);
    if(index < (this.tabsArray.length - 1)){
      this.activeTabs = this.tabsArray[index+1];
    }
  }

  scrollLeft() {
    var left = document.querySelector('.scroll-exceed-tabs');
    left.scrollBy(-150, 0);
    var scrollPercentage = 100 * left.scrollLeft / (left.scrollWidth - left.clientWidth);
    if (scrollPercentage == 0) {
      this.isScrollLeft = false;
      this.isScrollRight = true;
    }
    else if (scrollPercentage < 98) {
      this.isScrollRight = true;
    }
  }

  scrollRight() {
    var right = document.querySelector('.scroll-exceed-tabs');
    right.scrollBy(150, 0);
    var scrollPercentage = 100 * right.scrollLeft / (right.scrollWidth - right.clientWidth);
    if (scrollPercentage > 98) {
      this.isScrollLeft = true;
      this.isScrollRight = false;
    }
    else if (scrollPercentage > 0) {
      this.isScrollLeft = true;
    }
  }

  manipulateData(data){
    let totalSharePercentage = 0;
    let obj = { companyName: data.companyName, totalSharePercentage: 0, officerList: [], companiesList: [] }
    if(data.officerList && data.officerList.length){
      for(let item of data.officerList){
        totalSharePercentage += item.sharePercentage;
        obj['officerList'].push({ id: item.id, name: item.firstName, sharePercentage: item.sharePercentage })
      }
    }
    if(data.childCompanyOfficerList && data.childCompanyOfficerList.length){
      for(let item of data.childCompanyOfficerList){
        totalSharePercentage += item.companyStack;
        this.manipulateData(item);
        let companyObj = { companyName: item.companyName, stack: item.companyStack, stackOwnerIds: []};
        for(let officer of item.officerList){
          companyObj['stackOwnerIds'].push(officer.id)
        }
        obj['companiesList'].push(companyObj);
      }
    }
    obj['totalSharePercentage'] = totalSharePercentage;
    this.officersList.push(obj);
  }
  
  async getFinalSubmit() {
    this.officersList = [];
    let officersData = await this.getOfficersList()
    //closing the modal
    this.goToBack();
    if(officersData && ((officersData.officerList && officersData.officerList.length) || (officersData.childCompanyOfficerList && officersData.childCompanyOfficerList.length))){
      this.manipulateData(officersData);
      if(this.officersList.length){
        if(this.officersList.some((f) => f.totalSharePercentage > 100)){
          this.openModal('corporate-percentage-exceeded', this.officersList);
        }
        else if(this.officersList.some((f) => f.totalSharePercentage < 100)){
          this.openModal('corporate-percentage', this.officersList);
        }
        else{
          this.callSubmit(null);
        }
      }
    }
    else{
      this.callSubmit(null);
    }
  }

  callSubmit(equityJustification) {
    let abc = {
      message: equityJustification
    };
    this._data.changeLoaderVisibility(true);
    this._app.getFinalSubmit(abc).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((response) => {
      this._data.toastrShow(`All details submitted Successfully`, 'success');
      const payload = {
        "message": 'Company details submitted Successfully',
        "page": "Company Final Submit",
        "geoLocation": null
      }
      this._data.finishVal(true)
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Company Final Submit",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    );
  }

  async getOfficersList() {
    this._data.changeLoaderVisibility(true);
    const res = await this._app.getUBONewList().toPromise();
    this._data.changeLoaderVisibility(false);
    return res;
  }

  openModal(type, list) {
    this.isUboDataChange = false;
    const initialState = {
      type: type,
      officersList: list || []
    }
    this.bsModalRef = this.modalService.show(NewKycModalComponent, { initialState, class: 'modal-lg new-modal-as-dialogue' });
    this.bsModalRef.content.onClose.subscribe((result) => {
      if (type == 'corporate-percentage-exceeded') this.isUboDataChange = true;
      if (type == 'corporate-percentage') this.callSubmit(result);
    });
  }

  goToBack() {
    this.openedModal = null
  }

  submitFlow(){
    if(this.checksArray.every((f) => this[f] && this[f]['kysTpeCheckStatus'] != 'UNCHECK' && this[f]['kysTpeCheckStatus'] != 'WARNING' && this[f]['kysTpeCheckStatus'] != 'FAILED')){
      this.openedModal='confirm-sumbit-modal';
    }
    else{
      for(let item of this.checksArray){
        if(this[item] && this[item]['kysTpeCheckStatus'] && this[item]['kysTpeCheckStatus'] == 'UNCHECK'){
          this[item]['kysTpeCheckStatus'] = 'WARNING'
        }
      }
    }
  }

  getStatusWithText(){
    this.checksCount = {
      UNCHECK: 0,
      COMPLETED: 0,
      PASSED: 0,
      FAILED: 0,
    }
    if(this.checksArray.length){
      for(let item of this.checksArray){
        if(this[item] && this[item]['kysTpeCheckStatus']){
          this.checksCount[this[item].kysTpeCheckStatus] += 1;
        }
      }
    }
    let status = '';
    if(this.checksCount['UNCHECK'] == this.checksArray.length){
      status = 'Open';
    }
    else if(this.checksCount['COMPLETED'] == this.checksArray.length){
      status = 'Completed';
    }
    else if(this.checksCount['PASSED'] == this.checksArray.length){
      status = 'CompletedWithVerified';
    }
    else if(this.checksCount['FAILED'] > 0){
      status = 'OnHold';
    }
    else{
      status = 'Processing';
    }
    this.statusText = status;
  }

}
