interface DocumentI{
	countryCode       : string
	docIssueDate      : any
	docExpiryDate     : any
	docFrontUrl       : string 
	docRearUrl        : string 
	docSerialNo       : string
	issueYear         : any
	expiryYear        : any
	docSlug           : string
	docType           : string // "IDENTITY"
	firstNameAsPerDoc : string
	isNameInDocDiff   : boolean
	lastNameAsPerDoc  : string
	middleNameAsPerDoc: string
	provinceCode      ?: any 
	docName           ?: any 
}

export class IDDocument implements DocumentI{
	countryCode        : string
	docIssueDate       : any
	docExpiryDate      : any
	docFrontUrl        : string 
	docRearUrl         : string 
	docSerialNo        : string
	issueYear          : any
	expiryYear         : any
	docSlug            : string
	docType            : string = "IDENTITY" 
	firstNameAsPerDoc  : string
	isNameInDocDiff    : boolean
	lastNameAsPerDoc   : string
	middleNameAsPerDoc : string
	provinceCode      ?: any 
	docName           ?: any 

	constructor(document?) {
        if(document){
        	for(var key in document){
	        	this[key] = document[key];
	        }
        }
        else{
			this.countryCode        = null ;
			this.docIssueDate       = null ;
			this.docExpiryDate      = null ;
			this.docFrontUrl        = null ; 
			this.docRearUrl         = null ; 
			this.docSerialNo        = null ;
			this.expiryYear         = null ;
			this.issueYear          = null ;
			this.docSlug            = null ;
			this.docType            = "IDENTITY" ;
			this.firstNameAsPerDoc  = null ;
			this.isNameInDocDiff    = null ;
			this.lastNameAsPerDoc   = null ;
			this.middleNameAsPerDoc = null ;
			this.provinceCode       = null ; 
			this.docName            = null ; 
        }
    }
}

export class DocumentInfo {
	name: string;
	id: number;
}
export class idDocument{
	id:number;
	name: string;
	idDoc: number;
}
export class addressDocument{
	id:number;
	name: string;
	idDoc: number;
}
export class bankDocument{
	id:number;
	name: string;
	idDoc: number;
}

export class AddressDocumentInfo {
	name: string;
	id: number;
}

export class marital{
	key: string;
	value: string;
}

export class BankDocumentInfo {
	// name: string;
	// id: number;
	key: string;
	value: string;
}

export class netWorth {
    key: string;
}

export class riskTolerance {
    key: string;
}

export class AddressDocument implements DocumentI{
	countryCode       : string
	docIssueDate      : any
	docExpiryDate     : any
	docFrontUrl       : string 
	docRearUrl        : string 
	docSerialNo       : string
	expiryYear        : any
	docSlug           : string
	docType           : string = "ADDRESS" 
	firstNameAsPerDoc : string
	issueYear         : any
	isNameInDocDiff   : boolean
	lastNameAsPerDoc  : string
	middleNameAsPerDoc: string
	provinceCode      ?: any 
	livingHereFrom :any

	constructor(document) {
        for(var key in document){
        	this[key] = document[key];
        }
    }
}
