import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { BehaviorSubject } from "rxjs";

import { ImageCroppedEvent } from "ngx-image-cropper";
import { ImageCropperComponent } from "ngx-image-cropper";

import { SelfieService } from "../../services/selfie.service";
import { AppService } from "../../services/app.service";
import { DataService } from "../../services/data.service";

import * as RecordRTC from "recordrtc";
import { Ng2DeviceService } from "ng2-device-detector";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-feas-modal",
  templateUrl: "./feas-modal.component.html",
  styleUrls: ["./feas-modal.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class FeasModalComponent implements OnInit {
  //Generic Variables
  title: string;
  type:
    | "show-image"
    | "crop-image"
    | "record-video"
    | "completion-modal"
    | "capture-doc"
    | "crop-image-b64"
    | "confirmation-modal"
    | "face-done";

  public onClose = new BehaviorSubject<any>({});
  dashbordRedirectionURL = environment.URL.redirection_Url
  //variables for "show-image"
  image: string;

  //variables for "completion-modal"
  message: any;

  //vars for crop image
  imageChangedEvent: any;
  croppedImage: any;
  loadedImageEvent: any;
  showCropper: boolean = false;
  imageFile: any = null;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  //vars for video
  // videoStream : any ;
  videoRecorder: any;
  recordedVideo: any;
  selfie: any;
  selfieConfigData: any;
  shouldRetry: boolean = false;
  imgAsb64: any;
  shouldDisableRecordBtn: boolean = false;
  counter: any = 1;
  isVidForcedOff: boolean = false;
  waitFor: number = 3;

  //helper Arr
  // helperBackgroundImage  : any = null;
  // helperText             : any = null;

  // bgArr                  : any[] = [6, 1, 12, 6];
  // instructionArr         : any[] = [
  //     "Please Look Straight At The Camera",
  //     "Please Turn Your Face To The Right",
  //     "Please Turn Your Face To The Left",
  //     "Please Look Straight At The Camera & Blink"
  // ];
  // audioArr               : any[] = [
  //     "assets/audio/center.mp3",
  //     "assets/audio/right.mp3",
  //     "assets/audio/left.mp3",
  //     "assets/audio/blink.mp3"
  // ];
  showVideos: any = {
    showLiveWebCam: false,
    showRecordedWebCam: false
  };
  geolocation: any;
  deviceInfo: any;
  deviceName: string;
  ip: any;

  constructor(
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public _selfie: SelfieService,
    public _app: AppService,
    public _data: DataService,
    private deviceService: Ng2DeviceService
  ) {}

  ngOnInit() {
    if (this.type == "crop-image") {
      console.log(this.loadedImageEvent);
      var image: any = new Image();
      var file: File = this.loadedImageEvent[0]
        ? this.loadedImageEvent[0]
        : this.loadedImageEvent.target.files[0];
      this.imageFile = this.loadedImageEvent[0]
        ? this.loadedImageEvent[0]
        : this.loadedImageEvent.target.files[0];
      var name = file.name;
      if (file) {
        var reader = new FileReader();
        var that = this;
        reader.onloadend = function() {
          // console.log(reader.result);
        };
        reader.readAsDataURL(file);
      }
      console.log("::::::::::::::::::::::::::::::", this.imageFile);
    }

    if (this.type == "crop-image-b64") {
      this.imageFile = this.imgAsb64;
      console.log("Speaking from feas modal", this.imgAsb64);
    }

    if (this.type == "record-video") {
      this._selfie.init();
      //   console.log(":::::::::::::::", this.selfieConfigData);
      //   setInterval(() => {
      //     this._data.toastrShow("Bello", "error");
      //   }, 2000);
    }
    this.deviceInformation()
  }

  closeModal(data?: any) {
    console.log("CLoseddd!");
    if (this.type === "capture-doc") {
      this._selfie.stopVideo();
    }

    if (this.type === "record-video") {
      this.stopVideo();
    }
    this.onClose.next(data);
    this.bsModalRef.hide();
  }

  closeProcess() {
    let obj = {
      shouldClose: true
    };
    this.onClose.next(obj);
    this.bsModalRef.hide();
  }

  //functions for crop image

  cropImage() {
    let data = {
      image: this.croppedImage
    };
    this.closeModal(data);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event);
  }
  imageLoaded() {
    this.showCropper = true;
    console.log("Image loaded");
  }
  cropperReady() {
    console.log("Cropper ready");
  }
  loadImageFailed() {
    console.log("Load failed");
  }
  rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  rotateRight() {
    this.imageCropper.rotateRight();
  }
  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }
  flipVertical() {
    this.imageCropper.flipVertical();
  }

  ////record-video
  facingMode = "user";
  constraints = {
    audio: false,
    video: {
      facingMode: this.facingMode
    }
  } 
  checkForPermission() {
    navigator.mediaDevices.getUserMedia(this.constraints)
      .then(
        successCallback => {
          console.log("runn callback", successCallback);
          this.showVideos.showLiveWebCam = true;
          this.showVideos.showRecordedWebCam = false;
          setTimeout(() => {
            var video = <HTMLMediaElement>(
              document.getElementById("liveWebcamVideo")
            );
            video.srcObject = successCallback;
            video.play();
            this._selfie.videoStream = successCallback;
            if (this.type !== "capture-doc") {
              //   this.setDirectionHelpers();
              this.waitText();
            }
            this.startVideoRecording();
            this.shouldDisableRecordBtn = true;
          }, 0);
        },
        errorCallback => {
          let alertObj = {
            message: "Please Allow Camera",
            status: "danger",
            autoDismiss: true,
            timeToDismiss: 10000
          };
          console.log(alertObj);
          // this._data.displayUniversalAlert(alertObj);
          this._data.toastrShow("Please Allow Camera", "info");
        }
      );
  }

  startVideoRecording() {
    this.videoRecorder = RecordRTC(this._selfie.videoStream, {
      mimeType:"video/*;codecs=h264",
      frameInterval: 45,
      frameRate: 30
    });
    this.videoRecorder.startRecording();
  }

  waitText() {
    let count = this.waitFor + 1;
    var that = this;
    let interval = setInterval(() => {
      if (count > 1) {
        count--;
        that._selfie.helperText = `Recording starts in ${count} seconds`;
        console.log(count);
      } else {
        clearInterval(interval);
        that.setDirectionHelpers();
      }
    }, 1000);
  }

  setDirectionHelpers() {
    var that = this;

    var counter = 0;
    var timer = 0;
    var interval = setInterval(() => {
      that._selfie.helperBackgroundImage = `https://images.obsassets.com/kychome/direction/${that._selfie.bgArr[counter]}.jpeg`;
      that._selfie.helperText = `${that._selfie.instructionArr[counter]}`;
      this._selfie.audioStream = new Audio(`${that._selfie.audioArr[counter]}`);
      this._selfie.audioStream.play();
      counter++;

      if (counter === 1) {
        console.log("reached completions 1");
        that.setSubsequentDirections();
        clearInterval(interval);
      }
    }, 0);
  }

  setSubsequentDirections() {
    var that = this;
    var timer = 0;
    var interval = setInterval(() => {
      if (!that.isVidForcedOff) {
        that._selfie.helperBackgroundImage = `https://images.obsassets.com/kychome/direction/${
          that._selfie.bgArr[that.counter]
        }.jpeg`;
        that._selfie.helperText = `${
          that._selfie.instructionArr[that.counter]
        }`;
        this._selfie.audioStream = new Audio(
          `${that._selfie.audioArr[that.counter]}`
        );
        this._selfie.audioStream.play();
        that.counter++;
        console.log(":::::::::::::::::::::::::", that.counter);
        if (that.counter == 2) {
          that.triggerSnapshot();
        }
        if (that.counter == 5) {
          console.log("reached completions 2");
          that.stopVideoRecording();
          clearInterval(interval);
        }
      } else {
        clearInterval(interval);
      }
    }, 4000);
  }
  stopVideoRecording() {
    var that = this;
    this.showVideos.showLiveWebCam = false;
    this.showVideos.showRecordedWebCam = true;
    this.shouldDisableRecordBtn = false;
    that.videoRecorder.stopRecording(function() {
      let blob = that.videoRecorder.getBlob();
      that.invokeSaveAsDialog(blob);
      console.log(blob);
    });
    that._selfie.videoStream.getTracks().forEach(function(track) {
      track.stop();
    });
    // that._selfie.videoStream.stop();
  }

  invokeSaveAsDialog(blob) {
    // this.videoBlob = blob ;
    console.log(blob);
    // this.shouldShowFinalVideo = true ;
    // var videoSection = document.querySelector("#userVideoSection");
    // (<HTMLElement>videoSection).style.display = 'block' ;
    var video = document.getElementById("recordedWebcamVideo");
    if (video) {
      video["src"] = window.URL.createObjectURL(blob);
    }

    this.recordedVideo = blob;

    // var video = <HTMLMediaElement>document.getElementById('videoo');
    //     video.srcObject = successCallback;
    //     video.play();
  }

  triggerSnapshot() {
    var canvas = <HTMLCanvasElement>document.getElementById("snapCanvas");
    var context = canvas.getContext("2d");
    var video = <any>document.getElementById("liveWebcamVideo");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    // context.drawImage(video, 0, 0, 640, 480, 0, 0, 640, 480);

    // var data = canvas.toDataURL("image/png");
    var data = canvas.toDataURL("image/jpeg");
    this.selfie = data;

    if (this.type === "capture-doc") {
      this.closeModal(this.selfie);
    }
    // console.log(data);
  }

  stopVideo() {
    this.counter = 1;
    this.isVidForcedOff = true;
    this._selfie.helperText = "";
    this._selfie.stopVideo();
    this._selfie.stopAudio();
  }

  dataURLtoFile(dataURI) {
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
  onSubmitVideo(event){
    console.log("video file: ",event.srcElement.files) 
    this.recordedVideo = event.srcElement.files.item(0);
    //alert(this.recordedVideo);
  }

  onSubmitImage(event) {
    console.log("image file: ",event.srcElement.files) 
    this.selfie = event.srcElement.files.item(0);
    //alert(this.selfie);
  }
  uploadVideoAndSelfienew(){
    //alert(this.deviceInfo.browser);
    //alert(this.deviceName);
    let  endUserDeviceDetails = {
     
      os:this.deviceInfo.os,
      browser:this.deviceInfo.browser,
      device:this.deviceName,      
      location:this.geolocation,
      // ip:this.ip
    };
    var formData1 = new FormData();
  //  // var image = this.dataURLtoFile(this.selfie, "hero");
  //   // formData.append("selfie", this.dataURLtoFile(this.selfie));
  //   formData.append("selfie", this.selfie);
  //   formData.append("imageType", "jpg");
  //   formData.append("video", this.recordedVideo);
  //   formData.append("videoType", "mov");
  //   formData.append("selfiId", this.selfieConfigData.selfie.endUserCheckID);
  //   formData.append(
  //     "livenessId",
  //     this.selfieConfigData.liveness.endUserCheckID
  //   );
  //   console.log("enduser data",JSON.stringify(endUserDeviceDetails))
  //   formData.append("endUserDeviceDetails",JSON.stringify(endUserDeviceDetails))
  //   this._data.changeLoaderVisibility(true);
  //   this._app.uploadVideoAndSelfie(formData).subscribe(
  //     res => {
  //       console.log(res);
        
  //       this._data.changeLoaderVisibility(false);
  //       let alertObj = {
  //         message: res.message,
  //         status: "success",
  //         autoDismiss: true,
  //         timeToDismiss: 10000
  //       };
  //       this.closeModal(res);
        
  //     },
  //     err => {
  //       this._data.changeLoaderVisibility(false);
  //       this.shouldRetry = true;
  //       // alert("error");
  //     }
  //   );
  formData1.append("video", this.selfie);
  formData1.append("docId",  this.selfieConfigData['selfie']['docId']?this.selfieConfigData['selfie']['docId']:"");
  formData1.append("checkId", this.selfieConfigData['selfie']['checkId']?this.selfieConfigData['selfie']['checkId']:"");
  formData1.append("docName", 'selfie');
  formData1.append("address",  this.selfieConfigData['selfie']['address']?this.selfieConfigData['selfie']['address']:"");
  formData1.append("idNumber",  this.selfieConfigData['selfie']['idNumber']?this.selfieConfigData['selfie']['idNumber']:"");
  formData1.append("expiryYear",  this.selfieConfigData['selfie']['expiryYear']?this.selfieConfigData['selfie']['expiryYear']:"");
  formData1.append("issueYear",  this.selfieConfigData['selfie']['issueYear']?this.selfieConfigData['selfie']['issueYear']:"");
  formData1.append("stateCode",  this.selfieConfigData['selfie']['stateCode']?this.selfieConfigData['selfie']['stateCode']:"");
  this._data.changeLoaderVisibility(true);
  this._app.uploadVideoSelfieFile(formData1).subscribe(res=>{
    this._data.changeLoaderVisibility(false);
    let alertObj = {
      message: res.message,
      status: "success",
      autoDismiss: true,
      timeToDismiss: 10000
    };
    
    this._data.toastrShow(res.message, "success");
    this.closeModal(res);

    //automatically goto next step on success
    // this.stopCamActivity();
    // this.goToStep('next');
    // this.goToStep('isFinalStep');
    },
    err => {
      this._data.changeLoaderVisibility(false);
      // this.closeModal();
      this.shouldRetry = true;
      // alert("error");
  })

  var formData2 = new FormData();
  formData2.append("video", this.recordedVideo);
  formData2.append("docId",  this.selfieConfigData['liveness']['docId']?this.selfieConfigData['liveness']['docId']:"");
  formData2.append("checkId", this.selfieConfigData['liveness']['checkId']?this.selfieConfigData['liveness']['checkId']:"");
  formData2.append("docName", 'liveness');
  formData2.append("address",  this.selfieConfigData['liveness']['address']?this.selfieConfigData['liveness']['address']:"");
  formData2.append("idNumber",  this.selfieConfigData['liveness']['idNumber']?this.selfieConfigData['liveness']['idNumber']:"");
  formData2.append("expiryYear",  this.selfieConfigData['liveness']['expiryYear']?this.selfieConfigData['liveness']['expiryYear']:"");
  formData2.append("issueYear",  this.selfieConfigData['liveness']['issueYear']?this.selfieConfigData['liveness']['issueYear']:"");
  formData2.append("stateCode",  this.selfieConfigData['liveness']['stateCode']?this.selfieConfigData['liveness']['stateCode']:"");
  this._data.changeLoaderVisibility(true);
  this._app.uploadVideoSelfieFile(formData2).subscribe(res=>{
    this._data.changeLoaderVisibility(false);
    let alertObj = {
      message: res.message,
      status: "success",
      autoDismiss: true,
      timeToDismiss: 10000
    };
    this._data.toastrShow(res.message, "success");
    this.closeModal(res);
    // this._data.displayUniversalAlert(alertObj);

    //automatically goto next step on success
    // this.stopCamActivity();
    // this.goToStep('next');
    // this.goToStep('isFinalStep');
    },
    err => {
      this._data.changeLoaderVisibility(false);
      // this.closeModal();
      this.shouldRetry = true;
      // alert("error");
  })
    
  }
 

  uploadVideoAndSelfie() {
    let  endUserDeviceDetails = {
     
      os:this.deviceInfo.os,
      browser:this.deviceInfo.browser,
      device:this.deviceName,      
      location:this.geolocation,
      // ip:this.ip
    };
    var formData1 = new FormData();
    var image = this.dataURLtoFile(this.selfie);
    console.log("form data variable :   " + formData1.toString());
    // formData.append("selfie", image);
    // // formData.append('selfie', this.webcamImage);
    // formData.append("video", this.recordedVideo);
    // formData.append("imageType", "jpeg");
    // formData.append("videoType", "webcam");
    // formData.append("selfiId", this.selfieConfigData.selfie.endUserCheckID);
    // formData.append(
    //   "livenessId",
    //   this.selfieConfigData.liveness.endUserCheckID
    // );
    // console.log("enduser data",JSON.stringify(endUserDeviceDetails))
    // formData.append("endUserDeviceDetails",JSON.stringify(endUserDeviceDetails))
    // this._data.changeLoaderVisibility(true);
    // this._app.uploadVideoAndSelfie(formData).subscribe(
    //   res => {
    //     console.log(res);
    //     // this.isUpdate = true ;
    //     this._data.changeLoaderVisibility(false);
    //     let alertObj = {
    //       message: res.message,
    //       status: "success",
    //       autoDismiss: true,
    //       timeToDismiss: 10000
    //     };
    //     this.closeModal(res);
    //     // this._data.displayUniversalAlert(alertObj);

    //     //automatically goto next step on success
    //     // this.stopCamActivity();
    //     // this.goToStep('next');
    //     // this.goToStep('isFinalStep');
    //   },
    //   err => {
    //     this._data.changeLoaderVisibility(false);
    //     // this.closeModal();
    //     this.shouldRetry = true;
    //     // alert("error");
    //   }
    // );

    formData1.append("video", image);
    formData1.append("docId",  this.selfieConfigData['selfie']['docId']?this.selfieConfigData['selfie']['docId']:"");
    formData1.append("checkId", this.selfieConfigData['selfie']['checkId']?this.selfieConfigData['selfie']['checkId']:"");
    formData1.append("docName", 'selfie');
    formData1.append("address",  this.selfieConfigData['selfie']['address']?this.selfieConfigData['selfie']['address']:"");
    formData1.append("idNumber",  this.selfieConfigData['selfie']['idNumber']?this.selfieConfigData['selfie']['idNumber']:"");
    formData1.append("expiryYear",  this.selfieConfigData['selfie']['expiryYear']?this.selfieConfigData['selfie']['expiryYear']:"");
    formData1.append("issueYear",  this.selfieConfigData['selfie']['issueYear']?this.selfieConfigData['selfie']['issueYear']:"");
    formData1.append("stateCode",  this.selfieConfigData['selfie']['stateCode']?this.selfieConfigData['selfie']['stateCode']:"");
    this._data.changeLoaderVisibility(true);
    this._app.uploadVideoSelfieFile(formData1).subscribe(res=>{
      this._data.changeLoaderVisibility(false);
      let alertObj = {
        message: res.message,
        status: "success",
        autoDismiss: true,
        timeToDismiss: 10000
      };
      this._data.toastrShow(res.message, "success");
      this.closeModal(res);
      // this._data.displayUniversalAlert(alertObj);

      //automatically goto next step on success
      // this.stopCamActivity();
      // this.goToStep('next');
      // this.goToStep('isFinalStep');
      },
      err => {
        this._data.changeLoaderVisibility(false);
        // this.closeModal();
        this.shouldRetry = true;
        // alert("error");
    })

    var formData2 = new FormData();
    formData2.append("video", this.recordedVideo);
    formData2.append("docId",  this.selfieConfigData['liveness']['docId']?this.selfieConfigData['liveness']['docId']:"");
    formData2.append("checkId", this.selfieConfigData['liveness']['checkId']?this.selfieConfigData['liveness']['checkId']:"");
    formData2.append("docName", 'liveness');
    formData2.append("address",  this.selfieConfigData['liveness']['address']?this.selfieConfigData['liveness']['address']:"");
    formData2.append("idNumber",  this.selfieConfigData['liveness']['idNumber']?this.selfieConfigData['liveness']['idNumber']:"");
    formData2.append("expiryYear",  this.selfieConfigData['liveness']['expiryYear']?this.selfieConfigData['liveness']['expiryYear']:"");
    formData2.append("issueYear",  this.selfieConfigData['liveness']['issueYear']?this.selfieConfigData['liveness']['issueYear']:"");
    formData2.append("stateCode",  this.selfieConfigData['liveness']['stateCode']?this.selfieConfigData['liveness']['stateCode']:"");
    this._data.changeLoaderVisibility(true);
    this._app.uploadVideoSelfieFile(formData2).subscribe(res=>{
      this._data.changeLoaderVisibility(false);
      let alertObj = {
        message: res.message,
        status: "success",
        autoDismiss: true,
        timeToDismiss: 10000
      };
      this._data.toastrShow(res.message, "success");
      this.closeModal(res);
      // this._data.displayUniversalAlert(alertObj);

      //automatically goto next step on success
      // this.stopCamActivity();
      // this.goToStep('next');
      // this.goToStep('isFinalStep');
      },
      err => {
        this._data.changeLoaderVisibility(false);
        // this.closeModal();
        this.shouldRetry = true;
        // alert("error");
    })


  }

  // End user Activity log
  getIp(){
    // this._app.getSystemIp().subscribe((res:any)=>{  
    //   this.ip=res.ip;
    //   console.log("ip adress",this.ip)
    // });
  }
  deviceInformation() {    
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      const isDesktopDevice = this.deviceService.isDesktop();
      if(this.deviceService.device=="unknown"){
        if(isDesktopDevice){
          this.deviceName='Desktop';
        }
        if(isMobile){
          this.deviceName='Mobile';
        }
        if(isTablet){
          this.deviceName='Tablet';
        }
      }else this.deviceName=this.deviceInfo.device;
    console.log("device info",this.deviceName);
    this.getLocation();
    this.getIp()
  }
 
  getLocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position)=>{
          this._app.getLocation(position.coords.latitude,position.coords.longitude).subscribe(res=>{
            if(res.city==""){
              this.geolocation=`${res.locality}, ${res.countryName}`;
            }else this.geolocation=`${res.city}, ${res.countryName}`;
                
            console.log("location",this.geolocation)      
          });
          
          // this.geolocation=`lat:${position.coords.latitude},lon:${position.coords.longitude}`;
          // console.log("geolcation",this.geolocation);
          // let geocoder = new google.maps.Geocoder();
        });
    } else {
       this.geolocation=null;
    }
}
}
