import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Subject } from 'rxjs';
import { AppService } from '../../services/app.service';
import { BsModalRef } from "ngx-bootstrap/modal";
import { DataService } from 'src/app/services/data.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-new-kyc-modal',
  templateUrl: './new-kyc-modal.component.html',
  styleUrls: ['./new-kyc-modal.component.scss']
})
export class NewKycModalComponent implements OnInit {
  currentStatus:any[] = [
    {key: 'Active', value: 'Active'},
    {key: 'Dormant', value: 'Dormant'},
    {key: 'Active in Progress', value: 'Active in Progress'},
    {key: 'Under liquidation', value: 'Under liquidation'},
    {key: 'Under process of Striking Off', value: 'Under process of Striking Off'},
    {key: 'Strike off', value: 'Strike off'},
    {key: 'Amalgamated', value: 'Amalgamated'},
    {key: 'Liquidated', value: 'Liquidated'},
    {key: 'Dissolved', value: 'Dissolved'},
    {key: 'Converted to LLP', value: 'Converted to LLP'},
    {key: 'Converted to LLP and dissolved', value: 'Converted to LLP and dissolved'}
  ]
  currentStatusInd:any[] = [
    {key: 'Active', value: 'Active'},
    {key: 'Inactive', value: 'Inactive'},
  ]
  
  addMoreDetailsForm: FormGroup = this.formBuilder.group({
    lastName: ['',[
      Validators.pattern(/^[a-zA-Z ]+$/)
    ]],
    dob: [''],
    maritalStatus: [''],
    phoneNo: ['',[
      Validators.pattern(/^[0-9]\d*(\.\d+)?$/)
    ]],
    address: [''],
    ssn: [''],
    taxID: [''],
    percentage: [''],
    status: [''],
    designation: [''],
    firstName: [''],
    email: [null,[
      Validators.pattern(/@/)
    ]],
    citizenship: ['', Validators.required],
    jurisdictionCode: [null],
    officerType: [''],
    sharePercentUBO: ['',[
      Validators.pattern(/^[0-9]\d*(\.\d+)?$/)
    ]],
    officerTypeUBO: [''],
    companyId: [''],
    companyType: [''],
    incorporationDate: [''],
    branchCount: [''],
    parentCompanyName: [''],
    parentCompanyID: [''],
    streetAddress: [''],
    region: [''],
    postalCode: [''],
  });
  maturityStatus:any[] = [
    {key:'Single', value:'SINGLE'},
    {key:'Married', value:'MARRIED'},
    {key:'Widowed', value:'WIDOWED'},
    {key:'Divorced', value:'DIVORCED'},
    {key:'Separated', value:'SEPARATED'},
  ]
  public onClose: Subject<any> = new Subject();
  type: "officer-view-edit-page" | "corporate-percentage" | "corporate-percentage-exceeded";
  countries: any[] = [];
  officerItem: any;
  officerName: any = '';
  officerTypeUBO: any;
  corporatePercentageShare: any;
  equityJustificationMsg: any;
  isEditDetails: boolean = false;
  jurisdictions: any[] = [];
  officersList: any[] = [];
    constructor(private formBuilder : FormBuilder,private _app :AppService,public bsModalRef: BsModalRef,
    private _data: DataService  ) { }

  ngOnInit() {
    this.onClose.next('');
    if(this.type == 'officer-view-edit-page'){
      this.getAllCountries();
      this.addMoreDetailsForm.disable();
    }
  }

  onDateInputKeydown(event: KeyboardEvent) {
    event.preventDefault(); // Prevent any key input
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.addMoreDetailsForm.controls;
  }
  
  equityJustify(){
    this.bsModalRef.hide();
    let message = '';
    if(this.officersList.length){
      for(let item of this.officersList){
        if(item.totalSharePercentage < 100){
          message += ' ' + item.equityJustificationMsg;
        }
      }
    }
    this.onClose.next(message);
  }
  
  disableEquityButton(){
    if(this.officersList.length){
      if(this.officersList.every((f) => {
        if(f.totalSharePercentage < 100){
          if(f.equityJustificationMsg){
            return true
          }
          else{
            return false
          }
        }
        else{
          return true
        }
      })){
        return false
      }
      else{
        return true
      }
    }
  }
    
  editStakes(){
    let officerList = [];
    for(let item of this.officersList){
      for(let officer of item.officerList){
        let obj = { officerId: officer.id, sharePercentage: Number(officer.sharePercentage) };
        officerList.push(obj);
      }
      for(let company of item.companiesList){
        for(let id of company.stackOwnerIds){
          let obj = { officerId: id, companySharePercentage: Number(company.stack) };
          officerList.push(obj);
        }
      }
    }
    const payload = {
      officers: officerList
    }
    this._data.changeLoaderVisibility(true);
    this._app.editStakes(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
      this._data.toastrShow("Details have been updated", `success`);
      this.bsModalRef.hide();
      this.onClose.next('');
    })
  }

  getAllCountries() {
    this._app
      .getAllCountries()

      .subscribe(
        (res) => {
          this.countries = res['data'];

          this.patchValues();
        },
        (err) => {
          console.log(err);
        }
      );
  }

  patchValues(){
    this.officerTypeUBO = this.officerItem.controlOfficerEntityType;
    const firstName = this.officerItem.firstName || '';
    const lastName = this.officerItem.lastName || '';
    this.officerName = firstName + ' ' + lastName;
    if(this.officerItem != null){
      const res = this.officerItem;
      res.dob ? this.addMoreDetailsForm.controls.dob.setValue(formatDate(res.dob, 'yyyy-MM-dd', 'en-US')) : '';
      this.addMoreDetailsForm.patchValue({
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        maritalStatus: res.maritalStatus,
        citizenship: res.citizenshipCountry ? res.citizenshipCountry.countryCode: '',
        address: res.residenceAddress,
        ssn: res.ssn,
        taxID: res.taxId,
        percentage: res.percentageOfOwnership,
        status: res.currentStatus,
        designation: res.designation,
        phoneNo: res.phoneNumber,
        sharePercentUBO: res.sharePercentage,
        officerTypeUBO: res.controlOfficerEntityType,
        companyId: res.companyId,
        companyType: res.companyType,
        incorporationDate: res.incorporationDate,
        branchCount: res.branchCount,
        parentCompanyName: res.parentCompanyName,
        parentCompanyID: res.parentCompanyID,
        streetAddress: res.streetAddress,
        region: res.region,
        postalCode: res.postalCode,
        jurisdictionCode: res.jurisdictionCode
      });
      if(res.citizenshipCountry) this.getCountryCode('addMoreDetailsForm');
    }
  }

  getCountryCode(forms){
    const countryCode = this[forms].value.citizenship
    this._app.getJurisdictionData(countryCode).subscribe((res) => {
      this.jurisdictions = res || [];
      if(res.length){
        this[forms].get('jurisdictionCode').setValue(res[0].code);
      }
    })
  }

  discardChanges(){
    this.isEditDetails = false;
    this.addMoreDetailsForm.disable();
    this.patchValues();
  }

  editDetails(){
    this.isEditDetails = true;
    this.addMoreDetailsForm.enable();
    this.addMoreDetailsForm.get('firstName').disable();
    this.addMoreDetailsForm.get('officerTypeUBO').disable();
  }

  saveChanges(){
    let addDetailsForm = {
      "firstName": this.officerName,
      "sharePercentage": parseFloat(this.addMoreDetailsForm.value.sharePercentUBO),
      "controlOfficerEntityType": this.officerTypeUBO,
      "lastName": this.addMoreDetailsForm.value.lastName,
      "controlOfficerType": this.officerItem.controlOfficerType,
      "maritalStatus": this.addMoreDetailsForm.value.maritalStatus,
      "dob": this.addMoreDetailsForm.value.dob,
      "phoneNumber": parseInt(this.addMoreDetailsForm.value.phoneNo),
      "email": this.addMoreDetailsForm.value.email=="" ? null : this.addMoreDetailsForm.value.email,
      "residenceAddress": this.addMoreDetailsForm.value.address,
      "ssn": this.addMoreDetailsForm.value.ssn,
      "taxId": this.addMoreDetailsForm.value.taxID,
      "percentageOfOwnership": this.addMoreDetailsForm.value.percentage,
      "currentStatus": this.addMoreDetailsForm.value.status,
      "designation": this.addMoreDetailsForm.value.designation,
      "citizenshipCountryCode": this.addMoreDetailsForm.value.citizenship,
      "companyId": this.addMoreDetailsForm.value.companyId,
      "companyType": this.addMoreDetailsForm.value.companyType,
      "incorporationDate": this.addMoreDetailsForm.value.incorporationDate,
      "branchCount": this.addMoreDetailsForm.value.branchCount,
      "parentCompanyName": this.addMoreDetailsForm.value.parentCompanyName,
      "parentCompanyID": this.addMoreDetailsForm.value.parentCompanyID,
      "streetAddress": this.addMoreDetailsForm.value.streetAddress,
      "region": this.addMoreDetailsForm.value.region,
      "postalCode": this.addMoreDetailsForm.value.postalCode,
      "jurisdictionCode": this.addMoreDetailsForm.value.jurisdictionCode,
      "id": this.officerItem.id
    }
    this._data.changeLoaderVisibility(true);
    this._app.getMoreDetailsOfficers(addDetailsForm).subscribe(() => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow("Details have been updated", `success`);
      this.closeModal();
    })
  }

  closeModal(){
    this.bsModalRef.hide();
    this.onClose.next('');
  }
}
