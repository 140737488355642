import { Pipe, PipeTransform } from '@angular/core';
import { UiStateService } from '../services/ui-state.service';

@Pipe({
    name: 'fileName'
})
export class FileName implements PipeTransform {

    constructor(public uiStateService: UiStateService) { }

    transform(value: any): any {
        const isMobile = this.uiStateService.getIsMobileResolution();
        let docName = ''
        if(value){
            const spliArr = value.split('.');
            if(isMobile){
                docName = spliArr[0].substring(0, 7);
                if(spliArr.length > 1){
                    docName += `...${spliArr[1]}`;
                }
            }
            else{
                docName = spliArr[0].substring(0, 12);
                if(spliArr.length > 1){
                    docName += `...${spliArr[1]}`;
                }
            }
        }
        return docName;
    }

}
