import { Injectable, PLATFORM_ID, Inject,EventEmitter } from "@angular/core";
import { isPlatformBrowser, DOCUMENT } from "@angular/common";
import { CookieService } from "ngx-cookie";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class DataService {
  public showLoader = new BehaviorSubject<boolean>(false);
  loaderInfo = this.showLoader.asObservable();

  changeLoaderVisibility(shouldShow: boolean) {
    this.showLoader.next(shouldShow);
    //this.getLoaderData();
  }

  loaderEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  getLoaderData(){
    this.showLoader.subscribe(data=>{

      this.loaderEvent.emit(data);
      console.log("after",this.showLoader);
    })
  }

  constructor(
    private _cookieService: CookieService,
    public sanitizer: DomSanitizer,
    private toastr: ToastrService
  ) {}
  public nameValue = new BehaviorSubject<boolean>(false);
  nameVal(val){
    this.nameValue.next(val)
  }
  public isBankCheck = new BehaviorSubject<boolean>(false);
  isBankCheckVal(val){
    this.isBankCheck.next(val)
  }
  public isCountryUploaded = new BehaviorSubject<boolean>(false);
  isCountryUploadedVal(val){
    this.isCountryUploaded.next(val)
  }
  public digiData = new BehaviorSubject<boolean>(false);
  digiDataVal(val){
    this.digiData.next(val)
  }
  public questionaryData = new BehaviorSubject<boolean>(false);
  questionaryDataVal(val){
    this.questionaryData.next(val)
  }
  public uboData = new BehaviorSubject<boolean>(false);
  uboDataVal(val){
    this.uboData.next(val)
  }
  public directorData = new BehaviorSubject<boolean>(false);
  directorDataVal(val){
    this.directorData.next(val)
  }
  public finishValue = new BehaviorSubject<boolean>(false);
  finishVal(val){
    this.finishValue.next(val)
  }
  public uboDetailsValue = new BehaviorSubject<boolean>(false);
  uboDetailsVal(val){
    this.uboDetailsValue.next(val)
  }
  public uboCorpDocList = new BehaviorSubject<any>('');
  uboCorpDocListVal(val){
    this.uboCorpDocList.next(val)
  }
  public kysNo=new BehaviorSubject<any>("");
  kysNoDetails(val){
    this.kysNo.next(val)
  }
  getCookiesByName(slug: any) {
    return this._cookieService.get(slug);
  }

  getCookieObjectByName(slug: any) {
    return this._cookieService.getObject(slug);
  }

  setCookieByName(slug: any, value: any, expire?: any) {
    let expires;
    if (expire instanceof Date) {
      expires = expire;
    } else if (!expire) {
      expires = "";
    } else {
      expires = this.getCookieSessionTime(expire);
    }
    this._cookieService.put(slug, value, {
      expires: expires,
    });
  }

  setCookieObject(slug: any, value: any, expire?: any) {
    let expires;
    if (expire instanceof Date) {
      expires = expire;
    } else if (!expire) {
      expires = "";
    } else {
      expires = this.getCookieSessionTime(expire);
    }
    this._cookieService.putObject(slug, value, {
      expires: expires,
    });
  }

  removeAllCookie() {
    this._cookieService.removeAll();
  }

  getCookieSessionTime(days: any) {
    var session = new Date();
    days = days ? days : 1;
    session.setTime(session.getTime() + days * 24 * 60 * 60 * 1000);
    return session;
  }

  removeQueryParams(url) {
    let currentUrl = url.substring(0, url.indexOf("?")).length
      ? url.substring(0, url.indexOf("?"))
      : url;
    return currentUrl;
  }

  scrollTop() {
    window.scrollTo(0, 0);
    // (<any>)window.scrollTo(0,0);
  }

  transform(html) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(html);
    // return this.sanitizer.bypassSecurityTrustStyle(html);
    // return this.sanitizer.bypassSecurityTrustResourceUrl(html);
    // return this.sanitizer.bypassSecurityTrustUrl(html);
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "data:image/jpeg;base64," + html
    );
  }

  isLoggedIn() {
    if (this.getCookiesByName("feas_token")) {
      return true;
    }
    return false;
  }

  //normalize date
  getNormalizedDate(inputDate) {
    console.log(inputDate)
    if (inputDate) {
      var tzoffset = new Date().getTimezoneOffset() * 60000;
      var localISOTime = new Date(inputDate - tzoffset)
        .toISOString()
        .split("T")[0];
      return localISOTime;
      console.log(localISOTime)
    }
    return inputDate;
  }

  toastrShow(message: any, type: any) {
    this.toastr.clear();
    switch (type) {
      case "info": {
        this.toastr.info("", message, { timeOut: 5000 });
        break;
      }
      case "error": {
        this.toastr.error("", message, { timeOut: 5000 });
        break;
      }
      case "success": {
        this.toastr.success("", message, { timeOut: 5000 });
        break;
      }
      default: {
        this.toastr.warning("", message, { timeOut: 5000 });
        break;
      }
    }
  }

  public corporateBasicInfo = new BehaviorSubject<any>('');
  corporateBasicInfoVal(val){
    this.corporateBasicInfo.next(val)
  }

  public corporateAccountInfo = new BehaviorSubject<any>('');
  corporateAccountInfoVal(val){
    this.corporateAccountInfo.next(val)
  }

  public corporateDigiUpload = new BehaviorSubject<any>('');
  corporateDigiUploadVal(val){
    this.corporateDigiUpload.next(val)
  }

  public corporateFaceCheck = new BehaviorSubject<any>('');
  corporateFaceCheckVal(val){
    this.corporateFaceCheck.next(val)
  }

  public corporateQuestionnaireInfo = new BehaviorSubject<any>('');
  corporateQuestionnaireInfoVal(val){
    this.corporateQuestionnaireInfo.next(val)
  }

  public corporateTradeInfo = new BehaviorSubject<any>('');
  corporateTradeInfoVal(val){
    this.corporateTradeInfo.next(val)
  }

  public corporateUboInfo = new BehaviorSubject<any>('');
  corporateUboInfoVal(val){
    this.corporateUboInfo.next(val)
  }

  public corporateDirectorInfo = new BehaviorSubject<any>('');
  corporateDirectorInfoVal(val){
    this.corporateDirectorInfo.next(val)
  }

  checksStatus(status){
    if(status.length){
      for(let item of status){
        if(item.kysTpeCheckSlug == 'basic-information'){
          this.corporateBasicInfoVal(item)
        }
        else if(item.kysTpeCheckSlug == 'account-information'){
          this.corporateAccountInfoVal(item)
        }
        else if(item.kysTpeCheckSlug == 'digital-upload'){
          this.corporateDigiUploadVal(item)
        }
        else if(item.kysTpeCheckSlug == 'face-check'){
          this.corporateFaceCheckVal(item)
        }
        else if(item.kysTpeCheckSlug == 'questionnaire'){
          this.corporateQuestionnaireInfoVal(item)
        }
        else if(item.kysTpeCheckSlug == 'trade-check'){
          this.corporateTradeInfoVal(item)
        }
        else if(item.kysTpeCheckSlug == 'ubo-check'){
          this.corporateUboInfoVal(item)
        }
        else if(item.kysTpeCheckSlug == 'director-check'){
          this.corporateDirectorInfoVal(item)
        }
      }
    }
    else{
      this.corporateBasicInfoVal(null);
      this.corporateAccountInfoVal(null);
      this.corporateDigiUploadVal(null);
      this.corporateFaceCheckVal(null);
      this.corporateQuestionnaireInfoVal(null);
      this.corporateTradeInfoVal(null);
      this.corporateUboInfoVal(null);
      this.corporateDirectorInfoVal(null);
    }
  }


  
}
