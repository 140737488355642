import { AnimationGroupPlayer } from '@angular/animations/src/players/animation_group_player';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome2.component.html',
  styleUrls: ['./welcome2.component.scss']
})
export class Welcome2Component implements OnInit {
  userName: any='';
  langArray: any[] = [
    { name: 'English', key: 'en' },
    { name: 'Spanish', key: 'spn' },
    { name: 'French', key: 'fr' },
    { name: 'German', key: 'gr' },
    { name: 'Italian', key: 'itl' },
  ];
  langOptions: string[] = ['en', 'fr', 'gr', 'itl', 'spn']
  langDefault: string = 'en';
  selectedLanguage: string = 'en';
  showLanguageTranslation: boolean = false;
  selectedLanguageName: string = 'English';
  constructor(
    private router: Router,
    private _app:AppService,
    private _data: DataService,
    private route: ActivatedRoute,
    public translate: TranslateService
    ){
      translate.addLangs(this.langOptions);
      const getLang = translate.getLangs();
      if(getLang.length){
        this.langArray = this.langArray.filter((f) => getLang.includes(f.key));
      }
      translate.setDefaultLang(this.langDefault);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|fr/) ? browserLang : this.langDefault);
    }

  checkbox = false; // bind first checkbox

  ngOnInit() {
    if(this._data.getCookiesByName("feas_token")){
      this._data.changeLoaderVisibility(true);
      this._app.dashboardConfig().pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(res=>{
        if(res.data && res.data.length){
          for(let item of res.data){
            if(item.keySlug == 'language-translation' && item.keyValue == "true"){
              this.showLanguageTranslation = true;
            }
          }
        }
      });
    }
    else{
      this.router.navigate(["/access-denied"]);
      this._data.changeLoaderVisibility(false);
    }
    //this.getAccountInfo();
    this.route.queryParams.subscribe(({accountType}) => {
      
    });
  }
  
  getAccountInfo(){
    this.route.queryParams.subscribe(({accountType}) => {
      if (accountType === 'corporate') {
        
        this._app.getAccountInfoforCorp().subscribe(res=>{
          console.log("detauls ",res)
          if(res.client.userName){
            if(res.client)
            {
             this.userName=res.client.userName
            }
          }
    
        })
      } 
      else if(accountType === 'ubo' || accountType === 'director'){
        this._app.getAllInfoforCO().subscribe(res=>{
          console.log("detauls ",res)
          if(res){
            if(res.client){
            this.userName=res.client.userName
            }
          }
          
        })
      }
      else {
        
      }
    })
  }

  onChangeLang(event){
    this.setTransalateLang(event.key);
    this.translate.use(event.key);
  }

  setTransalateLang(value){
    this.selectedLanguage = value;
  }

  onClick() {
    this.route.queryParams.subscribe(({accountType}) => {
      if (accountType === 'corporate') {
        this.router.navigate(['kys-corporate-feas'], {
          queryParams: { language: this.selectedLanguage }
        });
      } else if(accountType === 'ubo'){
        this.router.navigate(['kys-control-officers-feas'], {
          queryParams: { language: this.selectedLanguage }
        });
      }else if(accountType === 'officer'){
        this.router.navigate(['kys-control-officers-feas'], {
          queryParams: { language: this.selectedLanguage }
        });
      }else if(accountType === 'director'){
        this.router.navigate(['kys-control-officers-feas'], {
          queryParams: { language: this.selectedLanguage }
        });
      } else if(accountType === 'questionnaire') {
        this.router.navigate(['questionair-page'], {
          queryParams: { language: this.selectedLanguage }
        });
      }
      else {
        this.router.navigate(['kys-retail-feas'], {
          queryParams: { language: this.selectedLanguage }
        });
      }
    })
  }
}
