import { Component, Input, OnInit } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DataService } from 'src/app/services/data.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-kys-retail-digital-uploads',
  templateUrl: './kys-retail-digital-uploads.component.html',
  styleUrls: ['./kys-retail-digital-uploads.component.scss']
})
export class KysRetailDigitalUploadsComponent implements OnInit {
  uploadIndividualForm: FormGroup;
  accountID: any;
  allIdvCheckData: any;
  idvCheckforPrimary: any;
  addressCheckforPrimary: any;
  idvCheckforSecondary: any;
  addressCheckforSecondary: any;
  docNameforID: any;
  docNameforID1: any;
  docNameforAddrr: any;
  docNameforAddrr1: any;
  statesVal: any = "";
  statesVal1: any = "";
  docNameID: any;
  docIdID: any;
  docNameAddrr: any;
  docIdAddrr: any;
  docNameID1: any;
  docIdID1: any;
  docNameAddrr1: any;
  docIdAddrr1: any;
  idDocUploadedforIDData: any;
  state: any = [{}];
  states: any = [{}];
  statesJ1: any = [{}];
  statesJ2: any = [{}];
  @Input() isSelfie: boolean;
  isSecondary: boolean = false;
  adHockFile: string[] = [];
  isRefresh: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private _app: AppService,
    private _data: DataService
  ) { }

  tabs = [
    { active: true }
  ];

  ngOnInit() {

    this._app.getStates(localStorage.getItem('countryCode')).subscribe((response) => {
      this.states = response.data;
      console.log("hjsgfhjgf", this.states);
    });

    this.uploadIndividualForm = this.formBuilder.group({
      idDocNoInd: [''],
      idDocNoIndS: [''],
      idDocExpDInd: [''],
      idDocIssDInd: [''],
      idDocExpDS: [''],
      idDocIssDS: [''],
      adHockDocName: [''],
      adHockDocNum: [''],
      adHockDocIssue: [''],
      adHockDocExp: ['']
    });

    this._app.getAccountInfoForRetail().subscribe((response) => {
      this.accountID = response.accountId
      console.log("Account ID: ", this.accountID);
      if(response.accountType == 'JOINT'){
        this.isSecondary = true;
      }

      this._app.getAllCheckByAccount(this.accountID).subscribe((data) => {
        this.allIdvCheckData = data
        // For Primary Holder
        if (this.allIdvCheckData.primary.length !== 0) {
          this._app.getStates(localStorage.getItem('countryCode')).subscribe((response) => {
            this.states = [];
            this.states = response.data;
          });
          if (this.allIdvCheckData.primary.allCheck.idvChecks.length !== 0) {
            this.idvCheckforPrimary = this.allIdvCheckData.primary.allCheck.idvChecks[0].IDENTITY?this.allIdvCheckData.primary.allCheck.idvChecks[0].IDENTITY:""
            this.addressCheckforPrimary = this.allIdvCheckData.primary.allCheck.idvChecks[0].ADDRESS?this.allIdvCheckData.primary.allCheck.idvChecks[0].ADDRESS:""
          }
        }

        // For Secondary Holder
        if (this.allIdvCheckData.secondary.length !== 0) {
          this._app.getStates(localStorage.getItem('countryCodeJ2')).subscribe((response) => {
            this.statesJ2 = response.data;
            console.log("fchjsgjhs", this.statesJ2);
          });
          if (this.allIdvCheckData.secondary[0].allCheck.idvChecks.length !== 0) {
            this.idvCheckforSecondary = this.allIdvCheckData.secondary[0].allCheck.idvChecks[0].IDENTITY?this.allIdvCheckData.secondary[0].allCheck.idvChecks[0].IDENTITY:""
            this.addressCheckforSecondary = this.allIdvCheckData.secondary[0].allCheck.idvChecks[0].ADDRESS?this.allIdvCheckData.secondary[0].allCheck.idvChecks[0].ADDRESS:""
          }
        }
      });
    });
    this.getAvailableDocID()
    this.getAvailableDocAddrr()
    this.getAvailableDocID1()
    this.getAvailableDocAddrr1()
  }
  abc(event) {
    this.statesVal = event;
  }
  abc1(event) {
    this.statesVal1 = event;
  }
  getAvailableDocID() {
    let id1 = 'IDENTITY';
    this._app
      .getAvailableDoc(id1)
      .subscribe((data) => {
        this.docNameforID = data;
        console.log(this.docNameforID);
        
      });
  }

  getAvailableDocAddrr() {
    let id1 = 'ADDRESS';
    this._app
      .getAvailableDoc(id1)
      .subscribe((data) => {
        this.docNameforAddrr = data;
      });
  }

  getAvailableDocID1() {
    let id1 = 'IDENTITY';
    this._app
      .getAvailableDoc(id1)
      .subscribe((data) => {
        this.docNameforID1 = data;
        console.log(this.docNameforID1);
      });
  }

  getAvailableDocAddrr1() {
    let id1 = 'ADDRESS';
    this._app
      .getAvailableDoc(id1)
      .subscribe((data) => {
        this.docNameforAddrr1 = data;
        console.log(this.docNameforAddrr1);
      });
  }

  checkNameID(item, data) {
    this.docNameID = item.docName
    this.docIdID = item.kysDocumentID
    if (this.docNameID !== null) {
      data.isTrue = true;
    }
  }

  checkNameAddrr(item, data) {
    this.docNameAddrr = item.docName
    this.docIdAddrr = item.kysDocumentID;
    if (this.docNameAddrr !== null) {
      data.isTrue1 = true;
    }
  }

  checkNameID1(item, data) {
    this.docNameID1 = item.docName;
    this.docIdID1 = item.kysDocumentID;
    if (this.docNameID1 !== null) {
      data.isTrue11 = true;
    }
  }

  checkNameAddrr1(item, data) {
    this.docNameAddrr1 = item.docName;
    this.docIdAddrr1 = item.kysDocumentID;
    if (this.docNameAddrr1 !== null) {
      data.isTrue12 = true;
    }
  }

  onUpload11(event, data) {
    data.array11 = new Array
    data.size11 = 0
  }

  getFileDetails11(e, data) {
    for (var i = 0; i < e.target.files.length; i++) {
      data.array11.push(e.target.files[i]);
    }
    data.size11 = 1
  }
  uploadFiles11(data, val) {
    const idDocIssDInd = new DatePipe('en-US').transform(this.uploadIndividualForm.value.idDocIssDInd, 'dd/MM/yyyy');
    const idDocExpDInd = new DatePipe('en-US').transform(this.uploadIndividualForm.value.idDocExpDInd, 'dd/MM/yyyy');
    const formData = new FormData();
    formData.append('frontImage', data.array11[0]);
    formData.append('accountId', this.accountID);
    formData.append('docId', this.docIdID);
    formData.append('checkId', val);
    formData.append('docName', this.docNameID);
    formData.append('idNumber', this.uploadIndividualForm.value.idDocNoInd);
    if (idDocIssDInd !== null) {
      formData.append('docIssueDate', idDocIssDInd);
    }
    if (idDocExpDInd !== null) {
      formData.append('docExpiryDate', idDocExpDInd);
    }
    this._data.changeLoaderVisibility(true);
    this._app.getDocumentUpload(formData).subscribe((response) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow('Documents successfully uploaded', `success`);
      this.idDocUploadedforIDData = response['data'];
      console.log('result', this.idDocUploadedforIDData);
      data.upload11 = true
    }
    );
    data.array11 = new Array()
  }
  cancelUpload11(data) {
    data.array11 = new Array
    data.size11 = 0
  }

  onUpload12(event, data) {
    data.array12 = new Array
    data.size12 = 0
  }

  getFileDetails12(e, data) {
    console.log('Address Docs Files: ', e.target.files);
    for (var i = 0; i < e.target.files.length; i++) {
      data.array12.push(e.target.files[i]);
    }
    data.size12 = 1
  }
  uploadFiles12(data, val) {
    const formData = new FormData();
    formData.append('frontImage', data.array12[0]);
    formData.append('accountId', this.accountID);
    formData.append('docId', this.docIdAddrr);
    formData.append('checkId', val);
    formData.append('docName', this.docNameAddrr);
    formData.append('stateCode', this.statesVal);
    this._data.changeLoaderVisibility(true);
    this._app.getDocumentUpload(formData).subscribe((response) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow('Documents successfully uploaded', `success`);
      this.idDocUploadedforIDData = response['data'];
      console.log('result', this.idDocUploadedforIDData);
      data.upload12 = true
    }
    );
    data.array12 = new Array()
  }
  cancelUpload12(data) {
    data.array12 = new Array
    data.size12 = 0
  }

  onUpload13(event, data) {
    data.array13 = new Array()
    data.size13 = 0
  }

  getFileDetails13(e, data) {
    console.log('Address Docs Files: ', e.target.files);
    for (var i = 0; i < e.target.files.length; i++) {
      data.array13.push(e.target.files[i]);
    }
    data.size13 = 1
  }
  uploadFiles13(data, val) {
    const idDocIssDS = new DatePipe('en-US').transform(this.uploadIndividualForm.value.idDocIssDS, 'dd/MM/yyyy');
    const idDocExpDS = new DatePipe('en-US').transform(this.uploadIndividualForm.value.idDocExpDS, 'dd/MM/yyyy');
    const formData = new FormData();
    formData.append('frontImage', data.array13[0]);
    formData.append('accountId', this.accountID);
    formData.append('docId', this.docIdID1);
    formData.append('checkId', val);
    formData.append('docName', this.docNameID1);
    formData.append('idNumber', this.uploadIndividualForm.value.idDocNoIndS);
    if (idDocIssDS !== null) {
      formData.append('docIssueDate', idDocIssDS);
    }
    if (idDocExpDS !== null) {
      formData.append('docExpiryDate', idDocExpDS);
    }
    this._data.changeLoaderVisibility(true);
    this._app.getDocumentUpload(formData).subscribe((response) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow('Documents successfully uploaded', `success`);
      this.idDocUploadedforIDData = response['data'];
      console.log('result', this.idDocUploadedforIDData);
      data.upload13 = true
    }
    );
    data.array13 = new Array()
  }
  cancelUpload13(data) {
    data.array13 = new Array()
    data.size13 = 0
  }

  onUpload14(event, data) {
    data.array14 = new Array()
    data.size14 = 0
  }

  getFileDetails14(e, data) {
    console.log('Address Docs Files: ', e.target.files);
    for (var i = 0; i < e.target.files.length; i++) {
      data.array14.push(e.target.files[i]);
    }
    data.size14 = 1
  }
  uploadFiles14(data, val) {
    const formData = new FormData();
    formData.append('frontImage', data.array14[0]);
    formData.append('accountId', this.accountID);
    formData.append('docId', this.docIdAddrr1);
    formData.append('checkId', val);
    formData.append('docName', this.docNameAddrr1);
    formData.append('stateCode', this.statesVal1);
    this._data.changeLoaderVisibility(true);
    this._app.getDocumentUpload(formData).subscribe((response) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow('Documents successfully uploaded', `success`);
      this.idDocUploadedforIDData = response['data'];
      console.log('result', this.idDocUploadedforIDData);
      data.upload14 = true
    });
    data.array14 = new Array()
  }
  cancelUpload14(data) {
    data.array14 = new Array()
    data.size14 = 0
  }

  onAdHockUpload($event){
    console.log($event);
  }

  getAdHockFileDetails(e){
    for (var i = 0; i < e.target.files.length; i++) {
      this.adHockFile.push(e.target.files[i]);
    }
  }

  uploadAdHock(type){
    const formData = new FormData()
    formData.append("frontImage", this.adHockFile[0]);
    formData.append("accountId", this.accountID);
    formData.append("docName", this.uploadIndividualForm.value.adHockDocName);
    formData.append("kysDocType", type);
    this._data.changeLoaderVisibility(true);
    this._app.getDocumentAdHockUpload(formData).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
      this._data.toastrShow('Documents successfully uploaded', `success`);
      this.isRefresh = !this.isRefresh;
      this.uploadIndividualForm.reset();
      this.adHockFile = []
    })
  }

  getRetailSubmit() {
    let obj;
    this._data.changeLoaderVisibility(true);
    if (!this.isSelfie) {
      this._app.getRetailSubmit(obj).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((response) => {
        this._data.toastrShow(`Submitted Successfully`, 'success');
        const payload = {
          "message": 'Primary Holder Submitted Successfully',
          "page": "Primary Holder Submit",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("primaryKysNo"), payload).subscribe();
        if(this.isSecondary){
          const message = {
            "message": 'Secondary Holder Submitted Successfully',
            "page": "Secondary Holder Submit",
            "geoLocation": null
          }
          this._app.feasAddActivityLog(localStorage.getItem("secondaryKysNo"), message).subscribe();
        }
      },
        (err: HttpErrorResponse) => {
          const payload = {
            "message": err,
            "page": "Primary Holder Submit",
            "geoLocation": null
          }
          this._app.feasAddActivityLog(localStorage.getItem("primaryKysNo"), payload).subscribe();
          if(this.isSecondary){
            const message = {
              "message": err,
              "page": "Secondary Holder Submit",
              "geoLocation": null
            }
            this._app.feasAddActivityLog(localStorage.getItem("secondaryKysNo"), message).subscribe();
          }
        }
      )
    }
    else {
      setTimeout(() => {
        this._data.changeLoaderVisibility(false);
        this._data.toastrShow('Documents successfully saved', `success`);
      }, 200);
    }
  }

}
