import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kys-sidebar',
  templateUrl: './kys-sidebar.component.html',
  styleUrls: ['./kys-sidebar.component.scss']
})
export class KysSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
