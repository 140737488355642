import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import { AppService } from '../../../services/app.service';
import { DataService } from '../../../services/data.service';
import { first } from 'rxjs/operators';
import { HttpErrorResponse, } from '@angular/common/http';
@Component({
  selector: 'app-trade-check',
  templateUrl: './trade-check.component.html',
  styleUrls: ['./trade-check.component.scss']
})
export class TradeCheckComponent implements OnInit {
  @Output() output = new EventEmitter<any>();

  tradeForm: FormGroup;
  dropdownSettings = {};
  tradeCheckId;
  countries=[];
  selectedItems=[];
  tradeOption=[
    {amount:"less than 1 million" },
    {amount:"1 million to 5 million" },
    {amount:"5 million to 1 billion" },
    {amount:"1 Billion and above" },
  ]
  products=['LIVE ANIMALS; ANIMAL PRODUCTS','VEGETABLE PRODUCTS','ANIMAL, VEGETABLE OR MICROBIAL FATS AND OILS AND THEIR CLEAVAGE PRODUCTS'
,'PREPARED FOODSTUFFS; BEVERAGES, SPIRITS AND VINEGAR; TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES','MINERAL PRODUCTS','PRODUCTS OF THE CHEMICAL OR ALLIED INDUSTRIES',
'PLASTICS AND ARTICLES THEREOF; RUBBER AND ARTICLES THEREOF','RAW HIDES AND SKINS, LEATHER, FURSKINS AND ARTICLES THEREOF; SADDLERY AND HARNESS; TRAVEL GOODS, HANDBAGS AND SIMILAR CONTAINERS; ARTICLES OF ANIMAL GUT',
'WOOD AND ARTICLES OF WOOD; WOOD CHARCOAL; CORK AND ARTICLES OF CORK','PULP OF WOOD OR OF OTHER FIBROUS CELLULOSIC MATERIAL','TEXTILES AND TEXTILE ARTICLES',
'FOOTWEAR, HEADGEAR, UMBRELLAS, SUN UMBRELLAS, WALKING-STICKS, SEAT-STICKS','ARTICLES OF STONE, PLASTER, CEMENT, ASBESTOS, MICA OR SIMILAR MATERIALS','NATURAL OR CULTURED PEARLS, PRECIOUS OR SEMI-PRECIOUS STONES, PRECIOUS METALS'
,'BASE METALS AND ARTICLES OF BASE METAL','MACHINERY AND MECHANICAL APPLIANCES; ELECTRICAL EQUIPMENT','VEHICLES, AIRCRAFT, VESSELS AND ASSOCIATED TRANSPORT EQUIPMENT',
'OPTICAL, PHOTOGRAPHIC, CINEMATOGRAPHIC, MEASURING, CHECKING, PRECISION, MEDICAL OR SURGICAL INSTRUMENTS AND APPARATUS','ARMS AND AMMUNITION',
'MISCELLANEOUS MANUFACTURED ARTICLES','WORKS OF ART, COLLECTORS PIECES AND ANTIQUES']
  tradeDone: any;
  constructor(private formBuilder: FormBuilder,  private _data: DataService,
    private _app: AppService,) { }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "countryCode",
      textField: "countryName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
    this.tradeForm = this.formBuilder.group({
      carrierName: [''],
      carrierCountry: [''],
      agentName: [''],
      agentCountry: [''],
      shipName: [''],
      shipCountry: [''],
      freightName: [''],
      route:[''],
      freightCountry: [''],
      goodsName: [''],
      goodsCountry: [''],
      vesselName: [''],
      vesselCountry:[''],
      customerTranGrtThree:[''],
      tradeTranFive:[''],
      validLicense:[''],
      tradeTransaction:[''],
      tradeTransactionYear:[''],
      productCategory:[''],
      bankName:[''],
      
    })
    this._data.corporateTradeInfo.subscribe(res => {
      this.tradeDone = res;
    });

    this._app.getAllCountry().subscribe((response) => {
      this.countries = response
    });

    this.getTradeNo();

    
  }
  submitDetails(){
    let routes=[];
    if(this.tradeForm.value.route && this.tradeForm.value.route.length) this.tradeForm.value.route.forEach(c=>{
      routes.push(c.countryName)
    })
    let payload={
      "carrierName" : this.tradeForm.value.carrierName,
      "carrierCountryCode" : this.tradeForm.value.carrierCountry.countryCode,
      "agentName" : this.tradeForm.value.agentName,
      "agentCountryCode" : this.tradeForm.value.agentCountry.countryCode,
      "shippingCompanyName" : this.tradeForm.value.shipName,
      "shippingCompanyCountryCode": this.tradeForm.value.shipCountry.countryCode,
      "freightForwarderName" : this.tradeForm.value.freightName,
      "freightForwarderCountryCode" : this.tradeForm.value.freightCountry.countryCode,
      "goodsAndCommoditiesCountryCode" : this.tradeForm.value.goodsCountry.countryCode,
      "tradeVesselName": this.tradeForm.value.vesselName,
      "tradeVesselCountryCode" : this.tradeForm.value.vesselCountry.countryCode,
      "shippingRoute" : routes,
      "numberOfCustomer": this.tradeForm.value.customerTranGrtThree,
      "tradeTransactionValue": this.tradeForm.value.tradeTransaction,
      "customerOrAccountOnboardedCount": this.tradeForm.value.tradeTranFive,
      "yearTradeTransactionVolume" : this.tradeForm.value.tradeTransactionYear,
      "customerOrAccountCountry" : [],
      "bankingRelationShipCountry" : [],
      "extendedTradeCycleGoods" : "test",
      "validImportExportLicence" : this.tradeForm.value.validLicense,
      "productValueInternationalPriceCheck" : true,
      "bankName": this.tradeForm.value.bankName,
      "highRiskCategoryProduct" :this.tradeForm.value.productCategory,
     
    }
   this._data.changeLoaderVisibility(true);
    this._app.submitTradeInfo(this.tradeCheckId, payload).pipe(first()).subscribe((data) =>{
      this._data.changeLoaderVisibility(false)
      this._data.toastrShow('Your Details have been saved successfully', `success`);
      if(this.tradeDone){
        this.tradeDone = { ...this.tradeDone, kysTpeCheckStatus: 'COMPLETED' }
      }
      let status = this.tradeDone
      this._app.sendStatus(status).subscribe(() => {
        this._app.getStatus().subscribe(res => {
          this._data.checksStatus(res['data']);
          this.output.emit('tradeDone');
        })
      })
      const payload = {
        "message": 'Trade Details have been saved successfully',
        "page": "Trade Details",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Trade Details",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
        this._data.changeLoaderVisibility(false)
      }
    )
    }
  
  getTradeNo(){
    this._data.kysNo.subscribe(res=>{
      if(res!=""){
        let kys=res
        this._app.getTradeData(kys).subscribe(res=>{
          this.tradeCheckId=res.tradeCheckId;
        })
      }
     
    })
    
  }
}
