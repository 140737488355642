import { Injectable } from '@angular/core';
import { CanActivate, Router ,ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable , of } from 'rxjs';
import { DataService } from '../services/data.service';
import { KycService } from '../services/kyc.service';
@Injectable({
  providedIn: 'root'
})
export class KycGuard implements CanActivate {

	constructor(
        private _router: Router, 
        private data: DataService,
        private _kyc: KycService
    ) {}

	canActivate(
	next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean{
		let kycState  = this._kyc.getData("kycState");
		let routesMap = this._kyc.getData("routesMap");
		let checkName = null;
		for(var key in routesMap){
			if(routesMap[key] == state.url){
				checkName = key ;
			}
		}
		let checkState = kycState.filter(obj=>obj.standardSlugGroup == checkName)[0];
		console.log(checkName);
		console.log(state.url);
		if(checkState && checkState.isAllowed){
			return true;
		}
		else{
			this._router.navigate(['/access-denied']);
			return false;
		}
		
		
	}
}
