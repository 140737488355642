import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, first } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-add-officer-details',
  templateUrl: './add-officer-details.component.html',
  styleUrls: ['./add-officer-details.component.scss']
})
export class AddOfficerDetailsComponent implements OnChanges {
  @Input() uboData;
  @Output() close = new EventEmitter<any>();
  maturityStatus:any[] = [
    {key:'Single', value:'SINGLE'},
    {key:'Married', value:'MARRIED'},
    {key:'Widowed', value:'WIDOWED'},
    {key:'Divorced', value:'DIVORCED'},
    {key:'Separated', value:'SEPARATED'},
  ]
  currentStatus:any[] = [
    {key: 'Active', value: 'Active'},
    {key: 'Dormant', value: 'Dormant'},
    {key: 'Active in Progress', value: 'Active in Progress'},
    {key: 'Under liquidation', value: 'Under liquidation'},
    {key: 'Under process of Striking Off', value: 'Under process of Striking Off'},
    {key: 'Strike off', value: 'Strike off'},
    {key: 'Amalgamated', value: 'Amalgamated'},
    {key: 'Liquidated', value: 'Liquidated'},
    {key: 'Dissolved', value: 'Dissolved'},
    {key: 'Converted to LLP', value: 'Converted to LLP'},
    {key: 'Converted to LLP and dissolved', value: 'Converted to LLP and dissolved'},
  ]
  currentStatusInd:any[] = [
    {key: 'Active', value: 'Active'},
    {key: 'Inactive', value: 'Inactive'},
  ]
  addMoreDetailsForm: FormGroup = this.formBuilder.group({
    lastName: [null,[
      Validators.pattern(/^[a-zA-Z ]+$/)
    ]],
    dob: [null],
    maritalStatus: [null],
    phoneNo: [null,[
      Validators.pattern(/^[0-9]\d*(\.\d+)?$/)
    ]],
    address: [null],
    ssn: [null],
    taxID: [null],
    percentage: [null],
    status: [null],
    designation: [null],
    firstName: [null],
    email: [null,[
      Validators.pattern(/@/)
    ]],
    sharePercentUBO: [null],
    officerTypeUBO: [null],
    citizenship: ['', Validators.required],
    jurisdictionCode: [null],
    companyId: [null],
    companyType: [null],
    incorporationDate: [null],
    branchCount: [null],
    parentCompanyName: [null],
    parentCompanyID: [null],
    streetAddress: [null],
    region: [null],
    postalCode: [null],
  });
  remoteValid: boolean = false;
  jurisdictions: any[] = [];
  countries: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private _data: DataService,
    private _app: AppService,
  ) {
    this.getAllCountries()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.uboData.currentValue) {
      this.uboData = changes.uboData.currentValue;
      if (this.uboData) {
        this.addMoreDetailsForm.patchValue({
          firstName: this.uboData.firstName,
          email: this.uboData.email,
          sharePercentUBO: this.uboData.sharePercentage,
          officerTypeUBO: this.uboData.controlOfficerEntityType,
          citizenship: this.uboData.citizenshipCountryCode
        })
      }
    }
  }

  onDateInputKeydown(event: KeyboardEvent) {
    event.preventDefault(); // Prevent any key input
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.addMoreDetailsForm.controls;
  }

  // For UBO

  getMoreDetails() {
    let addDetailsForm = {
      "firstName": this.uboData.firstName,
      "sharePercentage": parseFloat(this.addMoreDetailsForm.value.sharePercentUBO),
      "controlOfficerEntityType": this.uboData.controlOfficerEntityType,
      "lastName": this.addMoreDetailsForm.value.lastName,
      "controlOfficerType": 'UBO',
      "maritalStatus": this.addMoreDetailsForm.value.maritalStatus,
      "dob": this.addMoreDetailsForm.value.dob,
      "phoneNumber": parseInt(this.addMoreDetailsForm.value.phoneNo),
      "email": this.addMoreDetailsForm.value.email == '' ? null : this.addMoreDetailsForm.value.email,
      "residenceAddress": this.addMoreDetailsForm.value.address,
      "ssn": this.addMoreDetailsForm.value.ssn,
      "taxId": this.addMoreDetailsForm.value.taxID,
      "percentageOfOwnership": this.addMoreDetailsForm.value.percentage,
      "currentStatus": this.addMoreDetailsForm.value.status,
      "designation": this.addMoreDetailsForm.value.designation,
      "citizenshipCountryCode": this.addMoreDetailsForm.value.citizenship,
      "companyId": this.addMoreDetailsForm.value.companyId,
      "companyType": this.addMoreDetailsForm.value.companyType,
      "incorporationDate": this.addMoreDetailsForm.value.incorporationDate,
      "branchCount": this.addMoreDetailsForm.value.branchCount,
      "parentCompanyName": this.addMoreDetailsForm.value.parentCompanyName,
      "parentCompanyID": this.addMoreDetailsForm.value.parentCompanyID,
      "streetAddress": this.addMoreDetailsForm.value.streetAddress,
      "region": this.addMoreDetailsForm.value.region,
      "postalCode": this.addMoreDetailsForm.value.postalCode,
      "jurisdictionCode": this.addMoreDetailsForm.value.jurisdictionCode,
      "id": this.uboData.id
    }
    this._data.changeLoaderVisibility(true);
    this._app.getMoreDetailsOfficers(addDetailsForm).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
      this.remoteValid = true;
      this._data.toastrShow("Details have been submitted", `success`)
    })
  }

  closeInfo($event: MouseEvent) {
    this.close.emit('backFromDetails');
  }

  // Send Remote Link
  sendRemoteLink() {
    let obj;
    this._data.changeLoaderVisibility(true);
    this._app.startKYC(this.uboData.id).subscribe((res) => {
      let event: MouseEvent;
      this.closeInfo(event);
      // this._app.sendRemoteLink(id, obj).subscribe((response) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow(`Remote link has been sent`, 'success');
      const payload = {
        "message": 'Remote Link has been sent to the ' + res['data'].tpe.kysNo,
        "page": "Control Officer Remote Link",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      // });
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Control Officer Remote Link",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      });
  }

  // Get All Couontries
  getAllCountries() {
    this._app.getAllCountries().subscribe(
      (res) => {
        this.countries = res['data'];
      },
      (err) => {
      }
    );
  }

  getCountryCode(forms) {
    const countryCode = this[forms].value.citizenship
    this._app.getJurisdictionData(countryCode).subscribe((res) => {
      this.jurisdictions = res || [];
      if (res.length) {
        this[forms].get('jurisdictionCode').setValue(res[0].code);
      }
    })
  }

}
