import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DataService } from 'src/app/services/data.service';
import { AppService } from "src/app/services/app.service";
import { BankDocumentInfo } from 'src/app/models/document';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
  FormArray,
  NgForm,

} from "@angular/forms";
import { finalize, first } from 'rxjs/operators';
import { CustomValidators } from "ng2-validation";
import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { style } from '@angular/animations';
import { element } from 'protractor';
import { Observable } from 'rxjs/internal/Observable';
import { isNgTemplate } from '@angular/compiler';


@Component({
  selector: 'app-corp-digital-upload',
  templateUrl: './corp-digital-upload.component.html',
  styleUrls: ['./corp-digital-upload.component.scss']
})
export class CorpDigitalUploadComponent implements OnInit {
  @Output() output = new EventEmitter<any>();

  myFile: string[] = [];
  myFile1: string[] = [];
  myFile2: string[] = [];
  step: any;
  stepp: any
  idDocUploadedData: any;
  idDocUpload: string;
  accountID: string | Blob;
  bankDocUploadedData: any;
  bankDocUpload: string;
  stateField: boolean = false;
  copmanyAccountInfoData: any;
  docArray: BankDocumentInfo[] = [
    // {key:"IDENTITY", value:'IDENTITY'},
    // {key:"ADDRESS", value:'ADDRESS'},
    { key: "LEGAL", value: 'LEGAL' },
    { key: "FINANCE", value: 'FINANCE' },
    { key: "CORPORATE", value: 'CORPORATE' },
  ];
  docForm: FormGroup;
  docNameData: any;
  docNameArray: any;
  // doctype: string='';
  // docName: any;
  docUploadedData: any;
  docUpload: string;
  inputField: boolean = false;
  isRefreshed: boolean = false;
  docUploaded: any;
  bankDocUpload2: string;
  imageUrl1: any;
  images3Url1: any;
  states: any = [{}];
  imageUrl2: any;
  images3Url2: any;
  isTrue: boolean = false;
  isTrue1: boolean = false;
  docList: any=[];
  checkId: any;
  stateVal: any = "";
  idNo: boolean = false;
  countryCode: any;
  openModal:any=null;
  @Input() checks:any;
  docuName: any=null;
  documentNo: any=null;
  // documentName: any;
  digitalDone: any;
  selectedCountry: any;
  imgErrorMsg: string;
  formSubmit: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private _data: DataService,
    private _app: AppService,
    public router: Router,
    public route: ActivatedRoute,
    private http: HttpClient) { }

  uploadForm = this.formBuilder.group({
    doctype:[null,[
      Validators.required
    ]],
    docName:[null,[
      Validators.required
    ]],
    otherDocName:[null],
    stateCode:[null],
    docIdNo:[null]
  })
  initialUploadValues;


  ngOnInit() {
    //this._data.changeLoaderVisibility(true);
    this.initialUploadValues = this.uploadForm.value
    this._data.isCountryUploaded.subscribe(res => {
      this.selectedCountry = res;
      console.log(res);
      this.getDigitalList();
    })
    this.goto("1", "1");
    this.getAccountInfo()
    this.docForm = this.formBuilder.group({
      documentName: [''],
      documentNo: ['']
    });
    this._data.corporateDigiUpload.subscribe(res => {
      this.digitalDone = res;
      // this.getDigitalList();
    });
  }

  get formData(): { [key: string]: AbstractControl } {
    return this.uploadForm.controls;
  }

  stateSelected(event) {
    this.stateVal = event;
  }
  getAccountInfo() {
    this._app.getAccountInfoforCorp().pipe(first()).subscribe((data) => {
      this.copmanyAccountInfoData = data["data"]
      this.accountID = this.copmanyAccountInfoData.accountId
      console.log(this.accountID);
      this._app.getCompanyInfo(this.accountID).subscribe((res) => {
        if(res.data.country && res.data.country.countryCode){
          this.countryCode = res.data.country.countryCode;
          this._app.getStates(this.countryCode).subscribe((response) => {
            this.states = response.data;
          });
        }
      });
      this.getDigitalList();
    })
  }

  getDigitalList(){
    if(this.accountID) this._app.getDocList(this.accountID).subscribe((response) => {
      this.docList = response;
      this._data.digiDataVal(this.docList);
      if(response.some((e) => e.status != 'CREATED')){
        if(this.digitalDone && this.digitalDone.kysTpeCheckStatus == 'UNCHECK'){
          this.digitalDone = { ...this.digitalDone, kysTpeCheckStatus: 'COMPLETED' }
        }
        let status = this.digitalDone
        this._app.sendStatus(status).subscribe(() => {
          this._app.getStatus().subscribe(res => {
            this._data.checksStatus(res['data'])
          })
        })
      }
    });
  }

  checkType(val) {
    // this.doctype = val
    let docType = this.uploadForm.controls.doctype.value;
    // console.log("Doc Type: ", this.doctype);
    if (docType === "IDENTITY") {
      this.idNo = true
    }
    else {
      this.idNo = false;
    }
    if(this.idNo){
      this.uploadForm.controls.docIdNo.setValidators([Validators.required, Validators.pattern(/^[0-9]+$/)])
    }else{
      this.uploadForm.controls.docIdNo.clearValidators();
    }
    this.uploadForm.controls.docIdNo.updateValueAndValidity();
    if (docType === "ADDRESS") {
      this.stateField = true;
    }
    else {
      this.stateField = false;
    }
    if(this.states.length && this.stateField) {
      this.uploadForm.controls.stateCode.setValidators([Validators.required])
    }else{
      this.uploadForm.controls.stateCode.clearValidators();
    }
    this.uploadForm.controls.stateCode.updateValueAndValidity();
    this.getDocName(docType)
  }

  checkName(val) {
    console.log("this si ",val)
    let docName = this.uploadForm.controls.docName.value
    if (docName !== null) {
      this.isTrue = true
      this.isTrue1 = true
    }
    var docNameP = val
    if (docNameP.substring(0, 5) === "Other") {
      this.inputField = true;
    }else{
      this.inputField = false;
    }
    if(this.inputField){
      this.uploadForm.controls.otherDocName.setValidators([Validators.required,Validators.pattern(/^[a-zA-Z0-9 ]+$/)])
    }else{
      this.uploadForm.controls.otherDocName.clearValidators();
    }
    this.uploadForm.controls.otherDocName.updateValueAndValidity();
  }

  getName() {
    if(this.inputField){
      // this.docName = this.documentName
      // this.uploadForm.controls.docName.setValue(this.uploadForm.controls.otherDocName.value)
    } 
  }

  getDocName(id) {
    this._app.getDocName(id).pipe(first()).subscribe((data) => {
      this.docNameData = data["data"]
      console.log(this.docNameData);
      this.docNameArray = this.docNameData
    })
  }

  goto(n, m) {
    this.step = n,
      this.stepp = m
  }


  gotoBack() {
    this.step = false
    this.stepp = false
    this.openModal=null
    this.formReset();
  }
  getCheckId(val) {
    this.checkId = val
    console.log(this.checkId);

  }

  onUpload(event, item) {
    item.array = new Array()
    item.size = 0
    console.log("OnUpload: ", event)
  }

  getFileDetails(e, item) {
    console.log("Address Docs Files: ", e.target.files);
    for (var i = 0; i < e.target.files.length; i++) {
      item.array.push(e.target.files[i]);
    }
    item.size = 1
    console.log("Uploaded Files: ", item.array)
  }
  uploadFiles(item, val, val1) {
    const formData = new FormData()
    formData.append("frontImage", item.array[0])
    formData.append("accountId", this.accountID)
    formData.append("checkId", val1)
    formData.append("docName", val)
    this._data.changeLoaderVisibility(true);
    this._app.getIdDocUploadforCorp(formData).subscribe(data => {
      this.idDocUploadedData = data["data"]
      this._data.toastrShow('Documents successfully uploaded', `success`)
      if(this.digitalDone){
        this.digitalDone = { ...this.digitalDone, kysTpeCheckStatus: 'COMPLETED' }
      }
      let status = this.digitalDone
      this._app.sendStatus(status).subscribe(() => {
        this._app.getStatus().subscribe(res => {
          this._data.checksStatus(res['data'])
        })
      })
      this._data.changeLoaderVisibility(false);
      item.upload = true
      item.array = new Array()
      this.images3Url1 = this.idDocUploadedData.docPathF
      this.getAccountInfo()

    }
    );
    //this.getViewDoc1(this.images3Url1)
    this.isRefreshed = !this.isRefreshed
    item.array = new Array()
  }
  cancelUpload(item) {
    item.array = new Array()
    item.size = 0
  }

  getViewDoc1(id) {
    console.log('Image Url 1');
    let abc = {
      s3url: id,
    };
    this._app.getViewDoc(abc).subscribe((data) => {
      this.imageUrl1 = data['data'];
    });
  }

  removeFile(){
    this.myFile2=new Array();
  }

  getFileDetails2(e) {
    console.log("Address Docs Files: ", e.target.files);
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFile2.push(e.target.files[i]);
    }
    console.log("Uploaded Files: ", this.myFile2)
  }

  formReset(){
    this.uploadForm.reset(this.initialUploadValues);
    this.stateField = false;
    this.idNo= false;
    this.inputField = false;
    Object.keys(this.uploadForm.controls).forEach(key => {
      this.uploadForm.get(key).markAsUntouched();
    });
  }

  onSubmit(){
    this.formSubmit = true;
    if(this.uploadForm.valid && this.myFile2[0]){
      this.uploadFiles2();
      this.gotoBack();
    }else{
      Object.keys(this.uploadForm.controls).forEach(key => {
        this.uploadForm.get(key).markAsTouched();
      });
    }
  }

  uploadFiles2() {
    if(!this.inputField){
      const formData = new FormData()
      formData.append("frontImage", this.myFile2[0])
      formData.append("accountId", this.accountID)
      formData.append("docName", this.uploadForm.controls.docName.value)
      formData.append("idNumber", this.uploadForm.controls.docIdNo.value)
      formData.append("stateCode", this.uploadForm.controls.stateCode.value);
      this._data.changeLoaderVisibility(true);
      this._app.getIdDocUploadforCorp(formData).subscribe(data => {
        this.docUploaded = data
        this.docUploadedData = data["data"]
        this._data.toastrShow('Documents successfully uploaded', `success`)
        this._data.changeLoaderVisibility(false);
        this.idNo = false
        this.myFile2 = new Array()
        this.getAccountInfo()
        this.formSubmit=false;
        this.formReset();
      }
      );
      this.isRefreshed = !this.isRefreshed
      this.myFile2 = new Array()
    }
    else{
      // this.uploadAdHock('UNKNOWN')
      this.uploadAdHock(this.uploadForm.controls.doctype.value)
    }
    this.inputField=false;
    // this.documentName=''

      
  }
  uploadAdHock(type){
    const formData = new FormData()
    formData.append("frontImage", this.myFile2[0]);
    formData.append("accountId", this.accountID);
    formData.append("docName", this.uploadForm.controls.docName.value);
    formData.append("kysDocType", type);
    this._data.changeLoaderVisibility(true);
    this._app.getDocumentAdHockUpload(formData).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
      this._data.toastrShow('Documents successfully uploaded', `success`);
      this.isRefreshed = !this.isRefreshed
      this.myFile2 = new Array()
      this.getAccountInfo();
      this.formSubmit=false;
      this.formReset();
    })
  }
  cancelUpload2() {
    this.myFile2 = []
  }

  gotoNext() {
    if(this.digitalDone){
      this.digitalDone = { ...this.digitalDone, kysTpeCheckStatus: 'COMPLETED' }
    }
    let status = this.digitalDone
    this._app.sendStatus(status).subscribe(() => {
      this._app.getStatus().subscribe(res => {
        this._data.checksStatus(res['data']);
        this.output.emit('digitalDone');
      })
    });
    this._data.toastrShow('Your Details have been saved successfully', `success`)
  }


  getFinalSubmit() {
    let abc;
    this._data.changeLoaderVisibility(true);
    this._app.getFinalSubmit(abc).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((response) => {
      this._data.toastrShow(`All details submitted Successfully`, 'success');
      
      const payload = {
        "message": 'Company details submitted Successfully',
        "page": "Company Final Submit",
        "geoLocation": null
      }
      this._data.finishVal(true)
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Company Final Submit",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    );
  }

}

