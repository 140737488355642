import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DataService } from 'src/app/services/data.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FormBuilder, FormGroup,
  Validators,
  FormControl,
  AbstractControl,
 } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { UiStateService } from 'src/app/services/ui-state.service';

@Component({
  selector: 'app-kys-co-digital-upload',
  templateUrl: './kys-co-digital-upload.component.html',
  styleUrls: ['./kys-co-digital-upload.component.scss']
})
export class KysCoDigitalUploadComponent implements OnInit {
  @Output() output = new EventEmitter<any>();

  uploadIndividualForm: FormGroup;
  accountID: any;
  allIdvCheckData: any;
  idvCheckforPrimary: any;
  addressCheckforPrimary: any;
  docNameforID: any;
  docNameforAddrr: any;
  // docNameID: any;
  // docIdID: any;
  docNameAddrr: any;
  docIdAddrr: any;
  idDocUploadedforIDData: any;
  states: any = [{}];
  stateVal: any = "";
  docList:any=[]
  @Input() isSelfie: boolean;
  tabs = [{ active: true }];
  openModal="";
  uploadedFile:any=[];
  checkId: any;
  // documentNo:any=null;
  // issueDate:any=null;
  // exparyDate:any=null;
  finish: boolean=false;
  requiredAttributes = {
    SN: false,
    ISSUEDATE: false,
    EXPIRYDATE: false
  };
  dashbordRedirectionURL = environment.URL.redirection_Url
  coDigitalDone: any;
  submitDisableButton: boolean = false;
  isMobile: boolean = false;
  imgErrorMsg: any = null;
  initialUploadForm: FormGroup;
  
  constructor(
    private formBuilder: FormBuilder,
    private _app: AppService,
    private _data: DataService,
    public uiStateService: UiStateService,
  ) 
  {
    this.isMobile = this.uiStateService.getIsMobileResolution();
  }

  uploadData = this.formBuilder.group({
    frontImage: [null],
    accountId: [null, [
      Validators.pattern(/^[0-9 ]+$/)
    ]],
    docId: [null, [
      Validators.pattern(/^[0-9 ]+$/)
    ]],
    checkId: [null, [
      Validators.pattern(/^[0-9 ]+$/)
    ]],
    docName: [null, [
      Validators.required,
    ]],
    idNumber: [null],
    docIssueDate: [null],
    docExpiryDate: [null],
  });

  ngOnInit() {
    this.initialUploadForm = this.uploadData
    this.getStates();
    this.getAccountInfo();
    this.getAllChecks();
    this.getAvailableDocID();
    this.getAvailableDocAddrr();
    this._data.corporateDigiUpload.subscribe(res=>{
      this.coDigitalDone=res;
    });
  }

  get formData(): { [key: string]: AbstractControl } {
    return this.uploadData.controls;
  }

  onDateInputKeydown(event: KeyboardEvent) {
    event.preventDefault(); // Prevent any key input
  }

  getAllChecks(){
    this._app.getAllCheckforCO().subscribe((data) => {
      this.allIdvCheckData = data;
      
      // this.allIdvCheckData.forEach(element => {
      //   if(element.docType=='IDENTITY'){
      //     element.identityUpload=false
      //   }else if(element.docType=='ADDRESS'){
      //     element.addressUpload=false;
      //   }
      // });
    });
  }

  getAccountInfo(){
    this._app.getAllInfoforCO().subscribe((response) => {
      this.accountID = response.tpe.account.accountId
      this.getDocList();
    })
  }

  getStates(){
    this._app.getStates(localStorage.getItem('countryCode')).subscribe((response) => {
      this.states = response.data;
    });
  }
  
  getAvailableDocID() {
    this._app.getAvailableDoc('IDENTITY').subscribe((data) => {
        this.docNameforID = data;
    });
  }

  getAvailableDocAddrr() {
    this._app.getAvailableDoc('ADDRESS').subscribe((data) => {
        this.docNameforAddrr = data;
    });
  }

  checkNameID(event) {
    this.uploadData.controls.docId.setValue(event.kysDocumentID)
    this.uploadData.controls.idNumber.setValue(null);
    this.uploadData.controls.docExpiryDate.setValue(null);
    const attr = ['SN', 'EXPIRYDATE', 'ISSUEDATE'];
    for(let atr of attr){
      this.requiredAttributes[atr] = false;
    }
    if(event.requiredAttributes && event.requiredAttributes.includes('|')){
      const splitArray = event.requiredAttributes.split('|');
      for(let item of splitArray){
        if(item != ''){
          this.requiredAttributes[item] = true;
        }
        // console.log(item,"required")
      }
      
      for(const [key, value] of Object.entries(this.requiredAttributes)){
        if (key === 'EXPIRYDATE') {
            if(value){
              this.uploadData.controls['docExpiryDate'].setValidators([Validators.required]);
            } else {
              this.uploadData.controls['docExpiryDate'].clearValidators();
            }
            this.uploadData.controls['docExpiryDate'].updateValueAndValidity();
          }
        if (key === 'SN') {
          if(value){
            this.uploadData.controls['idNumber'].setValidators([Validators.required,Validators.pattern(/^[a-zA-Z0-9 ]+$/)]);
          } else {
            this.uploadData.controls['idNumber'].clearValidators();
          }
          this.uploadData.controls['idNumber'].updateValueAndValidity();
        }
        this.uploadData.controls['idNumber'].updateValueAndValidity();
        if (key === 'ISSUEDATE') {
          if(value){
            this.uploadData.controls['docIssueDate'].setValidators([Validators.required]);
          } else {
            this.uploadData.controls['docIssueDate'].clearValidators();
          }
          this.uploadData.controls['docIssueDate'].updateValueAndValidity();
        }
      }
    }
    // this.requiredAttributes
  }

  gotoBack() {
    this.openModal=null;
  }

  formReset(){
    this.uploadData.reset(this.initialUploadForm);
    this.imgErrorMsg = null;
    Object.keys(this.uploadData.controls).forEach(key => {
      this.uploadData.get(key).markAsUntouched();
    });
    const attr = ['SN', 'EXPIRYDATE', 'ISSUEDATE'];
    for(let atr of attr){
      this.requiredAttributes[atr] = false;
    }
  }

  storeFile(e) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.uploadedFile.push(e.target.files[i]);
    }
    if(this.uploadedFile[0]){
      this.imgErrorMsg = null;
    }else{
      this.imgErrorMsg = 'Please upload a file'
    }
  }

  removeFile(){
    this.uploadedFile=new Array();
    if(this.uploadedFile[0]){
      this.imgErrorMsg = null;
    }
  }

  onSubmit(){
    if(this.uploadData.valid && this.uploadedFile[0]){
      this.uploadIdentity();
      this.gotoBack();this.checkId=null;
    }else{
      Object.keys(this.uploadData.controls).forEach(key => {
        this.uploadData.get(key).markAsTouched();
      });
      if(!this.uploadedFile[0]){
        this.imgErrorMsg = "Please upload a file"
      }else{
        this.imgErrorMsg = null;
      }
    }
  }

  uploadIdentity() {
    const idDocIssDInd = new DatePipe('en-US').transform(this.uploadData.controls.docIssueDate.value, 'dd/MM/yyyy');
    const idDocExpDInd = new DatePipe('en-US').transform(this.uploadData.controls.docExpiryDate.value, 'dd/MM/yyyy');
    const formData = new FormData();
    formData.append('frontImage',this.uploadedFile[0]);
    formData.append('accountId', this.accountID);
    formData.append('docId', this.uploadData.controls.docId.value);
    formData.append('checkId', this.checkId);
    formData.append('docName', this.uploadData.controls.docName.value);
    
    if (this.uploadData.controls.idNumber.value !== null) {
      formData.append('idNumber', this.uploadData.controls.idNumber.value);
    }
    if (idDocIssDInd !== null) {
      formData.append('docIssueDate', idDocIssDInd);
    }
    if (idDocExpDInd !== null) {
      formData.append('docExpiryDate', idDocExpDInd);
    }
    this.callApiFun(formData)
  } 

  callApiFun(formData){
    this._data.changeLoaderVisibility(true);
    this._app.getDocumentUploadforCO(formData).subscribe((response) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow('Documents successfully uploaded', `success`);
      this.idDocUploadedforIDData = response['data'];
      this.uploadedFile=[]
      // this.allIdvCheckData.forEach(element => {
      //   if(element.checkId===this.checkId){
      //     element.identityUpload=true
      //   }
      // });
      this.formReset();
      this.getAllChecks();
      this.getDocList();

    });
  }

  getDocList(){
    this._app.getDocList(this.accountID).subscribe((response) => {
      this.docList = response;
      this._data.uboCorpDocListVal(this.docList);
      this.docList=this.docList.filter(e=>(e.docType==='IDENTITY' || e.docType==='ADDRESS' ))
      if(this.docList.some((e) => e.status == 'CREATED')){
        this.submitDisableButton = true;
      }
      else{
        this.submitDisableButton = false;
      }
    });
  }

  getName(){}

  changeState(event){
    this.stateVal=event.stateCode
  }
  
  checkNameAddrr(event) {
    this.docNameAddrr = event.docName;
    this.docIdAddrr = event.kysDocumentID;
  } 

  uploadAddress() {
    const formData = new FormData();
    formData.append('frontImage', this.uploadedFile);
    formData.append('accountId', this.accountID);
    formData.append('docId', this.docIdAddrr);
    formData.append('checkId', this.checkId);
    formData.append('docName', this.docNameAddrr);
    formData.append('stateCode', this.stateVal);
    this._data.changeLoaderVisibility(true);
    this._app.getDocumentUploadforCO(formData).subscribe((response) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow('Documents successfully uploaded', `success`);
      this.idDocUploadedforIDData = response['data'];
      this.uploadedFile=[]
      this.stateVal=null;
      this.getAllChecks();
      this.getDocList();
    });
  }

  getFinalSubmitforCO() {
    let obj;
    
    if (!this.isSelfie) {
      // this._data.changeLoaderVisibility(true);
      // this._app.getFinalSubmitforCO(obj).pipe(finalize(() => this._data.changeLoaderVisibility(false)))
      //   .subscribe((response) => {
      //     this._data.toastrShow(`All details submitted Successfully`, 'success')
      //     const payload = {
      //       "message": 'Officer Details Submitted Successfully',
      //       "page": "Officer Submit",
      //       "geoLocation": null
      //     }
      //     this._app.feasAddActivityLog(localStorage.getItem("officerKysNo"), payload).subscribe();
      //     this.finish=true;
      //   },
      //     (err: HttpErrorResponse) => {
      //       const payload = {
      //         "message": err,
      //         "page": "Officer Submit",
      //         "geoLocation": null
      //       }
      //       this._app.feasAddActivityLog(localStorage.getItem("officerKysNo"), payload).subscribe();
      //     }
      //   );
    }
    else {
      // this._data.changeLoaderVisibility(true);
      // setTimeout(() => {
      //   this._data.changeLoaderVisibility(false);
      //   this._data.toastrShow('Documents successfully saved', `success`);
      // }, 200);
    }
    this._data.toastrShow('Documents successfully saved', `success`);
    if(this.coDigitalDone){
      this.coDigitalDone = { ...this.coDigitalDone, kysTpeCheckStatus: 'COMPLETED' }
    }
    let status = this.coDigitalDone
    this._app.sendStatus(status).subscribe(() => {
      this._app.getStatus().subscribe(res => {
        this._data.checksStatus(res['data']);
        this.output.emit('coDigitalDone');
      })
    });
    this.getDocList();
  }

}
