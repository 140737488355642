import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kys-officers',
  templateUrl: './kys-officers.component.html',
  styleUrls: ['./kys-officers.component.scss']
})
export class KysOfficersComponent implements OnInit {

  uboStep:number = 1;
  constructor() { }

  ngOnInit() {
  }
  openModal(){
    const buttonModal = document.getElementById("openModalButton")
    console.log('buttonModal', buttonModal)
    buttonModal.click()
  }
  addNewUbo(){
    if(this.uboStep == 1){
      this.uboStep = 2;
    }else if(this.uboStep == 2){
      this.uboStep = 1;
    }
  }
  uboDetails(){
    if(this.uboStep == 1){
      this.uboStep = 3;
    }else if(this.uboStep == 3){
      this.uboStep = 1;
    }
  }

}
