import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-quistionair-page',
  templateUrl: './quistionair-page.component.html',
  styleUrls: ['./quistionair-page.component.scss']
})
export class QuistionairPageComponent implements OnChanges {
  @Output() output = new EventEmitter<any>();
  
  isCharCountUp: boolean = false;
  files: any = [];
  questionnaireList: any = [];
  @Input() checks:any;
  @Input() pageScroll:any;
  firstTimePopup: boolean = true;
  disabledClearButton:  boolean = false;
  questionnaireDone: any;
  constructor(private dataService: DataService, private appService: AppService) { }
  
  ngOnInit() {
    this.getAllCheck();
    this.dataService.corporateQuestionnaireInfo.subscribe(res => {
      this.questionnaireDone = res;
    })
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.pageScroll && changes.pageScroll.currentValue){
      window.scrollTo(0, 0);
    }
  }

  getAllCheck() {
    //this.dataService.changeLoaderVisibility(true);
    this.appService.getAllQuestionnaireCheck().pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      this.questionnaireList = res || [];
      console.log("response questionnaire: ",this.questionnaireList);
      this.questionnaireList.forEach(element => {
        if(element.inputData){
          element.questionnaire["answersLength"] = element.inputData.length
        }else{
          element.questionnaire["answersLength"] =0
        }
        element.questionnaire["answers"] = element.inputData
      });
      this.dataService.questionaryDataVal(this.questionnaireList);
    });
  }

  textQuestions(answers, questionCheckID, i) {
    this.questionnaireList[i].questionnaire.answersLength = answers.length;
    this.getCleatButtonDisabled();
  }

  cancel() {
    this.questionnaireList.filter(e => e.questionnaire.questionType != 'DOCUMENT').forEach(element => {
      element.inputData = '';
      element.questionnaire.answersLength = 0;
    });
    this.getCleatButtonDisabled();
  }

  getCleatButtonDisabled(){
    if(this.questionnaireList.some((e) => e.inputData != null && e.inputData != '')){
      this.disabledClearButton = true;
    }
    else{
      this.disabledClearButton = false;
    }
  }

  getFiles(questionId,event) {
    this.questionnaireList.filter(e => e.questionCheckID===questionId).forEach(element => {
      element.file=event.target.files[0];
    });
    const formData = new FormData;
    formData.append('multipartFile', event.target.files[0])
    this.dataService.changeLoaderVisibility(true);
    this.appService.uploadQuestionnaireDocs(questionId,formData).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
      this.dataService.toastrShow(`Document uploaded successfully`, 'success');
      this.getAllCheck()
    });
  }


  submitAnswers() {
    this.questionnaireList.filter(e => e.questionnaire.questionType != 'DOCUMENT').forEach(element => {
      const payload = {
        "inputData": element.inputData
      }
      this.dataService.changeLoaderVisibility(true);
      this.appService.submitAnswers(element.questionCheckID, payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe(() => {
        if(this.firstTimePopup) this.dataService.toastrShow(`Answer submitted successfully`, 'success');
        this.firstTimePopup = false;
        this.getCleatButtonDisabled();
        if(this.questionnaireDone){
          this.questionnaireDone = { ...this.questionnaireDone, kysTpeCheckStatus: 'COMPLETED' }
        }
        let status = this.questionnaireDone
        this.appService.sendStatus(status).subscribe(() => {
          this.appService.getStatus().subscribe(res => {
            this.dataService.checksStatus(res['data']);
            this.output.emit('questionnaireDone');
          })
        })
        element.inputData = '';
        element.questionnaire.answersLength = 0;
        this.getAllCheck()
      });
    });
  }


  checkedAnswer(qId,option){
    let index = this.questionnaireList.map(item=>item.questionnaire.kysQesID).indexOf(qId);
    let checks=this.questionnaireList[index].questionnaire.outPutChoice.split(';')
    if(checks.includes(option)){
      if(this.questionnaireList[index].inputData){
        this.questionnaireList[index].inputData += `${option};`
      }
      else{
        this.questionnaireList[index].inputData = `${option};`
      }
    }
    this.getCleatButtonDisabled();
  }
  getFinalSubmit() {
    let abc;
    this.dataService.changeLoaderVisibility(true);
    this.appService.getFinalSubmit(abc).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((response) => {
      this.dataService.toastrShow(`All details submitted Successfully`, 'success');
      
      const payload = {
        "message": 'Company details submitted Successfully',
        "page": "Company Final Submit",
        "geoLocation": null
      }
      this.dataService.finishVal(true)
      this.appService.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Company Final Submit",
          "geoLocation": null
        }
        this.appService.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    );
  }

  getFileName(str){
    return str[0].split('/')[str[0].split('/').length-1] 
  }
}
