import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-additional-fields',
  templateUrl: './additional-fields.component.html',
  styleUrls: ['./additional-fields.component.scss']
})
export class AdditionalFieldsComponent implements OnChanges {
  @Output() output = new EventEmitter<any>();
  @Input() pageScroll:any;

  /////-Additional fields vars---------->>>>>
  entityTypes = [
    { name: "Private Limited Company", value: "PrivateLimitedCompany" },
    { name: "Public Limited Company", value: "PublicLimitedCompany" },
    { name: "Sole Proprietorship", value: "SoleProprietorship" },
    { name: "Other", value: "Other" }
  ]
  accountPurposeTypes = [
    { name: "Investing/Dealing on Behalf of Clients", value: "InvestingOnBehalfOfClients" },
    { name: "Investing Entity's Capital", value: "InvestingEntityCapital" },
    { name: "Hedging", value: "Hedging" },
    { name: "Trading for Speculative Purposes", value: "TradingForSpeculativePurposes" },
    { name: "Other", value: "Other" }
  ]
  currencyTypes = [{ name: "USD", value: "USD" },
  { name: "GBP", value: "GBP" },
  { name: "EUR", value: "EUR" },
  { name: "AUD", value: "AUD" },
  { name: "Other", value: "Other" }]

  plannedInvestmentTypes = [
    { name: "Stocks", value: "Stocks" },
    { name: "Cryptocurrency", value: "Cryptocurrency" },
    { name: "Commodities", value: "Commodities" },
    { name: "Indices", value: "Indices" },
    { name: "FX", value: "FX" },
    { name: "CFDs", value: "CFDs" }
  ]
  ////---------------------------------<<<<<<<<<

  additionalDone: any;

  additionalFieldForm: FormGroup = this.form.group({
    edTypeOfEntity: [null],
    edLegalEntityIdentifier: [null],
    edRegisteredAddress: [null],
    edRegisteredAddressTown: [null],
    edRegisteredAddressPostcode: [null],
    edRegisteredAddressCountry: [null],
    edBusinessAddress: [null],
    edBusinessAddressTown: [null],
    edBusinessAddressPostcode: [null],
    edBusinessAddressCountry: [null],
    edCorrespondenceAddress: [null],
    edCorrespondenceAddressTown: [null],
    edCorrespondenceAddressPostcode: [null],
    edCorrespondenceAddressCountry: [null],
    edNatureOfBusiness: [null],
    edBusinessActivitiesInvolved: [null],
    edCountriesOfOperations: [null],
    edBusinessWebsite: [null],
    edGroupStructure: [null],
    taiTypeOfEntity: [null],
    taiThirdPartyFunds: [null],
    taiMinimumDepositRequirement: [false],
    taiImmediateFund: [null],
    taiInitialDeposit: [null],
    taiInvestIn12Months: [null],
    taiCurrencyOfTrading: [null],
    taiPlannedInvestment: [null],
    fiCompanyShareCapital: [null],
    fiTurnover: [null],
    fiTotalNetAssets: [null],
    fiLastAccountDate: [null],
    fiCurrency: [null],
    biAccountCurrency: [null],
    biBankName: [null],
    biAccountHolderName: [null],
    biAccountNumber: [null],
    biSwiftAbaSortCode: [null],
  });

  constructor( private _data: DataService, private _app: AppService, private form: FormBuilder) { }

  ngOnInit() {
    this._data.corporateAccountInfo.subscribe(res => {
      this.additionalDone = res
    });
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.pageScroll && changes.pageScroll.currentValue){
      window.scrollTo(0, 0);
    }
  }
  
  getData(){
    this._data.changeLoaderVisibility(true);
    this._app.getAddInfoData().pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      if(res){
        this.patchValue(res);
      }
    });
  }

  patchValue(response){
    Object.keys(response).forEach(key => {
      if(key == 'fiLastAccountDate'){
        this.additionalFieldForm.controls.fiLastAccountDate.setValue(formatDate(response[key], 'yyyy-MM-dd', 'en-US'));
      }
      else{
        if (this.additionalFieldForm.get(key)) {
          this.additionalFieldForm.get(key).setValue(response[key]);
        }
      }
    });
  }

  saveAdditionalFields() {
    const payload = {};
    Object.keys(this.additionalFieldForm.controls).forEach(controlName => {
      payload[controlName] = this.additionalFieldForm.get(controlName).value;
    });
    this._data.changeLoaderVisibility(true);
    this._app.updateAddInfoData(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      this._data.toastrShow('Your Details have been saved successfully', `success`);
      if(this.additionalDone){
        this.additionalDone = { ...this.additionalDone, kysTpeCheckStatus: 'COMPLETED' }
      }
      let status = this.additionalDone
      this._app.sendStatus(status).subscribe(() => {
        this._app.getStatus().subscribe(res => {
          this._data.checksStatus(res['data']);
          this.output.emit('additionalDone');
        })
      })
    });
  }
}
