import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { AppService } from "../../services/app.service";
import { DataService } from "../../services/data.service";
import { KycService } from "../../services/kyc.service";
import { SelfieService } from "../../services/selfie.service";
import { Check } from "../../models/check";
import { EndUserDocInfo } from "../../models/end-user-doc-info";
import { forkJoin } from "rxjs";
import { FeasModalComponent } from "../../shared/feas-modal/feas-modal.component";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { UiStateService } from "../../services/ui-state.service";
import { Ng2DeviceService } from "ng2-device-detector";



import * as RecordRTC from "recordrtc";
import { TabHeadingDirective } from "ngx-bootstrap/tabs";
import { finalize } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-selfie-check",
  templateUrl: "./selfie-check.component.html",
  styleUrls: ["./selfie-check.component.scss"],
})
export class SelfieCheckComponent implements OnChanges {
  @Input() type: "retail" | "controlOfficer";
  @Input() checkObject: any;
  @Input() tabStatus;
  @Output() output = new EventEmitter<any>();
  
  selfieObj: Check;
  livenessObj: Check;
  isUpdate: boolean = false;
  endUserDocInfo: EndUserDocInfo;

  currentStepNo: any;
  bsModalRef: BsModalRef;

  //Mobile Vars
  isMobile: boolean;
  step: number = 1;

  //kyc vars
  isSelfieDone: boolean;
  deviceInfo: any;
  geolocation: string;
  deviceName: string;
  isDone: boolean = false;
  timeInterval = 4000;
  //video variables & objects
  facingMode = "user";
  constraints = {
    audio: false,
    video: {
      facingMode: this.facingMode
    }
  }
  showVideos: any = {
    showLiveWebCam: false,
    showRecordedWebCam: false
  };

  shouldDisableRecordBtn: boolean = false;

  videoRecorder: any;
  recordedVideo: any;
  selfie: any;
  waitFor: number = 3;

  selfieConfigData: any;
  shouldRetry: boolean = false;
  counter: any = 1;
  isVidForcedOff: boolean = false;
  accountID: any;
  coFaceDone: any;
  photoCaptured: boolean;
  videoCaptured: boolean;
  //video ends

  constructor(
    public _app: AppService,
    public _data: DataService,
    public _kyc: KycService,
    public _selfie: SelfieService,
    private modalService: BsModalService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public uiStateService: UiStateService,
    private deviceService: Ng2DeviceService
  ) { this.deviceInformation(); }

  ngOnChanges(changes: SimpleChanges){
    if(changes.checkObject && changes.checkObject.currentValue){
      this.checkObject = changes.checkObject.currentValue
      this.selfieObj = this.checkObject ? this.checkObject['SELFIE'] ? this.checkObject['SELFIE'][0] : null :null;
      this.livenessObj = this.checkObject ? this.checkObject['LIVELINESS'] ? this.checkObject['LIVELINESS'][0]:null:null;
    }
    if(changes.tabStatus && changes.checkObject && changes.checkObject.currentValue){
      this.tabStatus = changes.tabStatus.currentValue
    }
  }

  ngOnInit() {
    this._app.getAllInfoforCO().subscribe((response) => {
      this.accountID = response.tpe.account.accountId
      if (response.client.clientID == '224') {
        this.timeInterval = 6000;
      }
    });
    this._data.corporateFaceCheck.subscribe(res=>{
      this.coFaceDone=res;
    });
    this.isMobile = this.uiStateService.getIsMobileResolution();
    this.currentStepNo = 1;//this._kyc.getStepNo("selfie-check");
    let checks = []; //<Check[]>this._kyc.getData("checks");
    this.selfieObj = this.checkObject['SELFIE'][0];
    this.livenessObj = this.checkObject['LIVELINESS'][0];
    let client = localStorage.getItem('clientID');
    if (client == '224') {
      this.timeInterval = 6000;
    }
   
    

    // if(this.type=="controlOfficer"){
    // this._app.getVideoChecks().subscribe(result=>{
    //   checks=result["idvChecks"][0];
    //   console.log("checks",checks);

    //   console.log(checks);
    //   this.selfieObj = checks["SELFIE"][0];

    //   this.livenessObj = checks["LIVELINESS"][0];

    //   console.log("selfie obj",this.selfieObj);
    //   console.log("live obj",this.livenessObj);
    //   console.log(this.currentStepNo);
    //   // this.getFaceDetails();
    //   console.log("ISMOBILE: ", this.isMobile);
    // })
    // }
    // else if(this.type=="retail"){
    // this._app.getAccountInfoForRetail().subscribe((response) =>{
    //   this.accountID = response.accountId
    //   console.log("Account ID: ", this.accountID);
    //   this._app.getAllRetailCheck(this.accountID).subscribe(res=>{
    //     checks=res["primary"]["allCheck"]["idvChecks"][0];
    //     this.selfieObj = checks["SELFIE"][0];
    //     this.livenessObj = checks["LIVELINESS"][0]; 
    //   })
    // });
    // }

  }

  ngOnDestroy() {
    console.log("destroyed selfie");
    if (this._selfie.videoStream) {
      this._selfie.stopVideo();
      this._selfie.stopAudio();
    }
    if (this.bsModalRef) {
      this.bsModalRef.content.onClose.subscribe(result => {
        console.log(result);
        // var video = <HTMLMediaElement>document.getElementById('videoo');
        if (result && Object.keys(result).length) {
          console.log(result);
        }
      });
      this.bsModalRef.hide();
    }
  }

  getFaceDetails() {
    // console.log("selfie id :"+ this.selfieObj["endUserCheckID"]+" live id; "+this.livenessObj["endUserCheckID"])
    var livenessDetails$ = this._app.getLivenessDetails(
      this.livenessObj["endUserCheckID"]
    );
    var selfieDetails$ = this._app.getSelfieDetails(
      this.selfieObj["endUserCheckID"]
    );
    forkJoin([livenessDetails$, selfieDetails$]).subscribe(
      response => {
        console.log(response);
        if (response && response[0] && response[0].size) {
          this.isUpdate = true;
          const blob = new Blob([response[0]], { type: "video/mp4" });
          var file = new File([blob], "hero.mp4", { type: "video/mp4" });
          setTimeout(() => {
            this.invokeSaveAsDialog(blob);
          }, 0);
          this.isSelfieDone = true;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  invokeSaveAsDialog(blob) {
    // this.videoBlob = blob ;
    console.log(blob);
    // this.shouldShowFinalVideo = true ;
    // var videoSection = document.querySelector("#userVideoSection");
    // (<HTMLElement>videoSection).style.display = 'block' ;
    var video = document.querySelector("video#userVideo");
    if (video) {
      video["src"] = window.URL.createObjectURL(blob);
    }
  }

  openCameraModal() {
    // // console.log({ selfie: this.selfieObj, liveness: this.livenessObj });
    // const initialState = {
    //   title: `Record Video`,
    //   type: "record-video",
    //   selfieConfigData: { selfie: this.selfieObj, liveness: this.livenessObj },

    // };
    // this.bsModalRef = this.modalService.show(FeasModalComponent, {
    //   animated: true,
    //   keyboard: false,
    //   backdrop: true,
    //   ignoreBackdropClick: true,
    //   initialState,
    //   class: "modal-lg",
    // });
    // this.bsModalRef.content.closeBtnName = "Close";
    // console.log(this.bsModalRef.content.onClose);
    // this.bsModalRef.content.onClose.subscribe(result => {
    //   console.log(result);

    //   //   this.finishKyc();
    //   if (result && Object.keys(result).length) {
    //     console.log(result);
    //     this.isSelfieDone = true;
    //     this._data.toastrShow(result.message, "info");
    //     this.finishKyc();
    //     // this.gotoStep("isFinalStep");
    //   }
    // });
    this.selfieConfigData = { selfie: this.selfieObj, liveness: this.livenessObj }
    this.checkForPermission();
  }

  shouldShowButton(type) {
    let kycState = <Check[]>this._kyc.getData("kycState");
    let idStateObj = kycState.filter(
      obj => obj["standardSlugGroup"] == "selfie-check"
    )[0];
    // console.log(kycState);
    // console.log(idStateObj);
    return idStateObj[type];
  }

  async logActivity() {
    let name = await this._data.getCookieObjectByName("individualUserInfo");

    let logData = {
      entity: "a",
      value: "b",
      message: `Uploaded Selfie & Video`,
      page: `Selfie Check`,
      os: this.deviceInfo.os,
      browser: this.deviceInfo.browser,
      device: this.deviceName,
      source: "FEAS",
      geoLocation: this.geolocation
    };

    if(name && name["userName"]) this._app.saveUserActivityLogNew(logData, name["userName"]).subscribe(
      res => {
        console.log(res);
      },
      err => {
        console.log(err);
      }
    );
  }
  deviceInformation() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (this.deviceService.device == "unknown") {
      if (isDesktopDevice) {
        this.deviceName = 'Desktop';
      }
      if (isMobile) {
        this.deviceName = 'Mobile';
      }
      if (isTablet) {
        this.deviceName = 'Tablet';
      }
    } else this.deviceName = this.deviceInfo.device;
    console.log(this.deviceInfo);
    this.getLocation();
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this._app.getLocation(position.coords.latitude, position.coords.longitude).subscribe(res => {
          if (res.city == "") {
            this.geolocation = `${res.locality}, ${res.countryName}`;
          } else this.geolocation = `${res.city}, ${res.countryName}`;
        });
        // let geocoder = new google.maps.Geocoder();
      });
    } else {
      this.geolocation = null;
    }
  }
  finishKyc() {
    console.log("Over");
    // this.isDone=true;
    // this.router.navigate(["/kyc/preview"]);
    this.logActivity();
    if (this.type == "controlOfficer") {
      if(this.tabStatus && (this.tabStatus.basicInfo && (this.tabStatus.basicInfo.kysTpeCheckStatus == 'PASSED' || this.tabStatus.basicInfo.kysTpeCheckStatus == 'COMPLETED')
      && (!this.tabStatus.digitalUpload || (this.tabStatus.digitalUpload && (this.tabStatus.digitalUpload.kysTpeCheckStatus == 'PASSED' || this.tabStatus.digitalUpload.kysTpeCheckStatus == 'COMPLETED'))))){
        let obj;
      this._data.changeLoaderVisibility(true);
      this._app.getOverAllSubmitforCO(this.accountID, obj).pipe(finalize(() => this._data.changeLoaderVisibility(false)))
        .subscribe((response) => {
          this._data.toastrShow(`All details submitted Successfully`, 'success');
          this.output.emit('submitted');
          // let initialState =
          // {
          //   title: `New Entity Onboarding is done`,
          //   type: "face-done",
          // }

          // this.bsModalRef = this.modalService.show(FeasModalComponent, {
          //   animated: true,
          //   keyboard: false,
          //   backdrop: true,
          //   ignoreBackdropClick: true,
          //   initialState,
          //   class: "modal-md",
          // });
          const payload = {
            "message": 'Officer Details Submitted Successfully',
            "page": "Officer Submit",
            "geoLocation": null
          }
          this._app.feasAddActivityLog(localStorage.getItem("officerKysNo"), payload).subscribe();
        },
          (err: HttpErrorResponse) => {
            const payload = {
              "message": err,
              "page": "Officer Submit",
              "geoLocation": null
            }
            this._app.feasAddActivityLog(localStorage.getItem("officerKysNo"), payload).subscribe()
          }
        );
      }
      else{
        this._data.toastrShow(`Please complete all checks before submit`, 'warning');
      }
    }
    else if (this.type == "retail") {
      let obj;
      this._data.changeLoaderVisibility(true);
      this._app.getRetailSubmit(obj).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((response) => {
        this._data.toastrShow(`Submitted Successfully`, 'success');
        let initialState =
        {
          title: `New Entity Onboarding is done`,
          type: "face-done",
        }
        this.bsModalRef = this.modalService.show(FeasModalComponent, {
          animated: true,
          keyboard: false,
          backdrop: true,
          ignoreBackdropClick: true,
          initialState,
          class: "modal-md",
        })
        const payload = {
          "message": 'Primary Holder Details Submitted Successfully',
          "page": "Primary Holder Submit",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("primaryKysNo"), payload).subscribe();
      },
        (err: HttpErrorResponse) => {
          const payload = {
            "message": err,
            "page": "Primary Holder Submit",
            "geoLocation": null
          }
          this._app.feasAddActivityLog(localStorage.getItem("primaryKysNo"), payload).subscribe()
        }
      );
    }
  }

  gotoStep(stepNo) {
    let state = this._kyc.getCurrentState(stepNo);
    let navRoute = this._kyc.getData("routesMap")[state["standardSlugGroup"]];
    console.log(state);
    this.router.navigate([navRoute]);
  }
  stepForward(currentStep) {
    // this.goingForward = true;
    this.step = currentStep + 1;
    console.log(this.step);
  }
  stepBack(currentStep) {
    // this.goingForward = false;
    this.step = currentStep - 1;
    console.log(this.step);
  }


  //camera functions

  checkForPermission() {
    navigator.mediaDevices.getUserMedia(this.constraints)
      .then(
        successCallback => {
          this.stepForward(this.step);
          console.log("runn callback", successCallback);
          this.showVideos.showLiveWebCam = true;
          this.showVideos.showRecordedWebCam = false;
          setTimeout(() => {
            var video = <HTMLMediaElement>(
              document.getElementById("liveWebcamVideo")
            );
            video.srcObject = successCallback;
            video.play();
            this._selfie.videoStream = successCallback;
            // if (this.type !== "capture-doc") {
            //   this.setDirectionHelpers();
            this.waitText();
            // }
            this.startVideoRecording();
            this.shouldDisableRecordBtn = true;
          }, 0);
        },
        errorCallback => {
          let alertObj = {
            message: "Please Allow Camera",
            status: "danger",
            autoDismiss: true,
            timeToDismiss: 10000
          };
          console.log(alertObj);
          // this._data.displayUniversalAlert(alertObj);
          this._data.toastrShow("Please Allow Camera", "info");
        }
      );
  }

  startVideoRecording() {
    this.videoRecorder = RecordRTC(this._selfie.videoStream, {
      mimeType: "video/*;codecs=h264",
      frameInterval: 45,
      frameRate: 30
    });
    this.videoRecorder.startRecording();
  }

  waitText() {
    let count = this.waitFor + 1;
    var that = this;
    let interval = setInterval(() => {
      if (count > 1) {
        count--;
        that._selfie.helperText = `Recording starts in ${count} seconds`;
        console.log(count);
      } else {
        clearInterval(interval);
        that.setDirectionHelpers();
      }
    }, 1000);
  }

  setDirectionHelpers() {
    var that = this;

    var counter = 0;
    var timer = 0;
    var interval = setInterval(() => {
      // that._selfie.helperBackgroundImage = `https://images.obsassets.com/kychome/direction/${that._selfie.bgArr[counter]}.jpeg`;
      that._selfie.helperText = `${that._selfie.instructionArr[counter]}`;
      this._selfie.audioStream = new Audio(`${that._selfie.audioArr[counter]}`);
      this._selfie.audioStream.play();
      counter++;

      if (counter === 1) {
        console.log("reached completions 1");
        that.setSubsequentDirections();
        clearInterval(interval);
      }
    }, 0);
  }

  setSubsequentDirections() {
    var that = this;
    var timer = 0;
    that.counter = 1;
    var interval = setInterval(() => {
      if (!that.isVidForcedOff) {
        // that._selfie.helperBackgroundImage = `https://images.obsassets.com/kychome/direction/${that._selfie.bgArr[that.counter]
        //   }.jpeg`;
        that._selfie.helperText = `${that._selfie.instructionArr[that.counter]
          }`;
        this._selfie.audioStream = new Audio(
          `${that._selfie.audioArr[that.counter]}`
        );
        this._selfie.audioStream.play();
        that.counter++;
        console.log(":::::::::::::::::::::::::", that.counter);
        if (that.counter == 2) {
          that.triggerSnapshot();
        }
        if (that.counter == 5) {
          console.log("reached completions 2");
          that.stopVideoRecording();
          clearInterval(interval);
        }
      } else {
        clearInterval(interval);
      }
    }, this.timeInterval);
  }
  stopVideoRecording() {
    var that = this;
    this.showVideos.showLiveWebCam = false;
    this.showVideos.showRecordedWebCam = true;
    this.shouldDisableRecordBtn = false;
    that.videoRecorder.stopRecording(function () {
      let blob = that.videoRecorder.getBlob();
      that.invokeSaveAsDialogVideo(blob);
      console.log(blob);
    });
    that._selfie.videoStream.getTracks().forEach(function (track) {
      track.stop();
    });
    // that._selfie.videoStream.stop();
  }

  invokeSaveAsDialogVideo(blob) {
    // this.videoBlob = blob ;
    console.log(blob);
    // this.shouldShowFinalVideo = true ;
    // var videoSection = document.querySelector("#userVideoSection");
    // (<HTMLElement>videoSection).style.display = 'block' ;
    var video = document.getElementById("recordedWebcamVideo");
    if (video) {
      video["src"] = window.URL.createObjectURL(blob);
    }

    this.recordedVideo = blob;
    // var video = <HTMLMediaElement>document.getElementById('videoo');
    //     video.srcObject = successCallback;
    //     video.play();
  }

  triggerSnapshot() {
    var canvas = <HTMLCanvasElement>document.getElementById("snapCanvas");
    var context = canvas.getContext("2d");
    var video = <any>document.getElementById("liveWebcamVideo");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    // context.drawImage(video, 0, 0, 640, 480, 0, 0, 640, 480);

    // var data = canvas.toDataURL("image/png");
    var data = canvas.toDataURL("image/jpeg");
    this.selfie = data;

    // if (this.type === "capture-doc") {
    //   this.closeModal(this.selfie);
    // }
  }

  stopVideo() {
    this.counter = 1;
    this.isVidForcedOff = true;
    this._selfie.helperText = "";
    this._selfie.stopVideo();
    this._selfie.stopAudio();
  }

  dataURLtoFile(dataURI) {
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
  onSubmitVideo(event) {
    console.log("video file: ", event.srcElement.files)
    this.recordedVideo = event.srcElement.files.item(0);
    this.videoCaptured=true
  }

  onSubmitImage(event) {
    console.log("image file: ", event.srcElement.files)
    this.selfie = event.srcElement.files.item(0);
    this.photoCaptured = true
  }
  uploadVideoAndSelfienew() {
    if(this.selfieConfigData['selfie'] && this.selfieConfigData['selfie']['checkId'] && this.selfieConfigData['liveness'] && this.selfieConfigData['liveness']['checkId']){
      var formData1 = new FormData();
      formData1.append("video", this.selfie);
      formData1.append("docId", this.selfieConfigData['selfie'] && this.selfieConfigData['selfie']['docId'] ? this.selfieConfigData['selfie']['docId'] : "");
      formData1.append("checkId", this.selfieConfigData['selfie']['checkId'] ? this.selfieConfigData['selfie']['checkId'] : "");
      formData1.append("docName", 'selfie');
      formData1.append("address", this.selfieConfigData['selfie']['address'] ? this.selfieConfigData['selfie']['address'] : "");
      formData1.append("idNumber", this.selfieConfigData['selfie']['idNumber'] ? this.selfieConfigData['selfie']['idNumber'] : "");
      formData1.append("expiryYear", this.selfieConfigData['selfie']['expiryYear'] ? this.selfieConfigData['selfie']['expiryYear'] : "");
      formData1.append("issueYear", this.selfieConfigData['selfie']['issueYear'] ? this.selfieConfigData['selfie']['issueYear'] : "");
      formData1.append("stateCode", this.selfieConfigData['selfie']['stateCode'] ? this.selfieConfigData['selfie']['stateCode'] : "");
      this._data.changeLoaderVisibility(true);
      this._app.uploadVideoSelfieFile(formData1).subscribe(res => {
        this._data.changeLoaderVisibility(false);
        let alertObj = {
          message: res.message,
          status: "success",
          autoDismiss: true,
          timeToDismiss: 10000
        };
        this._data.toastrShow(res.message, "success");
        var formData2 = new FormData();
        formData2.append("video", this.recordedVideo);
        formData2.append("docId", this.selfieConfigData['liveness'] && this.selfieConfigData['liveness']['docId'] ? this.selfieConfigData['liveness']['docId'] : "");
        formData2.append("checkId", this.selfieConfigData['liveness']['checkId'] ? this.selfieConfigData['liveness']['checkId'] : "");
        formData2.append("docName", 'liveness');
        formData2.append("address", this.selfieConfigData['liveness']['address'] ? this.selfieConfigData['liveness']['address'] : "");
        formData2.append("idNumber", this.selfieConfigData['liveness']['idNumber'] ? this.selfieConfigData['liveness']['idNumber'] : "");
        formData2.append("expiryYear", this.selfieConfigData['liveness']['expiryYear'] ? this.selfieConfigData['liveness']['expiryYear'] : "");
        formData2.append("issueYear", this.selfieConfigData['liveness']['issueYear'] ? this.selfieConfigData['liveness']['issueYear'] : "");
        formData2.append("stateCode", this.selfieConfigData['liveness']['stateCode'] ? this.selfieConfigData['liveness']['stateCode'] : "");
        this._data.changeLoaderVisibility(true);
        this._app.uploadVideoSelfieFile(formData2).subscribe(res => {
          this._data.changeLoaderVisibility(false);
          if(this.coFaceDone){
            this.coFaceDone = { ...this.coFaceDone, kysTpeCheckStatus: 'COMPLETED' }
          }
          let status = this.coFaceDone
          this._app.sendStatus(status).subscribe(() => {
            this._app.getStatus().subscribe(res => {
              this._data.checksStatus(res['data'])
            })
          })
          this._data.toastrShow(res.message, "success");
          this.step++

        },
          err => {
            this._data.changeLoaderVisibility(false);
            this.shouldRetry = true;
          })
      },
        err => {
          this._data.changeLoaderVisibility(false);
          this.shouldRetry = true;
        })

      }

      else if(this.selfieConfigData['selfie'] && this.selfieConfigData['selfie']['checkId']){
        var formData1 = new FormData();
        formData1.append("video", this.selfie);
        formData1.append("docId", this.selfieConfigData['selfie'] && this.selfieConfigData['selfie']['docId'] ? this.selfieConfigData['selfie']['docId'] : "");
        formData1.append("checkId", this.selfieConfigData['selfie']['checkId'] ? this.selfieConfigData['selfie']['checkId'] : "");
        formData1.append("docName", 'selfie');
        formData1.append("address", this.selfieConfigData['selfie']['address'] ? this.selfieConfigData['selfie']['address'] : "");
        formData1.append("idNumber", this.selfieConfigData['selfie']['idNumber'] ? this.selfieConfigData['selfie']['idNumber'] : "");
        formData1.append("expiryYear", this.selfieConfigData['selfie']['expiryYear'] ? this.selfieConfigData['selfie']['expiryYear'] : "");
        formData1.append("issueYear", this.selfieConfigData['selfie']['issueYear'] ? this.selfieConfigData['selfie']['issueYear'] : "");
        formData1.append("stateCode", this.selfieConfigData['selfie']['stateCode'] ? this.selfieConfigData['selfie']['stateCode'] : "");
        this._data.changeLoaderVisibility(true);
        this._app.uploadVideoSelfieFile(formData1).subscribe(res => {
          this._data.changeLoaderVisibility(false);
          let alertObj = {
            message: res.message,
            status: "success",
            autoDismiss: true,
            timeToDismiss: 10000
          };
          this._data.toastrShow(res.message, "success");
          
            this.step++
        },
        err => {
          this._data.changeLoaderVisibility(false);
          this.shouldRetry = true;
        })
      }
  
      else if(this.selfieConfigData['liveness'] && this.selfieConfigData['liveness']['checkId']){
        var formData2 = new FormData();
        formData2.append("video", this.recordedVideo);
        formData2.append("docId", this.selfieConfigData['liveness'] && this.selfieConfigData['liveness']['docId'] ? this.selfieConfigData['liveness']['docId'] : "");
        formData2.append("checkId", this.selfieConfigData['liveness']['checkId'] ? this.selfieConfigData['liveness']['checkId'] : "");
        formData2.append("docName", 'liveness');
        formData2.append("address", this.selfieConfigData['liveness']['address'] ? this.selfieConfigData['liveness']['address'] : "");
        formData2.append("idNumber", this.selfieConfigData['liveness']['idNumber'] ? this.selfieConfigData['liveness']['idNumber'] : "");
        formData2.append("expiryYear", this.selfieConfigData['liveness']['expiryYear'] ? this.selfieConfigData['liveness']['expiryYear'] : "");
        formData2.append("issueYear", this.selfieConfigData['liveness']['issueYear'] ? this.selfieConfigData['liveness']['issueYear'] : "");
        formData2.append("stateCode", this.selfieConfigData['liveness']['stateCode'] ? this.selfieConfigData['liveness']['stateCode'] : "");
        this._data.changeLoaderVisibility(true);
        this._app.uploadVideoSelfieFile(formData2).subscribe(res => {
          this._data.changeLoaderVisibility(false);

          this._data.toastrShow(res.message, "success");
          this.step++

        },
          err => {
            this._data.changeLoaderVisibility(false);
            this.shouldRetry = true;
          })
      }

  }

  redoCheck(){
    this.step = 1;
    this.shouldRetry = false;
    this.videoRecorder=null;
  }

  uploadVideoAndSelfie() {
    //need to optimise this code

    if(this.selfieConfigData['selfie'] && this.selfieConfigData['selfie']['checkId'] && this.selfieConfigData['liveness'] && this.selfieConfigData['liveness']['checkId']){
      var formData1 = new FormData();
      var image = this.dataURLtoFile(this.selfie);
      console.log("form data variable :   " + formData1.toString());

      formData1.append("video", image);
      formData1.append("docId", this.selfieConfigData['selfie'] && this.selfieConfigData['selfie']['docId'] ? this.selfieConfigData['selfie']['docId'] : "");
      formData1.append("checkId", this.selfieConfigData['selfie']['checkId'] ? this.selfieConfigData['selfie']['checkId'] : "");
      formData1.append("docName", 'selfie');
      formData1.append("address", this.selfieConfigData['selfie']['address'] ? this.selfieConfigData['selfie']['address'] : "");
      formData1.append("idNumber", this.selfieConfigData['selfie']['idNumber'] ? this.selfieConfigData['selfie']['idNumber'] : "");
      formData1.append("expiryYear", this.selfieConfigData['selfie']['expiryYear'] ? this.selfieConfigData['selfie']['expiryYear'] : "");
      formData1.append("issueYear", this.selfieConfigData['selfie']['issueYear'] ? this.selfieConfigData['selfie']['issueYear'] : "");
      formData1.append("stateCode", this.selfieConfigData['selfie']['stateCode'] ? this.selfieConfigData['selfie']['stateCode'] : "");
      this._data.changeLoaderVisibility(true);
      this._app.uploadVideoSelfieFile(formData1).subscribe(res => {
        this._data.changeLoaderVisibility(false);

        this._data.toastrShow(res.message, "success");

        var formData2 = new FormData();
        formData2.append("video", this.recordedVideo);
        formData2.append("docId", this.selfieConfigData['liveness'] && this.selfieConfigData['liveness']['docId'] ? this.selfieConfigData['liveness']['docId'] : "");
        formData2.append("checkId", this.selfieConfigData['liveness']['checkId'] ? this.selfieConfigData['liveness']['checkId'] : "");
        formData2.append("docName", 'liveness');
        formData2.append("address", this.selfieConfigData['liveness']['address'] ? this.selfieConfigData['liveness']['address'] : "");
        formData2.append("idNumber", this.selfieConfigData['liveness']['idNumber'] ? this.selfieConfigData['liveness']['idNumber'] : "");
        formData2.append("expiryYear", this.selfieConfigData['liveness']['expiryYear'] ? this.selfieConfigData['liveness']['expiryYear'] : "");
        formData2.append("issueYear", this.selfieConfigData['liveness']['issueYear'] ? this.selfieConfigData['liveness']['issueYear'] : "");
        formData2.append("stateCode", this.selfieConfigData['liveness']['stateCode'] ? this.selfieConfigData['liveness']['stateCode'] : "");
        this._data.changeLoaderVisibility(true);
        this._app.uploadVideoSelfieFile(formData2).subscribe(res => {
          this._data.changeLoaderVisibility(false);
          if(this.coFaceDone){
            this.coFaceDone = { ...this.coFaceDone, kysTpeCheckStatus: 'COMPLETED' }
          }
          let status = this.coFaceDone
          this._app.sendStatus(status).subscribe(() => {
            this._app.getStatus().subscribe(res => {
              this._data.checksStatus(res['data'])
            })
          })
          this._data.toastrShow(res.message, "success");
          this.step++;
        },
          err => {
            this._data.changeLoaderVisibility(false);

            this.shouldRetry = true;
          })

      },
        err => {
          this._data.changeLoaderVisibility(false);
          this.shouldRetry = true;
        })

    }

    else if(this.selfieConfigData['selfie'] && this.selfieConfigData['selfie']['checkId']){
      var formData1 = new FormData();
      var image = this.dataURLtoFile(this.selfie);
      console.log("form data variable :   " + formData1.toString());

      formData1.append("video", image);
      formData1.append("docId", this.selfieConfigData['selfie'] && this.selfieConfigData['selfie']['docId'] ? this.selfieConfigData['selfie']['docId'] : "");
      formData1.append("checkId", this.selfieConfigData['selfie']['checkId'] ? this.selfieConfigData['selfie']['checkId'] : "");
      formData1.append("docName", 'selfie');
      formData1.append("address", this.selfieConfigData['selfie']['address'] ? this.selfieConfigData['selfie']['address'] : "");
      formData1.append("idNumber", this.selfieConfigData['selfie']['idNumber'] ? this.selfieConfigData['selfie']['idNumber'] : "");
      formData1.append("expiryYear", this.selfieConfigData['selfie']['expiryYear'] ? this.selfieConfigData['selfie']['expiryYear'] : "");
      formData1.append("issueYear", this.selfieConfigData['selfie']['issueYear'] ? this.selfieConfigData['selfie']['issueYear'] : "");
      formData1.append("stateCode", this.selfieConfigData['selfie']['stateCode'] ? this.selfieConfigData['selfie']['stateCode'] : "");
      this._data.changeLoaderVisibility(true);
      this._app.uploadVideoSelfieFile(formData1).subscribe(res => {
        this._data.changeLoaderVisibility(false);
        if(this.coFaceDone){
          this.coFaceDone = { ...this.coFaceDone, kysTpeCheckStatus: 'COMPLETED' }
        }
        let status = this.coFaceDone
        this._app.sendStatus(status).subscribe(() => {
          this._app.getStatus().subscribe(res => {
            this._data.checksStatus(res['data'])
          })
        })
        this._data.toastrShow(res.message, "success");
        this.step++;
      },
      err => {
        this._data.changeLoaderVisibility(false);
        this.shouldRetry = true;
      })
    }

    else if(this.selfieConfigData['liveness'] && this.selfieConfigData['liveness']['checkId']){
      var formData2 = new FormData();
        formData2.append("video", this.recordedVideo);
        formData2.append("docId", this.selfieConfigData['liveness'] && this.selfieConfigData['liveness']['docId'] ? this.selfieConfigData['liveness']['docId'] : "");
        formData2.append("checkId", this.selfieConfigData['liveness']['checkId'] ? this.selfieConfigData['liveness']['checkId'] : "");
        formData2.append("docName", 'liveness');
        formData2.append("address", this.selfieConfigData['liveness']['address'] ? this.selfieConfigData['liveness']['address'] : "");
        formData2.append("idNumber", this.selfieConfigData['liveness']['idNumber'] ? this.selfieConfigData['liveness']['idNumber'] : "");
        formData2.append("expiryYear", this.selfieConfigData['liveness']['expiryYear'] ? this.selfieConfigData['liveness']['expiryYear'] : "");
        formData2.append("issueYear", this.selfieConfigData['liveness']['issueYear'] ? this.selfieConfigData['liveness']['issueYear'] : "");
        formData2.append("stateCode", this.selfieConfigData['liveness']['stateCode'] ? this.selfieConfigData['liveness']['stateCode'] : "");
        this._data.changeLoaderVisibility(true);
        this._app.uploadVideoSelfieFile(formData2).subscribe(res => {
          this._data.changeLoaderVisibility(false);
          if(this.coFaceDone){
            this.coFaceDone = { ...this.coFaceDone, kysTpeCheckStatus: 'COMPLETED' }
          }
          let status = this.coFaceDone
          this._app.sendStatus(status).subscribe(() => {
            this._app.getStatus().subscribe(res => {
              this._data.checksStatus(res['data'])
            })
          })
          this._data.toastrShow(res.message, "success");
          this.step++;
        },
          err => {
            this._data.changeLoaderVisibility(false);

            this.shouldRetry = true;
          })
    }


  }
}
