import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { AppService } from '../../../services/app.service';
import { DatePipe, formatDate } from '@angular/common';
import { 
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
  FormArray,
  NgForm,
  
} from "@angular/forms";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { finalize, first } from 'rxjs/operators';
import { CustomValidators } from "ng2-validation";
import { HttpErrorResponse } from '@angular/common/http';
import { style } from '@angular/animations';
import { element } from 'protractor';

@Component({
  selector: 'app-kys-co-basicinfo',
  templateUrl: './kys-co-basicinfo.component.html',
  styleUrls: ['./kys-co-basicinfo.component.scss']
})
export class KysCoBasicinfoComponent implements OnInit {
  @Output() output = new EventEmitter<any>();

  accountID: any;
  countries: any;
  addMoreDetailsForm: FormGroup;
  controlOfficerType: any;
  officerId: any;
  controlOfficerEntityType: any;
  jurisdictions: any[] = [];
  coBasicDone: any;
  maturityStatus:any[] = [
    {key:'Single', value:'SINGLE'},
    {key:'Married', value:'MARRIED'},
    {key:'Widowed', value:'WIDOWED'},
    {key:'Divorced', value:'DIVORCED'},
    {key:'Separated', value:'SEPARATED'},
  ]
  currentStatus:any[] = [
    {key: 'Active', value: 'Active'},
    {key: 'Dormant', value: 'Dormant'},
    {key: 'Active in Progress', value: 'Active in Progress'},
    {key: 'Under liquidation', value: 'Under liquidation'},
    {key: 'Under process of Striking Off', value: 'Under process of Striking Off'},
    {key: 'Strike off', value: 'Strike off'},
    {key: 'Amalgamated', value: 'Amalgamated'},
    {key: 'Liquidated', value: 'Liquidated'},
    {key: 'Dissolved', value: 'Dissolved'},
    {key: 'Converted to LLP', value: 'Converted to LLP'},
    {key: 'Converted to LLP and dissolved', value: 'Converted to LLP and dissolved'}
  ]
  currentStatusInd:any[] = [
    {key: 'Active', value: 'Active'},
    {key: 'Inactive', value: 'Inactive'},
  ]

  constructor(
    private formBuilder: FormBuilder,
    private _data: DataService,
    private _app: AppService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.addMoreDetailsForm = this.formBuilder.group({
      firstName: [null],
      lastName: [null,[
        Validators.pattern(/^[a-zA-Z ]+$/)
      ]],
      dob: [null],
      maritalStatus: [null],
      phoneNo: [null,[
        Validators.pattern(/^[0-9]\d*(\.\d+)?$/)
      ]],
      address: [null],
      ssn: [null],
      taxID: [null],
      percentage: [null],
      status: [null],
      designation: [null],
      email: [null],
      citizenship: [null],
      sharePercentUBO: [null,[
        Validators.pattern(/^[0-9]\d*(\.\d+)?$/)
      ]],
      officerTypeUBO: [null],
      companyId: [null],
      companyType: [null],
      incorporationDate: [null],
      branchCount: [null],
      parentCompanyName: [null],
      parentCompanyID: [null],
      streetAddress: [null],
      region: [null],
      postalCode: [null],
      jurisdictionCode: [null],
    });

    this._data.corporateBasicInfo.subscribe(res=>{
      this.coBasicDone=res;
    });

    this._app.getAllCountry().subscribe((response) =>{
      this.countries = response
    });
  
    this.getInitialData();
    
  }

  getInitialData(){
    this._app.getAllInfoforCO().subscribe((response) =>{
      this._data.uboDetailsVal(response);
      this.accountID = response.tpe.account.accountId
      this.controlOfficerType = response.controlOfficerType;
      this.officerId = response.id;
      this.controlOfficerEntityType = response.controlOfficerEntityType;
      response.dob ? this.addMoreDetailsForm.controls.dob.setValue(formatDate(response.dob, 'yyyy-MM-dd', 'en-US')) : '';
      this.addMoreDetailsForm.patchValue({
        firstName: response.firstName,
        lastName: response.lastName,
        maritalStatus: response.maritalStatus,
        phoneNo: response.phoneNumber,
        address: response.residenceAddress,
        ssn: response.ssn,
        taxID: response.taxId,
        percentage: response.percentageOfOwnership,
        status: response.currentStatus,
        designation: response.designation,
        email: response.email,
        citizenship: response.citizenshipCountryCode,
        sharePercentUBO: response.sharePercentage,
        officerTypeUBO: response.controlOfficerEntityType,
        companyId: response.companyId,
        companyType: response.companyType,
        incorporationDate: response.incorporationDate,
        branchCount: response.branchCount,
        parentCompanyName: response.parentCompanyName,
        parentCompanyID: response.parentCompanyID,
        streetAddress: response.streetAddress,
        region: response.region,
        postalCode: response.postalCode,
        jurisdictionCode: response.jurisdictionCode
      })
      if(response.firstName){
        this.addMoreDetailsForm.controls['firstName'].disable();
      }
      if(response.email){
        this.addMoreDetailsForm.controls['email'].disable();
      }
      if(response.sharePercentage){
        this.addMoreDetailsForm.controls['sharePercentUBO'].disable();
      }
      if(response.controlOfficerEntityType){
        this.addMoreDetailsForm.controls['officerTypeUBO'].disable();
      }
      if(response.citizenshipCountryCode){
        this.addMoreDetailsForm.controls['citizenship'].disable();
      }

      if(response.tpe){
        localStorage.setItem("officerKysNo", response.tpe.kysNo)
      }
      this.getCountryCode('addMoreDetailsForm', true);
      console.log("hgfhjsg",response.tpe.country.countryCode);
      localStorage.setItem('countryCode', response.tpe.country.countryCode);
    })
  }

  onDateInputKeydown(event: KeyboardEvent) {
    event.preventDefault(); // Prevent any key input
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.addMoreDetailsForm.controls;
  }

  getCountryCode(forms, ngOnInit?){
    const countryCode = this[forms].controls['citizenship'].value
    this._app.getJurisdictionData(countryCode).subscribe((res) => {
      this.jurisdictions = res || [];
      if(res.length && !ngOnInit){
        this[forms].get('jurisdictionCode').setValue(res[0].code);
      }
    })
  }

  getNullValue(value){
    return value != '' ? value : null;
  }

  getUpdateInfoOfficer(){
    const payload = {
      firstName: this.getNullValue(this.addMoreDetailsForm.controls['firstName'].value),
      lastName: this.getNullValue(this.addMoreDetailsForm.value.lastName),
      maritalStatus: this.getNullValue(this.addMoreDetailsForm.value.maritalStatus),
      dob: this.getNullValue(this.addMoreDetailsForm.value.dob),
      phoneNumber: this.getNullValue(this.addMoreDetailsForm.value.phoneNo),
      email: this.getNullValue(this.addMoreDetailsForm.controls['email'].value),
      residenceAddress: this.getNullValue(this.addMoreDetailsForm.value.address),
      ssn: this.getNullValue(this.addMoreDetailsForm.value.ssn),
      taxId: this.getNullValue(this.addMoreDetailsForm.value.taxID),
      percentageOfOwnership: this.getNullValue(this.addMoreDetailsForm.value.percentage),
      currentStatus: this.getNullValue(this.addMoreDetailsForm.value.status),
      designation: this.getNullValue(this.addMoreDetailsForm.value.designation),
      controlOfficerType: this.controlOfficerType,
      citizenshipCountryCode: this.getNullValue(this.addMoreDetailsForm.controls['citizenship'].value),
      sharePercentage: this.getNullValue(this.addMoreDetailsForm.controls['sharePercentUBO'].value),
      controlOfficerEntityType: this.getNullValue(this.addMoreDetailsForm.controls['officerTypeUBO'].value),
      companyId: this.getNullValue(this.addMoreDetailsForm.value.companyId),
      companyType: this.getNullValue(this.addMoreDetailsForm.value.companyType),
      incorporationDate: this.getNullValue(this.addMoreDetailsForm.value.incorporationDate),
      branchCount: this.getNullValue(this.addMoreDetailsForm.value.branchCount),
      parentCompanyName: this.getNullValue(this.addMoreDetailsForm.value.parentCompanyName),
      parentCompanyID: this.getNullValue(this.addMoreDetailsForm.value.parentCompanyID),
      streetAddress: this.getNullValue(this.addMoreDetailsForm.value.streetAddress),
      region: this.getNullValue(this.addMoreDetailsForm.value.region),
      postalCode: this.getNullValue(this.addMoreDetailsForm.value.postalCode),
      jurisdictionCode: this.getNullValue(this.addMoreDetailsForm.value.jurisdictionCode),
      id: this.officerId
  };
    this._data.changeLoaderVisibility(true);
    this._app.getUpdateInfoOfficer(payload)
    .pipe(finalize(() => this._data.changeLoaderVisibility(false)))
    .subscribe((response) =>{
      this._data.toastrShow(`Information have been successfully updated`, 'success');
      const payload = {
        "message": 'Officer Information have been successfully updated',
        "page": "Officer Basic Info Update",
        "geoLocation": null
      }
      if(this.coBasicDone){
        this.coBasicDone = { ...this.coBasicDone, kysTpeCheckStatus: 'COMPLETED' }
      }
      let status = this.coBasicDone
      this._app.sendStatus(status).subscribe(() => {
        this._app.getStatus().subscribe(res => {
          this._data.checksStatus(res['data']);
          this.output.emit('coBasicDone');
        })
      })
      this.getInitialData();
      this._app.feasAddActivityLog(localStorage.getItem("officerKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Officer Basic Info Update",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("officerKysNo"), payload).subscribe();
      }
    )
  }

}
