import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
@Injectable({
  providedIn: "root"
})
export class SelfieService {
  helperBackgroundImage: any = null;
  helperText: any = null;

  bgArr: any[] = [6, 1, 12, 6];
  instructionArr: any[] = [
    "Please Look Straight At The Camera",
    "Please Turn Your Face To The Right",
    "Please Turn Your Face To The Left",
    "Please Look Straight At The Camera & Blink"
  ];
  audioArr: any[] = [
    "assets/audio/center.wav",
    "assets/audio/right.wav",
    "assets/audio/left.wav",
    "assets/audio/blink.wav"
  ];

  videoStream: any;
  audioStream: any;

  constructor(private translate: TranslateService) {}

  init() {
    this.translate.get("Directions").subscribe((res: string) => {
      console.log(res);
      this.instructionArr = Object.values(res);
    });
    if (localStorage.getItem("audios")) {
      this.audioArr = [];
      var audios = JSON.parse(localStorage.getItem("audios"));
      console.log(audios);
      this.audioArr.push(audios.center);
      this.audioArr.push(audios.right);
      this.audioArr.push(audios.left);
      this.audioArr.push(audios.blink);
      console.log(this.audioArr);
    } else {
      this.audioArr = [
        "assets/audio/center.mp3",
        "assets/audio/right.mp3",
        "assets/audio/left.mp3",
        "assets/audio/blink.mp3"
      ];
    }
    console.log(this.audioArr);
  }

  stopVideo() {
    var video = <HTMLMediaElement>document.getElementById("liveWebcamVideo");
    if (video) {
      video.pause();
      video.src = "";
    }
    if (this.videoStream) {
      this.videoStream.getTracks()[0].stop();
    }
    console.log("Vid off");
  }

  stopAudio() {
    if (this.audioStream) {
      this.audioArr = [];
      this.audioStream.pause();
      this.audioStream.currentTime = 0;
      this.audioStream.src = "";
      this.instructionArr = [];
      this.videoStream = null;
      this.audioStream = null;
    }
  }
}
