import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { AppService } from "../../services/app.service";
import { DataService } from "../../services/data.service";
import { IndividualUserInfo } from "../../models/individual-user-info";
import { Check } from "../../models/check";
import { UiStateService } from "../../services/ui-state.service";
import { KycService } from "../../services/kyc.service";

import { CustomFormsModule } from "ng2-validation";
import { first } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { Ng2DeviceService } from "ng2-device-detector";


@Component({
  selector: "app-getting-started",
  templateUrl: "./getting-started.component.html",
  styleUrls: ["./getting-started.component.scss"],
})
export class GettingStartedComponent implements OnInit {
  heroImages: any[] = [
    {
      name: "auth",
      image:
        "https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/authentication_fsn5.svg",
    },
    {
      name: "security",
      image:
        "https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/security_o890.svg",
    },
  ];

  countries: any[] = [];
  individualUserInfo: IndividualUserInfo;
  checks: Check[] = [];
  checkLinkGUID: string;
  kycOriginCountry: string;

  selectedCountry: string;
  isCountryUpdatable: boolean;
  isProfileUpdatable: boolean;

  themingInfo: any;

  newdob :any;

  //Mobile Vars
  isMobile: boolean;
  step: number = 1;

  //logo
  logo: string;
  deviceInfo: any;
  geolocation: any;
  deviceName: string;
  gettingStaredImage : string;
  ip: any;

  constructor(
    public _app: AppService,
    public _data: DataService,
    public _kyc: KycService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public uiStateService: UiStateService,
    public cdRef: ChangeDetectorRef,
    private deviceService: Ng2DeviceService
    
  ) {this.deviceInformation();}

  async ngOnInit() {
    this.isMobile = this.uiStateService.getIsMobileResolution();
    this.getCountries();
    this.getLocalData();
    this.selectedCountry = this.individualUserInfo.residenceCountry.countryName;
    console.log("ISMOBILE: ", this.isMobile);
    this.logo = await this._data.getCookiesByName("logo");
    this.gettingStaredImage  = await this._data.getCookiesByName("gettingStarted_image");
    this.getIp();
  }

  getCountries() {
    this._app.getAllCountries().subscribe(res => {
      this.countries = res.data;
    });
  }

  getLocalData() {
    this.individualUserInfo = <IndividualUserInfo>(
      this._data.getCookieObjectByName("individualUserInfo")
    );
    this.kycOriginCountry = <any>(
      this._data.getCookieObjectByName("kycOriginCountry")
    );
    this.checkLinkGUID = <any>this._data.getCookieObjectByName("checkLinkGUID");
    this.checks = <Check[]>JSON.parse(localStorage.getItem("remoteChecks"));
    this.isProfileUpdatable =
      this._data.getCookiesByName("isProfileUpdatable") === "true";
    this.isCountryUpdatable =
      this._data.getCookiesByName("isCountryUpdatable") === "true";
    console.log(this.individualUserInfo);
    console.log(this.kycOriginCountry);
    console.log(this.checkLinkGUID);
    console.log(this.checks);
    this.themingInfo = JSON.parse(localStorage.getItem("themingInfo"));

    
    //normalizeDate
    if(this.individualUserInfo["dob"]){
      console.log(this.individualUserInfo["dob"]);
      // let newDob = new Date(this.individualUserInfo["dob"]);
      // let dobOffset = newDob.getTimezoneOffset() * 60000;
      // var normalizedDob = new Date(newDob.getTime() - dobOffset).toISOString().split('T')[0].split("-").reverse().join("/");
      this.newdob = this.individualUserInfo["dob"].split("-").reverse().join("/");
      console.log(this.individualUserInfo["dob"].split("-").reverse().join("/"));

    }

    // if (this.individualUserInfo["dob"]) {
    //   let newDob = new Date(this.individualUserInfo["dob"]);
    // let dateExpiryOffset = newDob.getTimezoneOffset() * 60000;
    // let normalizeExpiryDate = new Date(newDob.getTime() - dateExpiryOffset).toISOString().split('T')[0];
    
      // let newDob = new Date(this.individualUserInfo["dob"]);
      
      // this.individualUserInfo["dob"] = newDob


      // this.datepipe.transform(newDob,'dd/MM/yyyy')
    //   console.log(newDob)
      
    // }
  }

  updateIndividualInfo() {
    this._data.changeLoaderVisibility(true);
    let countryCode = this.countries.filter(
      obj => obj.countryName == this.selectedCountry
    );
      var normalizedDob;
      console.log(typeof(this.newdob), typeof(this.individualUserInfo["dob"]));

    //if (this.newdob.split("/").reverse().join("-") === this.individualUserInfo["dob"]) {
      // var normalizedDob = this._data.getNormalizedDate(
      //   this.individualUserInfo["dob"]
      // );
      if (typeof(this.newdob) === typeof(this.individualUserInfo["dob"]) && typeof(this.newdob)!= 'object') {
      normalizedDob = this.newdob.split("/").reverse().join("-");
      console.log(normalizedDob);
    }else{
      let newDob = new Date(this.newdob);
      let dobOffset = newDob.getTimezoneOffset() * 60000;
      normalizedDob = new Date(newDob.getTime() - dobOffset).toISOString().split('T')[0];
      console.log(normalizedDob);
    }

    // console.log(":::::::::::::::::", "this.individualUserInfo");

    let obj = {
      individualUserType: "NORMAL",
      firstName: this.individualUserInfo.firstName,
      middleName: this.individualUserInfo.middleName,
      lastName: this.individualUserInfo.lastName,
      dob: normalizedDob,
      gender: this.individualUserInfo.gender,
      residenceCountryCode: countryCode[0].countryCode,
      checkLinkGUID: this.checkLinkGUID,
      os:this.deviceInfo.os,
      browser:this.deviceInfo.browser,
      device:this.deviceName,      
      location:this.geolocation,
      // ip:this.ip
    };
    this._app
      .updateIndividualInfo(obj, this.individualUserInfo.userName)
      .subscribe(res => {
        this._data.changeLoaderVisibility(false);
        this._data.setCookieObject("kycOriginCountry", this.selectedCountry);
        this._data.setCookieObject(
          "individualUserInfo",
          this.individualUserInfo
        );
        this._kyc.getLocalData();
        this._kyc.populateKycChecks();
        this._kyc.setKycState();
        this._kyc.isKycStateLoadingDone = true;
        // this.router.navigate(["/kyc"]);
        let redirectionRoute = this._kyc.getTraverseState(1);
        console.log(this._kyc.getCurrentState(1));
        console.log(redirectionRoute);
        this.router.navigate([redirectionRoute]);
        this.logActivity();
      });
  }

  logActivity() {
    let logData = {
      entity: "a",
      value: "b",
      message: "Updated User Info",
      page: "Getting-Started",
      os:this.deviceInfo.os,
      browser:this.deviceInfo.browser,
      device:this.deviceName,
      source:"FEAS",
      geoLocation:this.geolocation
    };

    this._app
      .saveUserActivityLogNew(logData, this.individualUserInfo["userName"])
      .subscribe(
        res => {
          console.log(res);
        },
        err => {
          console.log(err);
        }
      );
  }
  deviceInformation() {    
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      const isDesktopDevice = this.deviceService.isDesktop();
      if(this.deviceService.device=="unknown"){
        if(isDesktopDevice){
          this.deviceName='Desktop';
        }
        if(isMobile){
          this.deviceName='Mobile';
        }
        if(isTablet){
          this.deviceName='Tablet';
        }
      }else this.deviceName=this.deviceInfo.device;
    console.log("device info",this.deviceName);
    this.getLocation();
  }
  getLocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position)=>{
          this._app.getLocation(position.coords.latitude,position.coords.longitude).subscribe(res=>{
            if(res.city==""){
              this.geolocation=`${res.locality}, ${res.countryName}`;
            }else this.geolocation=`${res.city}, ${res.countryName}`;
                
            console.log("location",this.geolocation)      
          });
          
          // this.geolocation=`lat:${position.coords.latitude},lon:${position.coords.longitude}`;
          // console.log("geolcation",this.geolocation);
          // let geocoder = new google.maps.Geocoder();
        });
    } else {
       this.geolocation=null;
    }
}
getIp(){
  // this._app.getSystemIp().subscribe((res:any)=>{  
  //   this.ip=res.ip;
  //   console.log("ip adress",this.ip)
  // });
}
  stepForward(currentStep) {
    // this.goingForward = true;
    this.step = currentStep + 1;
    console.log(this.step);
  }
  stepBack(currentStep) {
    // this.goingForward = false;
    this.step = currentStep - 1;
    console.log(this.step);
  }
  

}
