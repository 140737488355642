import { Component, OnInit } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DataService } from 'src/app/services/data.service';
import { AppService } from "../../services/app.service";
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-kys-verification',
  templateUrl: './kys-verification.component.html',
  styleUrls: ['./kys-verification.component.scss']
})
export class KysVerificationComponent implements OnInit {

  fileToUpload: File = null;
  documents :any = ["ID Document", "Address Doc"];
  countries: any = [];
  step: any =  1;
  jurisdictions: any;
  getData: any = [];
  frontDrivingLicense: File = null;
  backDrivingLicense: File = null ;
  fileTypeFront: string = "";
  contentArray : any=[]
  returnedArray: any=[];
  currentPage: number;
  toggleArrow:boolean = false;
  submittedDoc: boolean = false;
  additionalInfo: number = 1;
  formDetails: boolean = false;
  accountForm: boolean = false;

  constructor(
    public _app: AppService,
    public _data:DataService
  ) { }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    console.log(this.fileToUpload);
   console.log(this.fileToUpload.type)
    
}

addInfoStep(){
  this.additionalInfo = 2;
}
gotoList(){
  this.additionalInfo = 1;
}
showAccountOpeningForm(){
  this.formDetails = true;
  this.accountForm = true;
}

gotoFormList(){
  this.formDetails = false;
  this.accountForm = false;
}
toggleArrowClick(){
  console.log("i am clicked")
  if(this.toggleArrow == true){
    this.toggleArrow = false;
  }else if(this.toggleArrow == false){
    this.toggleArrow = true;
  }
}

openModal(){
  const buttonModal = document.getElementById("openModalButton")
  console.log('buttonModal', buttonModal)
  buttonModal.click()
}

handleFileInputFrontDriving(event){
  this.frontDrivingLicense = event.srcElement.files.item(0);
  // console.log(files);
  if(this.frontDrivingLicense.type == "image/jpeg"){
    this.fileTypeFront = "jpg"
  }else if(this.frontDrivingLicense.type == "application/pdf"){
    this.fileTypeFront = "pdf"
  }
  
}
handleFileInputBackDriving(event){
  this.backDrivingLicense = event.srcElement.files.item(0);
  console.log(this.backDrivingLicense);
  if(this.backDrivingLicense.type == "image/jpeg"){
    this.fileTypeFront = "jpg"
  }else if(this.backDrivingLicense.type == "application/pdf"){
    this.fileTypeFront = "pdf"
  }
}

sendDocument(id){
  var formDataNew = new FormData();
  // formDataNew.append("key1","abc");
  formDataNew.append("frontImage", this.frontDrivingLicense);
  formDataNew.append("rearImage", this.backDrivingLicense);
  formDataNew.append("fileType", this.fileTypeFront);
  formDataNew.append("checkguid", id);
  
  // for(var value of formDataNew.values()){
  //   console.log(value);
  // }
  console.log(formDataNew);
   this._app
    .uploadDocuments(formDataNew)
    .subscribe(
      res => {
        console.log(res.message);
        let alertObj = {
          message: res["message"],
          status: "success",
          autoDismiss: true,
          timeToDismiss: 10000
        };
        this._data.toastrShow(res.message, "info");
        this.frontDrivingLicense = null;
        this.backDrivingLicense = null;
        this.getAllData();
      },
      err => {
        console.log(err);
      }
    );
}

getAllCountries() {
  // this._app
  //   .getAllCountries()
  //   .pipe(first())
  //   .subscribe(
  //     res => {
  //       this.countries = res["data"];
  //     },
  //     err => {
  //       console.log(err);
  //     }
  //   );
}
getCompanyJurisdictions(country) {
  // this._app
  //   .getCompanyJurisdictions()
  //   .pipe(first())
  //   .subscribe(
  //     res => {
  //       console.log(res);
  //       console.log(res["data"].countryCode)
  //       res["data"].map(item => {
  //         if(country === item.countryCode){
  //             this.jurisdictions = item["jurisdictions"];
  //           }
  //       })        
  //     },
  //     err => {
  //       console.log(err);
  //     }
  //   );
}

onCountrySelect() {
  // let country = this.registerForm.value.countryCode;
  // console.log(country);
  // this.getCompanyJurisdictions(country)
  // let jurisdiction = this.registerForm.value.jurisdiction;
  // console.log(jurisdiction);
  // if (country) {
  //   this._app.getAllowedDocs(country).subscribe(res => {
  //     (this.allowedDocs["validIdentityDoc"] = res.data["validIdentityDocs"]),
  //       (this.allowedDocs["validAddressDoc"] = res.data["validAddressDocs"]);
  //   });
  // }
}
onJurisdictionSelect() {

}
getAllData(){
  this._app.getDetails()
  .subscribe(
        res => {
          console.log(res);
          this.getData = res["data"]
            console.log(this.getData);
            this.contentArray=this.getData.kysDocumentChecks
            console.log(this.contentArray.length)
            this.returnedArray = this.contentArray.slice(0, 1)
        },
        err => {
          console.log(err);
        }
  );
}
// change(t:Object,i){
//   ++i
// }
pageChanged(event: PageChangedEvent): void {
  const startItem = (event.page - 1) * event.itemsPerPage;
  const endItem = event.page * event.itemsPerPage;
  this.returnedArray = this.contentArray.slice(startItem, endItem);
  this.currentPage=event.page
}
  ngOnInit() {
    this.getAllData();
    console.log(this.contentArray)
    
  }
  myFunction() {
    alert("I am an alert box!");
  }
  isfrmChecked:any;

  onCheckboxChecked(event: any, isChecked: boolean){
   
    if (isChecked) {
      this.submittedDoc = true;
    }
    else {
      this.submittedDoc = false;
    }
    
  }

  officerSelectedDetails: string = 'ubo';
  officerSelected(event){
    console.log(event.target.value);
    if(event.target.value == 'ubo'){
      this.officerSelectedDetails = 'ubo'
    }else if(event.target.value == 'director'){
      this.officerSelectedDetails = 'director'
    }else if(event.target.value == 'officer'){
      this.officerSelectedDetails = 'officer'
    }
  }
  

  forms = `
      <label style="color:green">Name</label><input type="text">
      <button class="btn-primary" onclick="myFunction()">Click Me</button>
      <form action="/action_page.php">
  <label for="fname">First name:</label><br>
  <input type="text" id="fname" name="fname" value="John"><br>
  <label for="lname">Last name:</label><br>
  <input type="text" id="lname" name="lname" value="Doe"><br><br>
  <input type="submit" value="Submit">
</form> 

 
  `
}
