import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { AppService } from '../../../services/app.service';
import { DatePipe } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
  FormArray,
  NgForm,

} from "@angular/forms";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { finalize, first } from 'rxjs/operators';
import { CustomValidators } from "ng2-validation";
import { HttpErrorResponse } from '@angular/common/http';
import { style } from '@angular/animations';
import { element } from 'protractor';
@Component({
  selector: 'app-kys-retail-basic-info',
  templateUrl: './kys-retail-basic-info.component.html',
  styleUrls: ['./kys-retail-basic-info.component.scss']
})
export class KysRetailBasicInfoComponent implements OnInit {
  primaryPersoanlDataIndiForm: FormGroup;
  accountID: any;
  personalID1: any;
  personalID2: any;
  isSecondary: boolean = false;
  primaryResidentialDataIndiForm: FormGroup;
  countries: any;
  claimArray: any[] = [
    { key: 'true' },
    { key: 'false' },
  ]
  kysNoPrimary: any;
  kysNoSecondary: any;

  constructor(
    private formBuilder: FormBuilder,
    private _data: DataService,
    private _app: AppService,
    public router: Router,
    public route: ActivatedRoute
  ) { }
  ngOnInit() {
    this.primaryPersoanlDataIndiForm = this.formBuilder.group({
      firstNamePInd: [''],
      dobPInd: [''],
      dobDayPInd: [''],
      dependentsPInd: [''],
      phoneCodePInd: [''],
      lastNamePInd: [''],
      dobMPInd: [''],
      maritalStatusPInd: [''],
      maidenNamePInd: [''],
      phonePInd: [''],
      middleNamePInd: [''],
      firstNameSJoint: [''],
      dobSJoint: [''],
      dobDaySJoint: [''],
      dependentsSJoint: [''],
      phoneCodeSJoint: [''],
      lastNamePSoint: [''],
      dobMPSoint: [''],
      maritalStatusSJoint: [''],
      maidenNameSJoint: [''],
      phoneSJoint: [''],
      middleNameSJoint: ['']
    });
    this.primaryResidentialDataIndiForm = this.formBuilder.group({
      countryPInd: [''],
      cityPInd: [''],
      employmentStatusPInd: [''],
      yearsPInd: [''],
      natureOfBusinessPInd: [''],
      addressLine1BusinessPInd: [''],
      zipCodeBusinessPInd: [''],
      claimTaxPInd: [''],
      associatePind: [''],
      addressLine1Pind: [''],
      zipCodePind: [''],
      companyNamePind: [''],
      positionPInd: [''],
      countryBusinessPind: [''],
      cityBusinessPind: [''],
      foreignTaxNoPInd: [''],
      countryTaxPInd: [''],
      countrySJoint: [''],
      citySJoint: [''],
      employmentStatusSJoint: [''],
      yearsSJoint: [''],
      natureOfBusinessSJoint: [''],
      addressLine1BusinessSJoint: [''],
      zipCodeBusinessSJoint: [''],
      claimTaxSJoint: [''],
      associateSJoint: [''],
      addressLine1SJoint: [''],
      zipCodeSJoint: [''],
      companyNameSJoint: [''],
      positionSJoint: [''],
      countryBusinessSJoint: [''],
      cityBusinessSJoint: [''],
      foreignTaxNoSJoint: [''],
      countryTaxSJoint: [''],
    });

    this._app.getAllCountry().subscribe((response) => {
      this.countries = response
    });

    this._app.getAccountInfoForRetail().subscribe((response) => {
      this.accountID = response.accountId
      console.log("Account ID: ", this.accountID);

      this._app.getIndividualInfoForRetail(this.accountID).subscribe((response) => {
        var individualData = response;
        for (var i = 0; individualData.length > i; i++) {
          if (individualData[i].tpe.account.accountType === "JOINT") {
            this.isSecondary = true
            if (individualData[i].tpe.kysTpeAccount === "PRIMARY") {
              this.personalID1 = individualData[i].id;
              this.kysNoPrimary = individualData[i].tpe.kysNo;
              localStorage.setItem("primaryKysNo", individualData[i].tpe.kysNo);
              console.log("Personal ID 1: ", this.kysNoPrimary);
              localStorage.setItem('countryCode', response[0].country.countryCode);
              this.primaryPersoanlDataIndiForm.patchValue({
                firstNamePInd: individualData[i].firstName,
                middleNamePInd: individualData[i].middleName,
                maidenNamePInd: individualData[i].motherMaidenName,
                lastNamePInd: individualData[i].lastName,
                citizenshipPInd: individualData[i].country.countryName,
                emailPind: individualData[i].tpe.primaryEmail,
              });
            }
            if (individualData[i].tpe.kysTpeAccount === "SECONDARY") {
              this.personalID2 = individualData[i].id;
              this.kysNoSecondary = individualData[i].tpe.kysNo;
              localStorage.setItem("secondaryKysNo", individualData[i].tpe.kysNo);
              console.log("Personal ID 2: ", this.kysNoSecondary);
              localStorage.setItem('countryCodeJ2', response[1].country.countryCode);
              this.primaryPersoanlDataIndiForm.patchValue({
                firstNameSJoint: individualData[i].firstName,
                middleNameSJoint: individualData[i].middleName,
                maidenNameSJoint: individualData[i].motherMaidenName,
                lastNamePSoint: individualData[i].lastName,
                citizenshipSJoint: individualData[i].country.countryName,
                emailSJoint: individualData[i].tpe.primaryEmail,
              });
            }
          } else {
            console.log(response[0].country.countryCode);
            localStorage.setItem('countryCode', response[0].country.countryCode);
            this.personalID1 = individualData[i].id
            this.kysNoPrimary = individualData[i].tpe.kysNo
            localStorage.setItem("primaryKysNo", individualData[i].tpe.kysNo);
            console.log("Personal ID 1: ", this.kysNoPrimary);
            this.primaryPersoanlDataIndiForm.patchValue({
              middleNamePInd: individualData[i].middleName,
              firstNamePInd: individualData[i].firstName,
              maidenNamePInd: individualData[i].motherMaidenName,
              lastNamePInd: individualData[i].lastName,
              citizenshipPInd: individualData[i].country.countryName,
              emailPind: individualData[i].tpe.primaryEmail,
            });
          }
        }
      })
    })

  }

  getUpdateInfo() {
    this.getUpdateIndividualInfo1(this.accountID, this.personalID1)
    if (this.isSecondary == true) {
      this.getUpdateIndividualInfo2(this.accountID, this.personalID2)
    }
  }

  getUpdateIndividualInfo1(accontId, personalId) {
    let obj = {
      firstName: this.primaryPersoanlDataIndiForm.value.firstNamePInd,
      middleName: this.primaryPersoanlDataIndiForm.value.middleNamePInd,
      lastName: this.primaryPersoanlDataIndiForm.value.lastNamePInd,
      dob: this.primaryPersoanlDataIndiForm.value.dobPInd,
      maritalStatus1: this.primaryPersoanlDataIndiForm.value.maritalStatusPInd,
      dependents: parseInt(this.primaryPersoanlDataIndiForm.value.dependentsPInd),
      motherMaidenName: this.primaryPersoanlDataIndiForm.value.maidenNamePInd,
      phoneCode: this.primaryPersoanlDataIndiForm.value.phoneCodePInd,
      phone: parseInt(this.primaryPersoanlDataIndiForm.value.phonePInd),
      residenceCountry:
        this.primaryResidentialDataIndiForm.value.countryPInd == '' ? null : this.primaryResidentialDataIndiForm.value.countryPInd,
      city: this.primaryResidentialDataIndiForm.value.cityPInd,
      zipCode: this.primaryResidentialDataIndiForm.value.zipCodePind,
      employmentStatus: this.primaryResidentialDataIndiForm.value.employmentStatusPInd,
      companyName: this.primaryResidentialDataIndiForm.value.companyNamePind,
      years: this.primaryResidentialDataIndiForm.value.yearsPInd,
      postions: this.primaryResidentialDataIndiForm.value.positionPInd,
      natureOfBusiness: this.primaryResidentialDataIndiForm.value.natureOfBusinessPInd,
      businessCountry: this.primaryResidentialDataIndiForm.value.countryBusinessPind,
      businessAddressLine1: this.primaryResidentialDataIndiForm.value.addressLine1BusinessPInd,
      businessCity: this.primaryResidentialDataIndiForm.value.cityBusinessPind,
      businessZipCode: this.primaryResidentialDataIndiForm.value.zipCodeBusinessPInd,
      areYouCommercialEnterprise: this.primaryResidentialDataIndiForm.value.associatePind,
      foreignTaxIdNumber: this.primaryResidentialDataIndiForm.value.foreignTaxNoPInd,
      clamTaxTreatyBenifits: this.primaryResidentialDataIndiForm.value.claimTaxPInd,
      individualCountry: this.primaryResidentialDataIndiForm.value.countryTaxPInd,
      gender: null,
      placeOfBirthCity: null,
      placeOfBirthState: null,
      placeOfBirthCountry: null,
      fatherName: null,
      addressLine1: this.primaryResidentialDataIndiForm.value.addressLine1Pind,
      addressLine2: null,
    }
    this._data.changeLoaderVisibility(true);
    this._app.getUpdateIndividualInfo(accontId, personalId, obj)
      .pipe(finalize(() => this._data.changeLoaderVisibility(false)))
      .subscribe((response) => {
        this._data.toastrShow(`Information have been successfully updated`, 'success');
        const payload = {
          "message": 'Primary Holder Information have been successfully updated',
          "page": "Primary Holder Basic Info Update",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(this.kysNoPrimary, payload).subscribe();
      },
        (err: HttpErrorResponse) => {
          const payload = {
            "message": err,
            "page": "Primary Holder Basic Info Update",
            "geoLocation": null
          }
          this._app.feasAddActivityLog(this.kysNoPrimary, payload).subscribe();
        }
      )
  }

  getUpdateIndividualInfo2(accontId, personalId) {
    let obj = {
      firstName: this.primaryPersoanlDataIndiForm.value.firstNamePInd,
      lastName: this.primaryPersoanlDataIndiForm.value.lastNamePInd,
      dob: this.primaryPersoanlDataIndiForm.value.dobPInd,
      maritalStatus1: this.primaryPersoanlDataIndiForm.value.maritalStatusPInd,
      dependents: parseInt(this.primaryPersoanlDataIndiForm.value.dependentsPInd),
      motherMaidenName: this.primaryPersoanlDataIndiForm.value.maidenNamePInd,
      phoneCode: this.primaryPersoanlDataIndiForm.value.phoneCodePInd,
      phone: parseInt(this.primaryPersoanlDataIndiForm.value.phonePInd),
      residenceCountry:
        this.primaryResidentialDataIndiForm.value.countryPInd == '' ? null : this.primaryResidentialDataIndiForm.value.countryPInd,
      city: this.primaryResidentialDataIndiForm.value.cityPInd,
      zipCode: this.primaryResidentialDataIndiForm.value.zipCodePind,
      employmentStatus: this.primaryResidentialDataIndiForm.value.employmentStatusPInd,
      companyName: this.primaryResidentialDataIndiForm.value.companyNamePind,
      years: this.primaryResidentialDataIndiForm.value.yearsPInd,
      postions: this.primaryResidentialDataIndiForm.value.positionPInd,
      natureOfBusiness: this.primaryResidentialDataIndiForm.value.natureOfBusinessPInd,
      businessCountry: this.primaryResidentialDataIndiForm.value.countryBusinessPind,
      businessAddressLine1: this.primaryResidentialDataIndiForm.value.addressLine1BusinessPInd,
      businessCity: this.primaryResidentialDataIndiForm.value.cityBusinessPind,
      businessZipCode: this.primaryResidentialDataIndiForm.value.zipCodeBusinessPInd,
      areYouCommercialEnterprise: this.primaryResidentialDataIndiForm.value.associatePind,
      foreignTaxIdNumber: this.primaryResidentialDataIndiForm.value.foreignTaxNoPInd,
      clamTaxTreatyBenifits: this.primaryResidentialDataIndiForm.value.claimTaxPInd,
      individualCountry: this.primaryResidentialDataIndiForm.value.countryTaxPInd,
      middleName: this.primaryPersoanlDataIndiForm.value.middleNameSJoint,
      gender: null,
      placeOfBirthCity: null,
      placeOfBirthState: null,
      placeOfBirthCountry: null,
      fatherName: null,
      addressLine1: this.primaryResidentialDataIndiForm.value.addressLine1Pind,
      addressLine2: null,
    }
    this._data.changeLoaderVisibility(true);
    this._app.getUpdateIndividualInfo(accontId, personalId, obj)
      .pipe(finalize(() => this._data.changeLoaderVisibility(false)))
      .subscribe((response) => {
        this._data.toastrShow(`Information have been successfully updated`, 'success');
        const payload = {
          "message": 'Secondary Holder Information have been successfully updated',
          "page": "Secondary Holder Basic Info Update",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(this.kysNoSecondary, payload).subscribe();
      },
        (err: HttpErrorResponse) => {
          const payload = {
            "message": err,
            "page": "Secondary Holder Basic Info Update",
            "geoLocation": null
          }
          this._app.feasAddActivityLog(this.kysNoSecondary, payload).subscribe();
        }
      )
  }


}
