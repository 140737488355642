import { Component, OnInit } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DataService } from 'src/app/services/data.service';
import { AppService } from "../../services/app.service";
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { addressDocument, bankDocument, DocumentInfo, idDocument, marital } from 'src/app/models/document';
import { AddressDocumentInfo } from 'src/app/models/document';
import { BankDocumentInfo } from 'src/app/models/document';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
  FormArray,
  NgForm,
  
} from "@angular/forms";
import { first } from 'rxjs/operators';
import { CustomValidators } from "ng2-validation";
import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { style } from '@angular/animations';
import { element } from 'protractor';
import { Observable } from 'rxjs/internal/Observable';
@Component({
  selector: 'app-kys-retail-feas',
  templateUrl: './kys-retail-feas.component.html',
  styleUrls: ['./kys-retail-feas.component.scss']
})
export class KysRetailFeasComponent implements OnInit {
  tabs = [
    { active: true  }
 ];
 checks:any;
 isSelfie;
 showQuestionr:boolean = false
 constructor(
  private formBuilder: FormBuilder,
  private _data: DataService,
  private _app: AppService,
  public router: Router,
  public route: ActivatedRoute,
  private http: HttpClient
) { }
ngOnInit() {
  this._app.getAccountInfoForRetail().subscribe((response) =>{
      let accountID = response.accountId;
      localStorage.setItem("clientID",response.client.clientID)
      this._app.getAllRetailCheck(accountID).subscribe(res=>{
        this.checks=res["primary"]["allCheck"]["idvChecks"][0];
        console.log("Checks", this.checks);
        this.isSelfie =(this.checks['SELFIE'].length>0||this.checks['LIVELINESS'].length>0)?true:false
      })
    });
    this._app.getAllQuestionnaireCheck().subscribe((res) => {
      if(res && res.length){
        this.showQuestionr = true
      }
    });
}

}

