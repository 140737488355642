import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { AppService } from "../../services/app.service";
import { DataService } from "../../services/data.service";
import { UiStateService } from "../../services/ui-state.service";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IndividualUserInfo } from "src/app/models/individual-user-info";
import { Ng2DeviceService } from "ng2-device-detector";


@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.scss"],
})
export class TermsComponent implements OnInit {
  isTermsChecked: boolean = false;
  individualUserInfo: IndividualUserInfo;
  //   logo: string =
  //     "";
  mailObj = { mailId: "info@kychub.com" };
  geolocation: any;
  deviceName: any;
  ip: any;
  deviceInfo: any;
  constructor(
    public _app: AppService,
    public _data: DataService,
    public router: Router,
    public uiStateService: UiStateService,
    private _sanitizer:DomSanitizer,
    private deviceService: Ng2DeviceService
  ) {}

  //Mobile Vars
  isMobile: boolean;
  logo: string;

  async ngOnInit() {
    this.isMobile = this.uiStateService.getIsMobileResolution();
    console.log("isMobile: ", this.isMobile);
    this.individualUserInfo = <IndividualUserInfo>(
      this._data.getCookieObjectByName("individualUserInfo")
    );
    this.logo = await this._data.getCookiesByName("logo");
    var elem = <HTMLElement>document.querySelector('.watermark');
    elem.style.backgroundImage="url('" + this.logo + "')";
    console.log(this.logo);
    console.log("indi",this.individualUserInfo)
    this.deviceInformation();
    this.getIp();
    
  }

  startKyc() {
    if (this.isTermsChecked) {
      // this.router.navigate(["/getting-started"]);
      //this.router.navigate(["/kys-verification"]);
      this.router.navigate(["/kys-retail-feas"]);
      // this.logActivity();
    }
  }

  // end user Activity tracking
  logActivity() {
    let logData = {
     
      os:this.deviceInfo.os,
      browser:this.deviceInfo.browser,
      device:this.deviceName,      
      location:this.geolocation,
      // ip:this.ip
    };
console.log(this.individualUserInfo)
    this._app
      .saveUserActivityLogAgrement(logData, this.individualUserInfo.userName)
      .subscribe(
        res => {
          console.log(res);
        },
        err => {
          console.log(err);
        }
      );
  }
  getIp(){
    // this._app.getSystemIp().subscribe((res:any)=>{  
    //   this.ip=res.ip;
    //   console.log("ip adress",this.ip)
    // });
  }
  deviceInformation() {    
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      const isDesktopDevice = this.deviceService.isDesktop();
      if(this.deviceService.device=="unknown"){
        if(isDesktopDevice){
          this.deviceName='Desktop';
        }
        if(isMobile){
          this.deviceName='Mobile';
        }
        if(isTablet){
          this.deviceName='Tablet';
        }
      }else this.deviceName=this.deviceInfo.device;
    console.log("device info",this.deviceName);
    this.getLocation();
  }
  getLocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position)=>{
          this._app.getLocation(position.coords.latitude,position.coords.longitude).subscribe(res=>{
            if(res.city==""){
              this.geolocation=`${res.locality}, ${res.countryName}`;
            }else this.geolocation=`${res.city}, ${res.countryName}`;
                
            console.log("location",this.geolocation)      
          });
          
          // this.geolocation=`lat:${position.coords.latitude},lon:${position.coords.longitude}`;
          // console.log("geolcation",this.geolocation);
          // let geocoder = new google.maps.Geocoder();
        });
    } else {
       this.geolocation=null;
    }
}
}
