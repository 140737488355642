import { Injectable } from '@angular/core';
import { DataService } from "../services/data.service";
import { IndividualUserInfo } from "../models/individual-user-info";
import { Check } from "../models/check";
import { State } from "../models/state";

@Injectable({
  providedIn: 'root'
})
export class KycService {

	isKycStateLoadingDone : boolean = false ;

	individualUserInfo: IndividualUserInfo ;
	checks            : Check[] = [];
	checkLinkGUID     : string ;
	kycOriginCountry  : string ;
	redirectUri       : string ;
	isCountryUpdatable: boolean;
	isProfileUpdatable: boolean;
	
	kycState          : State[] = [];
	
	kycChecks         : any  = {
			"id-doc-check"     : null,
			"address-doc-check": null,
			"liveness-check"   : null,
			"selfie-check"     : null
	};

	routesMap         : any = {
		"id-doc-check"     : "/kyc/id-check",
		"address-doc-check": "/kyc/address-check",
		"selfie-check"     : "/kyc/face-check",
		"liveness-check"   : "/kyc/face-check"
	}

	themingInfo       : any ;

	constructor(
		private _data: DataService,
	) {
	}

	getLocalData(){
		this.individualUserInfo = <IndividualUserInfo>this._data.getCookieObjectByName("individualUserInfo");
		this.kycOriginCountry   = <any>this._data.getCookieObjectByName("kycOriginCountry");
		this.checkLinkGUID      = <any>this._data.getCookieObjectByName("checkLinkGUID");
		this.checks             = <Check[]>JSON.parse(localStorage.getItem('remoteChecks'));
		this.isProfileUpdatable = (this._data.getCookiesByName("isProfileUpdatable") === "true");
		this.isCountryUpdatable = (this._data.getCookiesByName("isCountryUpdatable") === "true");
		this.redirectUri        = this._data.getCookiesByName("redirectUri");
		this.themingInfo        = JSON.parse(localStorage.getItem('themingInfo'));
		// console.log(this.individualUserInfo);
		// console.log(this.kycOriginCountry);
		// console.log(this.checkLinkGUID);
		// console.log(this.checks);
	}

	populateKycChecks(){
		for(var slug in this.kycChecks) {
			let slugGroup = this.checks.filter(obj => slug == obj.standardCheck.slug);
			if (slugGroup.length) {
				this.kycChecks[slug] = slugGroup[0];
			}
		}
		console.log(this.kycChecks);
	}

	setKycState(){
		for (let slug in this.kycChecks) {
			let slugGroup = this.checks.filter(obj => obj.standardCheck.slug == slug);
			if (slugGroup.length) {
				const checkState: State = new State();
				if (this.kycState.length) {
					this.kycState[this.kycState.length - 1].next        = true;
					this.kycState[this.kycState.length - 1].isFinalStep = false;
					checkState.standardSlugGroup                        = slug;
					checkState.stepNo                                   = this.kycState[this.kycState.length - 1].stepNo + 1;
					checkState.next                                     = null;
					checkState.previous                                 = true;
					checkState.isFinalStep                              = true;
					checkState.isAllowed                                = false;
				} else {
					checkState.standardSlugGroup = slug;
					checkState.stepNo            = 1;
					checkState.next              = null;
					checkState.previous          = null;
					checkState.isFinalStep       = true;
					checkState.isAllowed         = true;
				}
				this.kycState.push(checkState);
			}
		}
		console.log(this.kycState);
	}

	getData(attr){
		return this[attr] ? this[attr] : null ;
	}

	getTraverseState(stepNo){
		for(let key in this.kycChecks){
			let presentStep      = this.kycState.filter(obj=>obj.stepNo == stepNo)[0];
			let redirectionRoute = this.routesMap[presentStep["standardSlugGroup"]];
			return redirectionRoute ;
			// console.log(this.routesMap[presentStep["standardSlugGroup"]]);
		}
	}

	setTraverseState(stepNo , isAllowed){
		for(var i = 0 ; i < this.kycState.length ; i++){
			if(this.kycState[i].stepNo == stepNo){
				this.kycState[i].isAllowed = isAllowed ;
			}
		}
	}

	getCurrentState(stepNo){
		return this.kycState.filter(obj=>obj.stepNo == stepNo)[0];
	}

	getStepNo(standardSlugGroup){
		return this.kycState.filter(obj=>obj.standardSlugGroup == standardSlugGroup)[0].stepNo;
	}

	///For child guards , check State (kycState) , passing in check slug & checking isAllowed attribute
}
