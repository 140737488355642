import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kys-document-verification',
  templateUrl: './kys-document-verification.component.html',
  styleUrls: ['./kys-document-verification.component.scss']
})
export class KysDocumentVerificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openModal(){
    const buttonModal = document.getElementById("openModalButton")
    console.log('buttonModal', buttonModal)
    buttonModal.click()
  }

}
