import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';
import { UiStateService } from 'src/app/services/ui-state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-kys-control-officers-feas',
  templateUrl: './kys-control-officers-feas.component.html',
  styleUrls: ['./kys-control-officers-feas.component.scss']
})
export class KysControlOfficersFeasComponent implements OnInit {

  checks: any;
  isSelfie;
  details:any;
  terms:boolean=true;
  checkbox = false;
  coFaceDone:boolean=false;
  coDigitalDone:boolean=false;
  coBasicDone:boolean=false;
  corporateDocs: any[] = [];
  finish: boolean = false;
  dashbordRedirectionURL = environment.URL.redirection_Url
  checkData: any;
  statusText: string;
  checksArray: any[] = [];
  checksCompleteDate: string;
  checksCount: { UNCHECK: 0; COMPLETED: 0; PASSED: 0; FAILED: 0; };
  activeTabs: string = 'coBasicDone';
  tabsArray = ['coBasicDone', 'coDigitalDone', 'coCorpDigitalDone', 'coFaceDone']
  onboardingStatus: any;
  isScrollLeft: boolean = false;
  isScrollRight: boolean = true;
  
  openModalPopup=null;
  docList: any;
  docRejected: number;
  docSubmitted: number;
  faceList: any;
  faceRejected: number;
  faceSubmitted: number;
  digitalUploadStatus: string;
  faceCheckStatus: string;
  isMobile: any;

  constructor(
    private _app: AppService,
    private _data: DataService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public uiStateService: UiStateService,
  ) { 
    this.isMobile = this.uiStateService.getIsMobileResolution();
    this.route.queryParams.subscribe(params => {
      const lang = params['language'];
      if(lang){
        translate.use(lang);
      }
      else{
        translate.use('en');
      }
    });
  }

  ngOnInit() {
    this._data.corporateBasicInfo.subscribe(res=>{
      this.coBasicDone=res
      this.getStatusWithText();
    });
    this._data.corporateDigiUpload.subscribe(res=>{
      this.coDigitalDone=res
      this.getStatusWithText();
    });
    this._data.corporateFaceCheck.subscribe(res=>{
      this.coFaceDone=res;
      this.getStatusWithText();
    });
    this._app.getStatus().subscribe((res: any) => {
      if(res.data.length){
        this._data.checksStatus(res.data);
        if(res.data.some((f) => f.kysTpeCheckStatus != 'UNCHECK')){
          this.terms = false
        }
        this.getStatusWithText();
      }
    });
    this.checksArray.push('coBasicDone');
    this._app.getAccountInfoforCorp().subscribe((data) => {
      if(data['data']){
        this.onboardingStatus = data['data'].status;
      }
    });
    this._app.getVideoChecks().subscribe(result => {
      if(result){
        const createdDate = result.tpe.createdDate;
        if(createdDate){
          const dateObject = new Date(createdDate);
          dateObject.setDate(dateObject.getDate() + 1);
          const formattedDate = new DatePipe('en-US').transform(dateObject, 'dd MMM, yyyy hh:mm a');
          this.checksCompleteDate = formattedDate;
        }
        this.checkData = result;
        this.checks = result["idvChecks"][0];
        this.corporateDocs = result.docChecks || [];
        this.isSelfie = ((this.checks['SELFIE'] && this.checks['SELFIE'].length > 0) || (this.checks['LIVELINESS'] && this.checks['LIVELINESS'].length > 0)) ? true : false;
        if(this.isSelfie){
          this.checksArray.push('coFaceDone');
        }
        else{
          this.tabsArray = this.tabsArray.filter((f) => f != 'coFaceDone');
        }
        if((this.checks.IDENTITY && this.checks.IDENTITY.length>0)){
          this.checksArray.push('coDigitalDone');
        }
        else{
          this.tabsArray = this.tabsArray.filter((f) => f != 'coDigitalDone');
        }
        if(this.corporateDocs.length){
          this.checksArray.push('coDigitalDone');
        }
        else{
          this.tabsArray = this.tabsArray.filter((f) => f != 'coCorpDigitalDone');
        }
        this.getStatusWithText();
      }
    })
    this._data.uboDetailsValue.subscribe(res=>{
      this.details=res;
    })
    this._data.uboCorpDocList.subscribe(res => {
      if(res){
        let list:any = res;
        this.docList= list.filter(e=>(e.docType==='IDENTITY' || e.docType==='ADDRESS' ));
        this.faceList = list.filter(e => e.docType==='LIVELINESS' || e.docType==='SELFIE');
        this.docRejected = 0;
        this.docSubmitted = 0;
        this.faceRejected = 0;
        this.faceSubmitted = 0;
        this.calcVerificationStatus(this.docList, 'digitalUploadStatus');
        this.calcVerificationStatus(this.faceList, 'faceCheckStatus');
        for(let i = 0; i < this.docList.length; i++){
          let ehQ = this.docList[i]
          if(ehQ.status === 'REJECTED'){
            this.docRejected ++
          }
          if(ehQ.status !== 'CREATED'){
            this.docSubmitted ++
          }
        }
        for(let i = 0; i < this.faceList.length; i++){
          let ehQ = this.faceList[i];
          if(ehQ.status === 'REJECTED'){
            this.faceRejected ++
          }
          if(ehQ.status !== 'CREATED'){
            this.faceSubmitted ++
          }
        }
      }
    })
  }

  calcVerificationStatus(list, statusName){
    if(list.every(eh => eh.status === 'VERIFIED')){
      this[statusName] = 'Approved';
    }
    else if(list.every(eh => eh.status === 'REJECTED')){
      this[statusName] = 'Rejected';
    }else{
      this[statusName] = 'Failed';
    }
    // console.log(list, this[statusName], statusName, 'statusName');
  }

  selectedActiveTabs(slug){
    this.activeTabs = slug;
  }

  getOutputData(event){
    const index = this.tabsArray.findIndex((f) => f == event);
    if(index < (this.tabsArray.length - 1)){
      this.activeTabs = this.tabsArray[index+1];
    }
  }

  scrollLeft() {
    var left = document.querySelector('.scroll-exceed-tabs');
    left.scrollBy(-150, 0);
    var scrollPercentage = 100 * left.scrollLeft / (left.scrollWidth - left.clientWidth);
    if (scrollPercentage == 0) {
      this.isScrollLeft = false;
      this.isScrollRight = true;
    }
    else if (scrollPercentage < 98) {
      this.isScrollRight = true;
    }
  }

  scrollRight() {
    var right = document.querySelector('.scroll-exceed-tabs');
    right.scrollBy(150, 0);
    var scrollPercentage = 100 * right.scrollLeft / (right.scrollWidth - right.clientWidth);
    if (scrollPercentage > 98) {
      this.isScrollLeft = true;
      this.isScrollRight = false;
    }
    else if (scrollPercentage > 0) {
      this.isScrollLeft = true;
    }
  }

  checkConditions(){
    if(this.checkData && ((this.checkData.docChecks && this.checkData.docChecks.length) || (this.checks && Object.entries(this.checks).length))){
      if(!this.coDigitalDone || (this.coDigitalDone['kysTpeCheckStatus'] == 'UNCHECK' || this.coDigitalDone['kysTpeCheckStatus'] == 'WARNING' || this.coDigitalDone['kysTpeCheckStatus'] == 'FAILED')  || !this.coBasicDone || (this.coBasicDone['kysTpeCheckStatus'] == 'UNCHECK' || this.coBasicDone['kysTpeCheckStatus'] == 'WARNING' || this.coBasicDone['kysTpeCheckStatus'] == 'FAILED')){
        if(this.coDigitalDone['kysTpeCheckStatus'] == 'UNCHECK') this.coDigitalDone['kysTpeCheckStatus'] = 'WARNING';
        if(this.coBasicDone['kysTpeCheckStatus'] == 'UNCHECK') this.coBasicDone['kysTpeCheckStatus'] = 'WARNING';
      }
      else{
        this.getFinalSubmitforCO();
      }
    }
    else{
      if(!this.coBasicDone || (this.coBasicDone['kysTpeCheckStatus'] == 'UNCHECK' || this.coBasicDone['kysTpeCheckStatus'] == 'WARNING' || this.coBasicDone['kysTpeCheckStatus'] == 'FAILED')){
        if(this.coBasicDone['kysTpeCheckStatus'] == 'UNCHECK') this.coBasicDone['kysTpeCheckStatus'] = 'WARNING';
      }
      else{
        this.getFinalSubmitforCO();
      }
    }
  }

  getFinalSubmitforCO() {
    let obj;
    this._data.changeLoaderVisibility(true);
    this._app.getFinalSubmitforCO(obj).pipe(finalize(() => this._data.changeLoaderVisibility(false)))
    .subscribe((response) => {
      this._data.toastrShow(`All details submitted Successfully`, 'success')
      const payload = {
        "message": 'Officer Details Submitted Successfully',
        "page": "Officer Submit",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("officerKysNo"), payload).subscribe();
      this.finish = true;
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Officer Submit",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("officerKysNo"), payload).subscribe();
      }
    );
  }

  getOuput(event){
    if(event == 'submitted'){
      this.finish = true;
    }
  }

  getStatusWithText(){
    this.checksCount = {
      UNCHECK: 0,
      COMPLETED: 0,
      PASSED: 0,
      FAILED: 0,
    }
    if(this.checksArray.length){
      for(let item of this.checksArray){
        if(this[item] && this[item]['kysTpeCheckStatus']){
          this.checksCount[this[item].kysTpeCheckStatus] += 1;
        }
      }
    }
    let status = '';
    if(this.checksCount['UNCHECK'] == this.checksArray.length){
      status = 'Open';
    }
    else if(this.checksCount['COMPLETED'] == this.checksArray.length){
      status = 'Completed';
    }
    else if(this.checksCount['PASSED'] == this.checksArray.length){
      status = 'CompletedWithVerified';
    }
    else if(this.checksCount['FAILED'] > 0){
      status = 'OnHold';
    }
    else{
      status = 'Processing';
    }
    this.statusText = status;
  }

}
