import { Component, OnInit , Input , ViewEncapsulation} from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {Svgs} from "../../utils/svgs/svgs"
declare const require: any;
@Component({
  selector: 'app-kyc-svg',
  templateUrl: './kyc-svg.component.html',
  styleUrls: ['./kyc-svg.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class KycSvgComponent implements OnInit {

	@Input() imageName : string = null ;
	@Input() heroColor : string = null ;
	@ViewChild('dataContainer') dataContainer: ElementRef;

	uiImage : any ;

	svg:SafeHtml;


	constructor(private sanitizer: DomSanitizer){
	}

	ngOnInit(){
		let image = Svgs.filter(obj=>obj.name == this.imageName)[0].path;
		this.uiImage = require(`!raw-loader!../../utils/svgs/${image}`);
		// console.log(this.uiImage);
		this.dataContainer.nativeElement.innerHTML = this.uiImage;
		// this.svg = this.sanitizer.bypassSecurityTrustHtml(this.uiImage);
		// setTimeout(()=>{
		// 	document.getElementsByTagName('svg')[0].style.fill = this.heroColor ;
		// },0)
	}

	ngAfterViewInit(){
		document.getElementsByTagName('svg')[0].style.fill = this.heroColor ;
	}

}
