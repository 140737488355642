import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-feas-loader",
  templateUrl: "./feas-loader.component.html",
  styleUrls: ["./feas-loader.component.scss"],
})
export class FeasLoaderComponent implements OnInit {
  @Input() isUIBlockingLoader: boolean;
  constructor() {}

  ngOnInit() {}
}
