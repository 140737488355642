// import { TranslateService } from './services/translate.service';
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { AppService } from "./services/app.service";
import { DataService } from "./services/data.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  user = {
    name: "Arthur",
    age: 42
  };

  themeData: any;
  token: any;

  //if Overriden is turned ON
  shouldOverride: any;

  //Universal UI blocker loader
  showLoader: boolean = false;

  //isToken
  isToken: boolean;

  constructor(
    public _app: AppService,
    public _data: DataService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public cdRef: ChangeDetectorRef
  ) {
    // console.log("I ran constructor:::::::::::: ", window.location.pathname);
    // this._data.setCookieObject("individualUserInfo", this.individualUserInfo);

    //get language from path and save in cookies
    //this.lang = window.location.pathname.split("/")[2];

    //get token and save it:::::::::::::::::: bello
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.token) {
        console.log(params.token);
        this.token = params.token;
        this.shouldOverride = params.shouldOverRide
          ? params.shouldOverRide
          : false;
        this._data.removeAllCookie(); //Remove All previous sessions
        this._data.setCookieByName("feas_token", this.token);
        this.getAllDetails();
        this.isToken = true;
        console.log("SHOULDOVERRIDE: ", this.shouldOverride);
      } else {
        this.isToken = false;
      }
    });

    //get all data
    // this.getAllDetails();

    // document.documentElement.style.setProperty('--color-primary', 'red');
    // document.documentElement.style.setProperty('--color-secondary', 'green');
  }

  async ngOnInit() {
    // this.getAllDetails();
    // if (this.lang) {
    //   this.router.navigate(["/startkyc"]);
    // }
    // console.log("I ran from ngOnInit");
    // this._data.loaderEvent.subscribe(loader => {
    //   this.showLoader = loader;
    //   console.log("fjhffhjkkk",loader)
    // });

    this._data.loaderInfo.subscribe((val) => {
      this.showLoader = val;
      this.cdRef.detectChanges();
  });
    
    let access_token = await this._data.getCookiesByName("feas_token");

    if (access_token) {
      this.getAllDetails();
    }
  }

  

  //get all details and set theme and language
  getAllDetails() {
    // console.log("I ran function");
    //this._data.changeLoaderVisibility(true);
    this._app.getClientConfig().subscribe(
      res => {
        //this._data.changeLoaderVisibility(false);
        // Theme setings //
        let themeObj = JSON.parse(res.data.feAsTheme);
        console.log(themeObj)
        this.themeData = JSON.parse(themeObj.themeConfig);
        console.log("COLOR: ", this.themeData["logo"]);
        this._data.setCookieByName(
          "logo",
          themeObj.logo ||
            "https://www.kychub.com/assets/img/kyc%20hub%201.png"
        );
        this._data.setCookieByName(
          "gettingStarted_image",
          themeObj.gettingStarted_image ||
            null
        );
        //set default color
        document.documentElement.style.setProperty(
          "--color-primary",
          this.themeData["color-primary"]
        );
        document.documentElement.style.setProperty(
          "--color-secondary",
          this.themeData["color-secondary"]
        );
      },
      err => {
        console.log(err);
      }
    );
  }

  // getLangObj() {
  //   this._app.getLangObj().subscribe(
  //     res => {
  //       this.langFromApi = JSON.parse(res.data.defaultMessage);
  //       console.log("::::::::::::LANG:::::::::::::::", this.langFromApi);
  //     },
  //     err => {
  //       console.log(err);
  //     }
  //   );
  // }
}
