import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { AppService } from '../../../services/app.service';
import { DatePipe } from '@angular/common';
import { 
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
  FormArray,
  NgForm,
  
} from "@angular/forms";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { finalize, first } from 'rxjs/operators';
import { CustomValidators } from "ng2-validation";
import { HttpErrorResponse } from '@angular/common/http';
import { style } from '@angular/animations';
import { element } from 'protractor';
import { netWorth, riskTolerance } from 'src/app/models/document';

@Component({
  selector: 'app-kys-control-officers',
  templateUrl: './kys-control-officers.component.html',
  styleUrls: ['./kys-control-officers.component.scss']
})
export class KysControlOfficersComponent implements OnInit {
  financialDataIndiForm: FormGroup;
  investmentDataIndiForm: FormGroup;
  netWorthArray: netWorth[] =[
    { key: 'Under $50,000' },
    { key: '$50,000 - $149,999' },
    { key: '$150,000 - $499,999' },
    { key: 'Over $5,000,000' },
];
taxBracketArray: netWorth[] =[
  { key: '0%-5%' },
  { key: '5%-10%' },
  { key: '10%-15%' },
  { key: '15%-above' },
];

riskToleranceArray: riskTolerance[] = [
    { key: 'Low' },
    { key: 'Moderate' },
    { key: 'Speculative' },
    { key: 'Aggressive' },
]

  constructor(
    private formBuilder: FormBuilder,
    private _data: DataService,
    private _app: AppService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.financialDataIndiForm = this.formBuilder.group({
        earningsInd: [''],
        liquidNetWorthInd: [''],
        taxBracketInd: [''],
        annualIncomeInd: [''],
        totalNetWorthInd: [''],
    });
    this.investmentDataIndiForm = this.formBuilder.group({
        capitalPreservationInd: [''],
        incomeInd: [''],
        riskToleranceInd: [''],
        timeHorizonInd: [''],
        stocksInd: [''],
        marginTradingInd: [''],
        mutulFundsInd: [''],
        capitalAppreciationInd: [''],
        speculationInd: [''],
        liquidityNeedsInd: [''],
        investmentKnowledgeInd: [''],
        optionTradingInd: [''],
        shortSalesInd: [''],
    });
  }

  getUpdateAccountInfo(){
    let accountForm = {
      accountNo: null,
      capitalPreservation: this.investmentDataIndiForm.value.capitalPreservationInd,
      capitalAppreciation: this.investmentDataIndiForm.value.capitalAppreciationInd,
      income: this.investmentDataIndiForm.value.incomeInd,
      specialization: this.investmentDataIndiForm.value.speculationInd,
      riskTemplate: this.investmentDataIndiForm.value.riskToleranceInd,
      liquidityNeeds: this.investmentDataIndiForm.value.liquidityNeedsInd,
      timeHorizon: this.investmentDataIndiForm.value.timeHorizonInd,
      investmentKnowledge: this.investmentDataIndiForm.value.investmentKnowledgeInd,
      stocks: this.investmentDataIndiForm.value.stocksInd,
      optionTrading: this.investmentDataIndiForm.value.optionTradingInd,
      marginTrading: this.investmentDataIndiForm.value.marginTradingInd,
      shortSales: this.investmentDataIndiForm.value.shortSalesInd,
      mutualFunds: this.investmentDataIndiForm.value.mutulFundsInd,
      earning: this.financialDataIndiForm.value.earningsInd,
      annualIncome: this.financialDataIndiForm.value.annualIncomeInd,
      liquidNetWorth: this.financialDataIndiForm.value.liquidNetWorthInd,
      totalNetWorth: this.financialDataIndiForm.value.totalNetWorthInd,
      taxBracket: this.financialDataIndiForm.value.taxBracketInd,
  };
  this._data.changeLoaderVisibility(true);
  this._app.getUpdateAccountInfo(accountForm)
  .pipe(finalize(()=> this._data.changeLoaderVisibility(false))).subscribe((response) => {
    this._data.toastrShow(`Information have been successfully updated`, 'success')
  })
  }

}
