import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { first } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
// import { pureObjectDef } from '@angular/core/src/view';

@Component({
  selector: 'app-add-details',
  templateUrl: './add-details.component.html',
  styleUrls: ['./add-details.component.scss']
})
export class AddDetailsComponent implements OnInit {

  @Input() name: string;
  @Input() email: string;
  @Input() name1: string;
  @Input() email1: string;
  @Input() name2: string;
  @Input() email2: string;
  @Input() UBOId;
  @Input() sharePercentUBO;
  @Input() officerTypeUBO;
  @Input() sharePercentDirector;
  @Input() officerTypeDirector;
  @Input() officerID1;
  @Input() officerID2;
  @Input() document;
  @Input() uboCountryCode;


  @Output()
  close = new EventEmitter();
  @Output() public isDoc = new EventEmitter<boolean>();
  reportUserName: string;
  individualInfoByAccountIdData: any;
  dob: any;
  addMoreDetailsForm: FormGroup;
  addMoreDetailsOfDirectorsForm: FormGroup;
  addMoreDetailsOfficersForm: FormGroup;
  formDataDetails: any;
  formDataDetails1: any;
  formDataDetails2: any;
  countries: any;
  param1: string;
  param2: string;
  param3: string;
  remoteValid:boolean=false;
  jurisdictions: any[] = [];
  maturityStatus:any[] = [
    {key:'Single', value:'SINGLE'},
    {key:'Married', value:'MARRIED'},
    {key:'Widowed', value:'WIDOWED'},
    {key:'Divorced', value:'DIVORCED'},
    {key:'Separated', value:'SEPARATED'},
  ]
  currentStatus:any[] = [
    {key: 'Active', value: 'Active'},
    {key: 'Dormant', value: 'Dormant'},
    {key: 'Active in Progress', value: 'Active in Progress'},
    {key: 'Under liquidation', value: 'Under liquidation'},
    {key: 'Under process of Striking Off', value: 'Under process of Striking Off'},
    {key: 'Strike off', value: 'Strike off'},
    {key: 'Amalgamated', value: 'Amalgamated'},
    {key: 'Liquidated', value: 'Liquidated'},
    {key: 'Dissolved', value: 'Dissolved'},
    {key: 'Converted to LLP', value: 'Converted to LLP'},
    {key: 'Converted to LLP and dissolved', value: 'Converted to LLP and dissolved'}
  ]

  currentStatusInd:any[] = [
    {key: 'Active', value: 'Active'},
    {key: 'Inactive', value: 'Inactive'},
  ]

  constructor(

    private formBuilder: FormBuilder,
    private _data: DataService,
    private _app: AppService,
    public router: Router,
    public route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.route.queryParams.subscribe(params => {
      this.param1 = params['controlOfficerType'];
    });

  }

  ngOnInit() {

    this.isDoc.emit(true);
    this.getAllCountries()

    this.addMoreDetailsForm = this.formBuilder.group({
      lastName: [null,[
        Validators.pattern(/^[a-zA-Z ]+$/)
      ]],
      dob: [null],
      maritalStatus: [null],
      phoneNo: [null,[
        Validators.pattern(/^[0-9]\d*(\.\d+)?$/)
      ]],
      address: [null],
      ssn: [null],
      taxID: [null],
      percentage: [null],
      status: [null],
      designation: [null],
      firstName: [this.name],
      email: [this.email,[
        Validators.pattern(/@/)
      ]],
      sharePercentUBO: [this.sharePercentUBO],
      officerTypeUBO: [this.officerTypeUBO],
      citizenship: [this.uboCountryCode, Validators.required],
      jurisdictionCode: [null],
      companyId: [null],
      companyType: [null],
      incorporationDate: [null],
      branchCount: [null],
      parentCompanyName: [null],
      parentCompanyID: [null],
      streetAddress: [null],
      region: [null],
      postalCode: [null],
    });
    this.addMoreDetailsOfDirectorsForm = this.formBuilder.group({
      lastName: [null],
      dob: [null],
      maritalStatus: [null],
      phoneNo: [null],
      address: [null],
      ssn: [null],
      taxID: [null],
      percentage: [null],
      status: [null],
      designation: [null],
      firstName: [this.name1],
      email: [this.email1],
      citizenship: ['', Validators.required],
      jurisdictionCode: [null],
      sharePercentUBO: [this.sharePercentDirector],
      officerTypeUBO: [this.officerTypeDirector],
      companyId: [null],
      companyType: [null],
      incorporationDate: [null],
      branchCount: [null],
      parentCompanyName: [null],
      parentCompanyID: [null],
      streetAddress: [null],
      region: [null],
      postalCode: [null],
    });
    this.addMoreDetailsOfficersForm = this.formBuilder.group({
      lastName: [null],
      dob: [null],
      maritalStatus: [null],
      phoneNo: [null],
      address: [null],
      ssn: [null],
      taxID: [null],
      percentage: [null],
      status: [null],
      designation: [null],
      firstName: [this.name2],
      email: [this.email2],
      citizenship: ['', Validators.required]
    });
  }

  onDateInputKeydown(event: KeyboardEvent) {
    event.preventDefault(); // Prevent any key input
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.addMoreDetailsForm.controls;
  }

  closeInfo($event: MouseEvent) {
    // $event.preventDefault();
    this.close.emit(this.document);
  }



  // For UBO

  getMoreDetails() {
    this.remoteValid=true
    let addDetailsForm = {
      "firstName": this.name,
      "sharePercentage": parseFloat(this.addMoreDetailsForm.value.sharePercentUBO),
      "controlOfficerEntityType": this.officerTypeUBO,
      "lastName": this.addMoreDetailsForm.value.lastName,
      "controlOfficerType": this.document,
      "maritalStatus": this.addMoreDetailsForm.value.maritalStatus,
      "dob": this.addMoreDetailsForm.value.dob,
      "phoneNumber": parseInt(this.addMoreDetailsForm.value.phoneNo),
      "email": this.addMoreDetailsForm.value.email =="" ? null : this.addMoreDetailsForm.value.email,
      "residenceAddress": this.addMoreDetailsForm.value.address,
      "ssn": this.addMoreDetailsForm.value.ssn,
      "taxId": this.addMoreDetailsForm.value.taxID,
      "percentageOfOwnership": this.addMoreDetailsForm.value.percentage,
      "currentStatus": this.addMoreDetailsForm.value.status,
      "designation": this.addMoreDetailsForm.value.designation,
      "citizenshipCountryCode": this.uboCountryCode,
      "companyId": this.addMoreDetailsForm.value.companyId,
      "companyType": this.addMoreDetailsForm.value.companyType,
      "incorporationDate": this.addMoreDetailsForm.value.incorporationDate,
      "branchCount": this.addMoreDetailsForm.value.branchCount,
      "parentCompanyName": this.addMoreDetailsForm.value.parentCompanyName,
      "parentCompanyID": this.addMoreDetailsForm.value.parentCompanyID,
      "streetAddress": this.addMoreDetailsForm.value.streetAddress,
      "region": this.addMoreDetailsForm.value.region,
      "postalCode": this.addMoreDetailsForm.value.postalCode,
      "jurisdictionCode": this.addMoreDetailsForm.value.jurisdictionCode,
      "id": this.UBOId
    }
    console.log("User Form: ", addDetailsForm);
    this.getAddOfficersDetails(addDetailsForm)
  }

  getAddOfficersDetails(obj) {
    this._app.getMoreDetailsOfficers(obj).pipe(first()).subscribe((data) => {
      this.formDataDetails = data["data"]
      this._data.toastrShow("Details have been submitted", `success`)
    })
  }

  getCountryCode(forms){
    const countryCode = this[forms].value.citizenship
    this._app.getJurisdictionData(countryCode).subscribe((res) => {
      this.jurisdictions = res || [];
      if(res.length){
        this[forms].get('jurisdictionCode').setValue(res[0].code);
      }
    })
  }

  // For DIRECTORS

  getMoreDetails1() {
    this.remoteValid=true
    let addDetailsForm = {
      "firstName": this.name1,
      "sharePercentage": parseFloat(this.addMoreDetailsOfDirectorsForm.value.sharePercentUBO),
      "controlOfficerEntityType": this.officerTypeDirector,
      "lastName": this.addMoreDetailsOfDirectorsForm.value.lastName,
      "controlOfficerType": this.param1,
      "maritalStatus": this.addMoreDetailsOfDirectorsForm.value.maritalStatus,
      "dob": this.addMoreDetailsOfDirectorsForm.value.dob,
      "phoneNumber": parseInt(this.addMoreDetailsOfDirectorsForm.value.phoneNo),
      "email": this.email1,
      "residenceAddress": this.addMoreDetailsOfDirectorsForm.value.address,
      "ssn": this.addMoreDetailsOfDirectorsForm.value.ssn,
      "taxId": this.addMoreDetailsOfDirectorsForm.value.taxID,
      "percentageOfOwnership": this.addMoreDetailsOfDirectorsForm.value.percentage,
      "currentStatus": this.addMoreDetailsOfDirectorsForm.value.status,
      "designation": this.addMoreDetailsOfDirectorsForm.value.designation,
      "citizenshipCountryCode": this.addMoreDetailsOfDirectorsForm.value.citizenship,
      "companyId": this.addMoreDetailsOfDirectorsForm.value.companyId,
      "companyType": this.addMoreDetailsOfDirectorsForm.value.companyType,
      "incorporationDate": this.addMoreDetailsOfDirectorsForm.value.incorporationDate,
      "branchCount": this.addMoreDetailsOfDirectorsForm.value.branchCount,
      "parentCompanyName": this.addMoreDetailsOfDirectorsForm.value.parentCompanyName,
      "parentCompanyID": this.addMoreDetailsOfDirectorsForm.value.parentCompanyID,
      "streetAddress": this.addMoreDetailsOfDirectorsForm.value.streetAddress,
      "region": this.addMoreDetailsOfDirectorsForm.value.region,
      "postalCode": this.addMoreDetailsOfDirectorsForm.value.postalCode,
      "jurisdictionCode": this.addMoreDetailsOfDirectorsForm.value.jurisdictionCode,
      "id": this.officerID1
    }
    console.log("User Form: ", addDetailsForm);
    this.getAddOfficersDetails1(addDetailsForm)
  }

  getAddOfficersDetails1(obj) {
    this._app.getMoreDetailsOfficers(obj).pipe(first()).subscribe((data) => {
      this.formDataDetails1 = data["data"]
      
      this._data.toastrShow("Details have been submitted", `success`)
    })
  }

  // For OFFICER

  getMoreDetails2() {
    this.remoteValid=true
    let addDetailsForm = {
      "firstName": this.name2,
      "lastName": this.addMoreDetailsOfficersForm.value.lastName,
      "controlOfficerType": this.param1,
      "maritalStatus": this.addMoreDetailsOfficersForm.value.maritalStatus,
      "dob": this.addMoreDetailsOfficersForm.value.dob,
      "phoneNumber": parseInt(this.addMoreDetailsOfficersForm.value.phoneNo),
      "email": this.email2,
      "residenceAddress": this.addMoreDetailsOfficersForm.value.address,
      "ssn": this.addMoreDetailsOfficersForm.value.ssn,
      "taxId": this.addMoreDetailsOfficersForm.value.taxID,
      "percentageOfOwnership": this.addMoreDetailsOfficersForm.value.percentage,
      "currentStatus": this.addMoreDetailsOfficersForm.value.status,
      "designation": this.addMoreDetailsOfficersForm.value.designation,
      "citizenshipCountryCode": this.addMoreDetailsOfficersForm.value.citizenship
    }
    console.log("User Form: ", addDetailsForm);
    this.getAddOfficersDetails2(addDetailsForm)
  }

  getAddOfficersDetails2(obj) {
    this._app.getMoreDetailsOfficers(obj).pipe(first()).subscribe((data) => {
      this.formDataDetails2 = data["data"]
      
      this._data.toastrShow("Details have been submitted", `success`)
    })
  }

  // Get All Couontries
  getAllCountries() {
    this._app
      .getAllCountries()

      .subscribe(
        (res) => {
          this.countries = res['data'];
          console.log(res['data']);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  // Send Remote Link
  sendRemoteLink() {
    if (this.document === "UBO") {
      var id = this.UBOId
    } else if (this.param1 === "DIRECTOR") {
      var id = this.officerID1
    } else if (this.param1 === "OFFICER") {
      var id = this.officerID2
    }
    let obj;
    this._data.changeLoaderVisibility(true);
    this._app.startKYC(id).subscribe((res) => {
      let event: MouseEvent;
      this.closeInfo(event);
      // this._app.sendRemoteLink(id, obj).subscribe((response) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow(`Remote link has been sent`, 'success');
      const payload = {
        "message": 'Remote Link has been sent to the ' + res['data'].tpe.kysNo,
        "page": "Control Officer Remote Link",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      // });
    },
    (err: HttpErrorResponse) => {
      const payload = {
        "message": err,
        "page": "Control Officer Remote Link",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    });
  }

}


