import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NewKycModalComponent } from '../../../shared/new-kyc-modal/new-kyc-modal.component';

export interface User {
  name: string;
  email: string;
  sharePercentage: string,
  controlOfficerEntityType: string,
  completed: boolean;
}


@Component({
  selector: 'app-corp-control-officers',
  templateUrl: './corp-control-officers.component.html',
  styleUrls: ['./corp-control-officers.component.scss']
})
export class CorpControlOfficersComponent implements OnInit, OnChanges {
  uboUsers: User[] = [];
  uboList = [];
  directors: User[] = [];
  directorList = []
  complianceOfficer: User[] = [];
  coList = [];
  countries: any;
  nameUBO: any;
  emailUBO: any;
  officerTypeUBO: any;
  sharePercentUBO: any;
  officerID1: any;
  selectedDocument: any;
  officerID2: any;
  controlOfficerType: any;
  emailDirector: any;
  nameDirector: any;
  officerTypeDirector: any;
  sharePercentDirector: any;
  nameOfficer: any;
  emailOfficer: any;
  isTrue: any = "";
  UBOId: any;
  officerTypeArray = [
    { heading: 'INDIVIDUAL', slug: 'INDIVIDUAL' },
    { heading: 'CORPORATE', slug: 'INDIVIDUAL CORPORATE' },
  ];
  tabs: any[] = [];
  controlOfficerEntityType: any;
  @Input() checks: any;
  @Input() isUboDataChange?: boolean;
  public bsModalRef: BsModalRef;
  activeTabs: string = "UBO";
  uboCountryCode: any;
  directorsDone: any;
  openedModal: any;
  constructor(
    private formBuilder: FormBuilder,
    private _data: DataService,
    private _app: AppService,
    public _router: Router,
    public route: ActivatedRoute,
    private modalService: BsModalService,
    private http: HttpClient,
    private toastrService: ToastrService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.checks && changes.checks.currentValue) {
      this.checks = changes.checks.currentValue;
      if (this.checks) {
        if (this.checks.uboCheck) {
          this.tabs.push('UBO');
          this.activeTabs = 'UBO';
        }
        // if (this.checks.directorCheck) {
        //   this.tabs.push('Director');
        //   if (!this.checks.uboCheck) this.activeTabs = 'Director';
        // }
      }
    }
    this.isUboDataChange = changes.isUboDataChange.currentValue;
    if (this.isUboDataChange) {
      this.loadUbo();
      this.loadDirector();
      this.loadComplianceOfficer();
    }
  }

  selectedTabs(slug) {
    this.activeTabs = slug;
  }

  ngOnInit() {
    this.getAllCountries();
    console.log("checks: ", this.checks)
    this.loadUbo();
    this.loadDirector();
    this.loadComplianceOfficer();
    this._data.corporateDirectorInfo.subscribe(res => {
      this.directorsDone = res;
    })
  }

    // Get All Couontries
    getAllCountries() {
      this._app
        .getAllCountries()
  
        .subscribe(
          (res) => {
            this.countries = res['data'];
            console.log(res['data']);
          },
          (err) => {
            console.log(err);
          }
        );
    }

  isDoc(val) {
    this.isTrue = val
  }

  close() {
    this.selectedDocument = null
  }

  onClose(event) {
    this.selectedDocument = event;
    if (this.selectedDocument === "UBO") {
      this.loadUbo();
      const user = this.uboUsers.find(({ email }) => email === this.emailUBO);
      user.completed = true;
    }
    if (this.selectedDocument === "DIRECTOR") {
      this.loadDirector()
      const user = this.directors.find(({ email }) => email === this.emailDirector);
      user.completed = true;
    }

    if (this.selectedDocument === "OFFICER") {
      this.loadComplianceOfficer()
      const user = this.complianceOfficer.find(({ email }) => email === this.emailOfficer);
      user.completed = true;
    }
    this.selectedDocument = null;
  }

  loadUbo() {
    this._app.getDirectors().subscribe((ubos: any[]) => {
      this.uboList = ubos;
      const result = [];
      const groupedCompanies = {};
      for (const obj of ubos) {
        if (obj.companyName === null) {
            result.push(obj);
        } 
        else {
          if (groupedCompanies[obj.companyName]) {
              groupedCompanies[obj.companyName].push(obj);
          } 
          else {
              groupedCompanies[obj.companyName] = [obj];
          }
        }
      }
      for(const companyName in groupedCompanies){
        const obj = { ...groupedCompanies[companyName][0], controlOfficerEntityType: 'INDIVIDUAL CORPORATE' }
        result.push({ ...obj, uboArray: groupedCompanies[companyName] })
      }
      this.uboUsers = result.map((e) => ({...e, name: e.firstName, completed: true, }));
      if (!ubos.length) {
        if(this.directorsDone){
          this.directorsDone = { ...this.directorsDone, kysTpeCheckStatus: 'UNCHECK' }
        }
        let status = this.directorsDone
        this._app.sendStatus(status).subscribe(() => {
          this._app.getStatus().subscribe(res => {
            this._data.checksStatus(res['data'])
          })
        })
        this.uboUsers = [{ name: '', email: '', sharePercentage: '', controlOfficerEntityType: '', completed: false }];
      }
    });
  }

  editStakesOfCompany(item){
    const payload = {
      officers: item.uboArray.map((e) => ({ officerId: e.id, companySharePercentage: Number(item.companySharePercentage) }))
    }
    this._data.changeLoaderVisibility(true);
    this._app.editStakes(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
      this._data.toastrShow("Details have been updated", `success`);
      item.editStakes = null;
      this.loadUbo();
    })
  }

  loadDirector() {
    this._app.getDirectors().subscribe((directors) => {
      this.directorList = directors;
      this._data.directorDataVal(this.directorList);
      this.directors = directors.map((e) => ({ ...e, name: e.firstName, completed: true }));
      if (!directors.length) {
        this.directors = [{ name: '', email: '', sharePercentage: '', controlOfficerEntityType: '', completed: false }];
      }
    });
  }

  loadComplianceOfficer() {
    this._app.getComplianceOfficer().subscribe((complianceOfficers) => {
      this.coList = complianceOfficers;
      this.complianceOfficer = complianceOfficers.map((e) => ({ ...e, name: e.firstName, completed: true }));
      if (!complianceOfficers.length) {
        this.complianceOfficer = [{ name: '', email: '', sharePercentage: '', controlOfficerEntityType: '', completed: false }];
      }
    });
  }

  addUbo(name: string, email: string, citizenshipCountryCode, row) {
    const payload = {
      "firstName": name,
      "lastName": null,
      "controlOfficerType": "DIRECTOR",
      "maritalStatus": null,
      "dob": null,
      "phoneNumber": null,
      "email": email,
      "residenceAddress": null,
      "ssn": null,
      "taxId": null,
      "percentageOfOwnership": null,
      "currentStatus": null,
      "designation": null,
      "controlOfficerEntityType": 'INDIVIDUAL', // row.controlOfficerEntityType,
      "sharePercentage": 0,
      "companyName": row.companyName,
      "companySharePercentage": 0,
      "citizenshipCountryCode": citizenshipCountryCode
    };

    this._app.getDetailsOfficers(payload).subscribe((data) => {
      this.toastrService.success('DIRECTOR added');
      this.controlOfficerEntityType = null;
      this.controlOfficerType = data["data"].controlOfficerType
      this.nameUBO = data["data"].firstName
      this.emailUBO = data["data"].email;
      this.uboCountryCode = data['data'].citizenshipCountryCode
      this.sharePercentUBO = data["data"].sharePercentage
      this.officerTypeUBO = data["data"].controlOfficerEntityType
      this.UBOId = data["data"].id;
      // this._router.navigate([], { queryParams: { 'controlOfficerType': 'UBO' } });
      this.selectedDocument = "UBO";
      row.name = name;
      row.email = email;
      const payload = {
        "message": name + ' has been added as DIRECTOR',
        "page": "Control Officer Add",
        "geoLocation": null
      }
      if(this.directorsDone){
        this.directorsDone = { ...this.directorsDone, kysTpeCheckStatus: 'COMPLETED' }
      }
      let status = this.directorsDone
      this._app.sendStatus(status).subscribe(() => {
        this._app.getStatus().subscribe(res => {
          this._data.checksStatus(res['data']);
          this.loadDirector();
        })
      })
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Control Officer Add",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    )
  }

  addDirector(name: string, email: string, sharePercentage: string, row: User) {
    let payload = {
      "firstName": name,
      "lastName": null,
      "controlOfficerType": "DIRECTOR",
      "maritalStatus": null,
      "dob": null,
      "phoneNumber": null,
      "email": email,
      "residenceAddress": null,
      "ssn": null,
      "taxId": null,
      "percentageOfOwnership": null,
      "currentStatus": null,
      "designation": null,
      "citizenshipCountryCode": "",
      "controlOfficerEntityType": row.controlOfficerEntityType,
      "sharePercentage": parseFloat(sharePercentage)
    }
    console.log("User Form: ", payload);
    this._app.getDetailsOfficers(payload).subscribe((data) => {
      this.toastrService.success('Director added');
      this.controlOfficerEntityType = null;
      this.officerID1 = data["data"].id
      this.nameDirector = data["data"].firstName
      this.emailDirector = data["data"].email
      this.sharePercentDirector = data["data"].sharePercentage
      this.officerTypeDirector = data["data"].controlOfficerEntityType
      this._router.navigate([], { queryParams: { 'controlOfficerType': 'DIRECTOR' } });
      this.selectedDocument = "DIRECTOR";
      row.name = name;
      row.email = email;
      const payload = {
        "message": name + ' has been added as DIRECTOR',
        "page": "Control Officer Add",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Control Officer Add",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    )
  }


  // For OFFICER
  addComplianceOfficer(name: string, email: string, row: User) {
    const payload = {
      "firstName": name,
      "lastName": null,
      "controlOfficerType": "OFFICER",
      "maritalStatus": null,
      "dob": null,
      "phoneNumber": null,
      "email": email,
      "residenceAddress": null,
      "ssn": null,
      "taxId": null,
      "percentageOfOwnership": null,
      "currentStatus": null,
      "designation": null,
      "citizenshipCountryCode": ""
    };
    this._app.getDetailsOfficers(payload).subscribe((data) => {
      this.toastrService.success('Compliance officer added');
      this.officerID2 = data["data"].id
      this.nameOfficer = data["data"].firstName
      this.emailOfficer = data["data"].email

      this._router.navigate([], { queryParams: { 'controlOfficerType': 'OFFICER' } });
      this.selectedDocument = "OFFICER";
      row.name = name;
      row.email = email;
      const payload = {
        "message": name + ' has been added as OFFICER',
        "page": "Control Officer Add",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Control Officer Add",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    )
  }

  deleteData(item){
    this.openedModal = {
      modals: 'delete-data',
      data: item
    }
  }
  
  callDeleteDataAPI(){
    this.openedModal.modals = null;
    this._data.changeLoaderVisibility(true);
    this._app.deleteControlOfficers(this.openedModal.data.id).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
      this.loadUbo();
      const msg = `${this.openedModal.data.name} has been deleted`;
      this._data.toastrShow(msg, 'info');
      const payload = {
        "message": msg,
        "page": "Control Officer Delete",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      this.openedModal.data = null;
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Control Officer Delete",
          "geoLocation": null
        }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    })
  }

  getOfficerEntityType(item){
    if(item.controlOfficerEntityType == 'INDIVIDUAL CORPORATE'){
      this.addCompanyUboEntry(item);
    }
    else{
      delete item.uboArray
    }
  }

  addComplianceEntry() {
    this.complianceOfficer.push({ name: '', email: '', sharePercentage: '', controlOfficerEntityType: '', completed: false })
  }

  addDirectorEntry() {
    this.directors.push({ name: '', email: '', sharePercentage: '', controlOfficerEntityType: '', completed: false })
  }

  addUboEntry() {
    this.uboUsers.push({ name: '', email: '', sharePercentage: '', controlOfficerEntityType: '', completed: false })
  }

  addCompanyUboEntry(item){
    if(item.uboArray){
      item['uboArray'].push({firstName: '', email: '', sharePercentage: '', controlOfficerEntityType: '', tpe: null})
    }
    else{
      item['uboArray'] = [{firstName: '', email: '', sharePercentage: '', controlOfficerEntityType: '', tpe: null}]
    }
  }

  getFinalSubmit() {
    let abc;
    this._data.changeLoaderVisibility(true);
    this._app.getFinalSubmit(abc).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((response) => {
      this._data.toastrShow(`All details submitted Successfully`, 'success');
      const payload = {
        "message": 'Company details submitted Successfully',
        "page": "Company Final Submit",
        "geoLocation": null
      }
      this._data.finishVal(true)
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Company Final Submit",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    );
  }

  viewDetails(item, type: string) {
    let searchItem;
    if (type == 'ubo') {
      this.uboList.forEach(ubo => {
        if (ubo.email == item.email) {
          searchItem = ubo;
        }
      })
    }
    else if (type == 'director') {
      this.directorList.forEach(director => {
        if (director.email == item.email) {
          searchItem = director;
        }
      })
    }
    else {
      this.coList.forEach(co => {
        if (co.email == item.email) {
          searchItem = co;
        }
      })
    }
    const initialState = {
      type: "officer-view-edit-page",
      officerItem: searchItem,
      viewType: 'view'
    }
    this.bsModalRef = this.modalService.show(NewKycModalComponent, { initialState, class: 'modal-lg new-modal-as-dialogue' });
    this.bsModalRef.content.onClose.subscribe((result) => {
      if (type == 'ubo') this.loadUbo();
      if (type == 'director') this.loadDirector();
    })
  }

  checkLinkButton(item) {
    if (item.controlOfficerEntityType == 'CORPORATE') {
      return (item.citizenshipCountry && item.jurisdictionCode) ? false : true;
    }
    else {
      return item.citizenshipCountry ? false : true;
    }
  }

  // Send Remote Link
  sendRemoteLink(item) {
    this._data.changeLoaderVisibility(true);
    this._app.startKYC(item.id).subscribe((res) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow(`Remote link has been sent`, 'success');
      const payload = {
        "message": 'Remote Link has been sent to the ' + res['data'].tpe.kysNo,
        "page": "Control Officer Remote Link",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Control Officer Remote Link",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      });
  }

}
