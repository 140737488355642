import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';
import { NewKycModalComponent } from 'src/app/shared/new-kyc-modal/new-kyc-modal.component';

@Component({
  selector: 'app-add-control-officers',
  templateUrl: './add-control-officers.component.html',
  styleUrls: ['./add-control-officers.component.scss']
})
export class AddControlOfficersComponent implements OnChanges {
  @Input() checks: any;
  @Input() isUboDataChange?: boolean;
  @Input() companyName;
  @Input() pageScroll:any;
  uboUsers: any;
  public bsModalRef: BsModalRef;
  officerTypeArray = [
    { heading: 'INDIVIDUAL', slug: 'INDIVIDUAL' },
    { heading: 'CORPORATE', slug: 'INDIVIDUAL CORPORATE' },
  ];
  uboAddData: any = null;
  emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  countries: any[] = [];
  uboDone: any;
  openedModal: any;

  constructor(
    private _app: AppService,
    private _data: DataService,
    private modalService: BsModalService,
    private toastrService: ToastrService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    window.scrollTo(0, 0);
    if (changes.checks && changes.checks.currentValue) {
      this.checks = changes.checks.currentValue;
    }
    if (changes.companyName && changes.companyName.currentValue) {
      this.companyName = changes.companyName.currentValue;
    }
    if (changes.isUboDataChange && changes.isUboDataChange.currentValue != null && changes.isUboDataChange.currentValue != undefined) {
      this.isUboDataChange = changes.isUboDataChange.currentValue;
      if(this.isUboDataChange){
        this.getUboList();
      }
    }
  }

  ngOnInit() {
    this.getAllCountries();
    this.getUboList();
    this._data.corporateUboInfo.subscribe(res => {
      this.uboDone = res;
    })
  }

  // Get All Couontries
  getAllCountries() {
    this._app.getAllCountries().subscribe(
      (res) => {
        this.countries = res['data'];
      },
      (err) => {
      }
    );
  }

  getUboList() {
    this._data.changeLoaderVisibility(true);
    this._app.getUBONewList().pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      this.uboUsers = res;
      if(this.uboUsers && this.uboUsers.officerList) this.uboUsers.officerList = [...this.uboUsers.officerList].reverse();
      if(this.uboUsers && this.uboUsers.childCompanyOfficerList) this.uboUsers.childCompanyOfficerList = [...this.uboUsers.childCompanyOfficerList].reverse()
      this._data.uboDataVal(this.uboUsers);
      if(this.uboUsers){
        this.manipulateData(this.uboUsers);
      }
      else{
        if(this.uboDone){
          this.uboDone = { ...this.uboDone, kysTpeCheckStatus: 'UNCHECK' }
        }
        let status = this.uboDone
        this._app.sendStatus(status).subscribe(() => {
          this._app.getStatus().subscribe(res => {
            this._data.checksStatus(res['data'])
          })
        })
        const obj = { officerList: [{firstName: null, email: null, sharePercentage: null, controlOfficerEntityType: 'INDIVIDUAL',}], childCompanyOfficerList: [] };
        this.uboUsers = obj;
      }
    })
  }

  manipulateData(data, cardState: boolean = true){
    if(data.childCompanyOfficerList && data.childCompanyOfficerList.length){
      for(let item of data.childCompanyOfficerList){
        if(item.officerList && item.officerList.length){
          item['parentNodeCompanyName'] = item.officerList[0].parentNodeCompanyName;
          item['cardState'] = cardState;
        }
        this.manipulateData(item, !cardState);
      }
    }
  }

  addOfficer(item){
    const obj = { firstName: null, email: null, sharePercentage: null, controlOfficerEntityType: 'INDIVIDUAL', citizenshipCountryCode: null };
    item.officerList.unshift(obj);
  }

  getOfficerEntityType(item){
    if(item.controlOfficerEntityType == 'INDIVIDUAL CORPORATE'){
      const pushObj = { companyName: '', companyStack: null, parentNodeCompanyName: this.companyName, controlOfficerEntityType: 'INDIVIDUAL CORPORATE', entityTypeDisabled: false, officerList: [{firstName: null, email: null, sharePercentage: null, controlOfficerEntityType: 'INDIVIDUAL', officerEntityType: true, citizenshipCountryCode: null}] };
      this.uboUsers.childCompanyOfficerList.unshift(pushObj);
      if(!item.createdDate){
        const index = this.uboUsers.officerList.findIndex((f) => f.firstName == null);
        this.uboUsers.officerList.splice(index, 1);
      }
    }
  }

  getOutputData(event){
    if(event == 'loadAPI'){
      this.getUboList();
    }
    else if(event == 'backFromDetails'){
      this.uboAddData = null;
      this.getUboList();
    }
    else if(event == 'INDIVIDUAL'){
      const index = this.uboUsers.childCompanyOfficerList.findIndex((f) => f.companyName == '');
      this.uboUsers.childCompanyOfficerList.splice(index, 1);
      const obj = {firstName: null, email: null, sharePercentage: null, controlOfficerEntityType: 'INDIVIDUAL', officerEntityType: false, citizenshipCountryCode: null};
      this.uboUsers.officerList.push(obj);
    }
    else if(event && event.length){
      if(event[0] == 'uboAddDetails'){
        this.uboAddData = event[1];
      }
    }
  }

  addOfficerButtonDisable(inputData){
    if(inputData && inputData.officerList.every((f) => f.createdDate)){
      return false;
    }
    else{
      return true;
    }
  }

  addDetails(item, isRemoteLink, uboDetails?){
    const parentNodeCompanyName = this.companyName;
    const payload = {
      firstName: item.firstName,
      controlOfficerType: 'UBO',
      email: item.email,
      controlOfficerEntityType: item.controlOfficerEntityType,
      sharePercentage: Number(item.sharePercentage),
      companyName: null,
      companySharePercentage: null,
      parentNodeCompanyName: parentNodeCompanyName,
      citizenshipCountryCode: item.citizenshipCountryCode
    }
    this._data.changeLoaderVisibility(true);
    this._app.getDetailsOfficers(payload).pipe((finalize(() => this._data.changeLoaderVisibility(false)))).subscribe((data) => {
      this.toastrService.success('UBO added');
      if(data.data){
        if(isRemoteLink){
          this.sendRemoteLink(data.data);
          this.getUboList();
        }
        else if(uboDetails){
          this.uboAddData = data.data;
        }
        else{
          this.getUboList();
        }
      }
      const payload = {
        "message": item.firstName + ' has been added as UBO',
        "page": "Control Officer Add",
        "geoLocation": null
      }
      if(this.uboDone){
        this.uboDone = { ...this.uboDone, kysTpeCheckStatus: 'COMPLETED' }
      }
      let status = this.uboDone
      this._app.sendStatus(status).subscribe(() => {
        this._app.getStatus().subscribe(res => {
          this._data.checksStatus(res['data'])
        })
      })
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Control Officer Add",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      }
    );
  }

  viewDetails(item){
    const initialState = {
      type: "officer-view-edit-page",
      officerItem: item,
      viewType: 'view'
    }
    this.bsModalRef = this.modalService.show(NewKycModalComponent, { initialState, class: 'modal-lg new-modal-as-dialogue' });
    this.bsModalRef.content.onClose.subscribe((result) => {
      this.getUboList();
    })
  }

  checkLinkForInitateButton(item){
    if (item.controlOfficerEntityType == 'CORPORATE') {
      return (item.citizenshipCountry && item.jurisdictionCode) ? false : true;
    }
    else {
      if(item.firstName && this.emailPattern.test(item.email) && item.sharePercentage && item.citizenshipCountryCode && item.controlOfficerEntityType){
        return false;
      }
      else{
        return true;
      }
    }
  }

  checkLinkButton(item) {
    if (item.controlOfficerEntityType == 'CORPORATE') {
      return (item.citizenshipCountry && item.jurisdictionCode) ? false : true;
    }
    else {
      return item.citizenshipCountry ? false : true;
    }
  }

  // Send Remote Link
  sendRemoteLink(item) {
    this._data.changeLoaderVisibility(true);
    this._app.startKYC(item.id).subscribe((res) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow(`Remote link has been sent`, 'success');
      const payload = {
        "message": 'Remote Link has been sent to the ' + res['data'].tpe.kysNo,
        "page": "Control Officer Remote Link",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    },
      (err: HttpErrorResponse) => {
        const payload = { 
          "message": err,
          "page": "Control Officer Remote Link",
          "geoLocation": null
        }
        this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      });
  }

  deleteData(item){
    this.openedModal = {
      modals: 'delete-data',
      data: item
    }
  }
  
  callDeleteDataAPI(){
    this.openedModal.modals = null;
    this._data.changeLoaderVisibility(true);
    this._app.deleteControlOfficers(this.openedModal.data.id).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
      this.getUboList();
      const msg = `${this.openedModal.data.firstName} has been deleted`;
      this._data.toastrShow(msg, 'info');
      const payload = {
        "message": msg,
        "page": "Control Officer Delete",
        "geoLocation": null
      }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
      this.openedModal.data = null;
    },
      (err: HttpErrorResponse) => {
        const payload = {
          "message": err,
          "page": "Control Officer Delete",
          "geoLocation": null
        }
      this._app.feasAddActivityLog(localStorage.getItem("companyKysNo"), payload).subscribe();
    })
  }

}
